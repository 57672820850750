import React, { useState } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { AppBar, ListItemText, Toolbar } from '@mui/material';

//
import Header from './header';
import Nav from './nav';
import useResponsive from '../../hooks/useResponsive';
import NavSection from '../../components/nav-section/NavSection';
import { showcaseRouteName } from '../../utils/constants';
import SvgColor from '../../components/svg-color/SvgColor';

// const StyledBackground = styled('div')`
//   background-image: url('/assets/backgroundImg/layered-waves-haikei.svg');
//   zindex: -1;
//   position: absolute;
//   min-width: 100%;
//   min-height: 100%;
//   background-repeat: no-repeat;
//   object-fit: cover;
//   background-position: center;
// `;
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const NAV_WIDTH = 250;

const HEADER_MOBILE = 50;

const HEADER_DESKTOP = 92;

const StyledRoot2 = styled(AppBar)(({ theme }) => ({
  // ...bgBlur({ color: theme.palette.background.default }),
  backgroundColor: '#ffffff',
  boxShadow: 'none',
  top: 'auto',
  bottom: '0',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar2 = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  padding: 0,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    // padding: theme.spacing(0, 5),
  },
}));

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  // overflow: 'hidden',
});

const Main = styled('div')(({ theme, smUp }) => ({
  flexGrow: 1,
  overflow: smUp ? 'unset' : 'auto',
  minHeight: '100%',
  backgroundColor: '#ffffff',
  // paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  // [theme.breakpoints.up('lg')]: {
  //   // paddingTop: APP_BAR_DESKTOP + 24,
  //   // paddingLeft: theme.spacing(2),
  //   // paddingRight: theme.spacing(2),
  // },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const [open, setOpen] = useState(false);
  const lgDown = useResponsive('down', 'lg');
  const smUp = useResponsive('up', 'sm');
  const { showcaseUnique: showcaseName } = useParams();

  const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

  const navConfig = [
    {
      title: 'Overview',
      path: `/${showcaseRouteName}/${showcaseName}/home`,
      // icon: icon('ic_user'),
      icon: icon('Dashboard-Overview'),
    },
    {
      title: 'Products',
      path: `/${showcaseRouteName}/${showcaseName}/product`,
      icon: icon('product'),
    },
    {
      title: 'Contact Us',
      path: `/${showcaseRouteName}/${showcaseName}/contact`,
      icon: icon('Contact'),
    },
  ];

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />
      {/* {smUp && <Nav openNav={open} onCloseNav={() => setOpen(false)} />} */}
      <Main smUp={smUp}>
        {/* <Outlet /> */}
        {children}
        {/* {React.cloneElement(children, { onOpenNav: () => setOpen(true) })} */}
        {!smUp && (
          <StyledRoot2>
            <StyledToolbar2>
              <NavSection data={navConfig} mobile />
            </StyledToolbar2>
          </StyledRoot2>
        )}
      </Main>
    </StyledRoot>
  );
}
