/* eslint-disable react/jsx-no-bind */
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Box, Button, Container, Grid, Input, InputLabel, MenuItem, Tab, Tabs, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Swal from 'sweetalert2';

import { Progresscontext } from '../../../components/circular-progress/ProgressContext';
import Iconify from '../../../components/iconify';
import ImageUpload from '../../../components/image-upload/ImageUpload';
import WarningModal from '../../../components/modal/WarningModal';
import Loader from '../../../components/loader';
import CommonModal from '../../../components/modal/CommonModal';
import useResponsive from '../../../hooks/useResponsive';
import {
    defaultError,
    wocOrange,
    S3BucketBaseUrl,
    SHOWCASE_HEADER_NOTE,
    tableBgColor,
    userRouteName,
    wocOrangeLight,
    confirmGreen,
} from '../../../utils/constants';
import customToast from '../../../utils/customToast';
import { getExtension, openInNewTab, parseVideo, uploadImageToS3 } from '../../../utils/helper';
import apiMiddleWare from '../../../utils/useApiMiddleWare';
import ImageUploadNew from '../../../components/image-upload/imageUploadNew';

// function createData(id, name, type) {
//   return { id, name, type };
// }

// const rows = [
//   createData(101, 'Main Video', 'Video'),
//   createData(102, 'Secondar Video', 'Video'),
//   createData(103, 'Banner 1', 'Image'),
//   createData(104, 'Banner 2', 'Image'),
//   createData(105, 'Outrow', 'Video'),
// ];

// const rows2 = [
//   createData(201, 'Doc 1', 'pdf'),
//   createData(202, 'Doc 2', 'xls'),
//   createData(203, 'Doc 3', 'pdf'),
//   createData(204, 'Doc 4', 'doc'),
//   createData(205, 'Doc 5', 'docx'),
// ];

const iconFontSize = '1.9rem';
function UserResource() {
    const navigate = useNavigate();

    const smUp = useResponsive('up', 'sm');
    const isLg = useResponsive('up', 'lg');

    const { setCallGuideStatus } = useContext(Progresscontext);

    const [isLoading, setIsLoading] = useState(false);
    const [callApi, setCallApi] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);

    const [allData, setAllData] = useState({ gallery: [], document: [] });
    const [addEditData, setAddEditData] = useState({
        mode: 'add',
        subtype: 'gallery',
        data: {
            id: '',
            name: '',
            type: 'image', // ['image','video']
            src: '',
            file: {},
            imgName: '',
            videoProvider: '',
        },
        modalOpen: false,
    });

    // * waring modal state
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [missingFields, setMissingFields] = useState([]);

    // * warning modal functions
    // * close handler
    const handleClose = () => {
        setShowWarningModal(false);
    };
    const handleProceed = () => {
        setShowWarningModal(false);
        navigate(`/${userRouteName}/showcase/product`);
    };

    useEffect(() => {
        getListing();
    }, [callApi]);

    async function getListing() {
        try {
            setIsLoading(true);
            const data = await apiMiddleWare({ type: 'get', url: `/company/resources` });
            const { galleryItems, documentItems } = data?.data?.packet;
            setAllData({ gallery: galleryItems, document: documentItems });
            setIsLoading(false);
        } catch (err) {
            customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
        }
    }

    // for gallery start
    function clearAddEditData() {
        setAddEditData({
            mode: 'add',
            subtype: 'gallery',
            data: {
                id: '',
                name: '',
                type: 'image',
                src: '',
                imgName: '',
                videoProvider: '',
                file: {},
            },
            modalOpen: false,
        });
    }

    function onOpenAddEditModal(type = 'add', subtype = 'gallery', data = {}) {
        if (type === 'add') {
            setAddEditData((prev) => ({
                ...prev,
                mode: type,
                subtype,
                data: {
                    ...prev.data,
                    type: subtype === 'gallery' ? 'image' : 'pdf',
                    name: '',
                    src: '',
                    file: {},
                },
                modalOpen: true,
            }));
        } else {
            setAddEditData((prev) => ({
                ...prev,
                mode: type,
                subtype,
                data: {
                    id: data._id,
                    name: data?.title,
                    type: data.type === 'image' || data.type === 'video' ? data?.type : data?.extension,
                    src: data.type === 'image' ? S3BucketBaseUrl + data.source : data.type === 'video' ? data.source : '',
                    imgName: data.type !== 'video' ? data.source : '',
                    videoProvider: data.type === 'video' ? data?.extension : '',
                    file: data.type !== 'image' && data.type !== 'video' ? data.source : '',
                },
                modalOpen: true,
            }));
        }
    }

    function handleChange(e) {
        const { name, value } = e.target;
        if (name === 'src') {
            setAddEditData((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    [name]: value,
                },
            }));
        } else if (name === 'file') {
            const fileHere = e.target.files[0];
            if (fileHere) {
                setAddEditData((prev) => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        file: fileHere,
                    },
                }));
            }
        } else {
            let newSrc = addEditData.data.src;
            if (name === 'type') {
                newSrc = '';
            }
            setAddEditData((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    src: newSrc,
                    [name]: value,
                },
            }));
        }
    }

    function onCloseAddEditModal() {
        clearAddEditData();
    }

    async function onSubmitAddEditModal() {
        // clearAddEditData()
        try {
            setIsLoading(true);
            await validateForm();
            const id = toast.loading('Please wait...');

            const sendData = {
                title: addEditData?.data?.name.trim(),
                // type : ['video', 'image', 'doc']
                type: addEditData?.subtype === 'catalogue' ? 'doc' : addEditData?.data?.type,
                // extension : ['doc', 'pdf', 'xls','xlsx', 'docx', 'jpg', 'jpeg', 'png', 'youtube', 'vimeo' ]
                extension: '',
                source: '',
            };

            if (sendData.type === 'doc') {
                sendData.extension = addEditData?.data?.type;
            }
            if (sendData.type === 'video') {
                sendData.extension = addEditData?.data?.videoProvider;
                sendData.source = addEditData?.data?.src;
            }
            if (sendData.type === 'image') {
                sendData.extension = getExtension(addEditData?.data?.src);
            }

            if (addEditData?.mode === 'add') {
                if (sendData.type === 'image') {
                    const { resource } = await uploadImageToS3({
                        sendData: {
                            resource: addEditData?.data?.imgName?.split('/')?.pop() || '',
                            resourceType: getExtension(addEditData?.data?.src),
                        },
                        value: addEditData?.data?.src,
                    });

                    sendData.source = resource;
                } else if (sendData.type === 'doc') {
                    const formData = new FormData();
                    formData.append('myFile', addEditData?.data?.file, addEditData?.data?.file.name);

                    const { resource } = await uploadImageToS3({
                        sendData: {
                            resource: addEditData?.data?.imgName?.split('/')?.pop() || '',
                            resourceType: addEditData?.data?.type,
                        },
                        body: formData,
                        headers: { 'Content-Type': 'multipart/form-data' },
                    });

                    sendData.source = resource;
                }
                const res = await apiMiddleWare({ type: 'post', url: `/company/resource/add`, data: sendData });
                if (!res?.data?.ok) {
                    customToast({
                        updateId: id,
                        type: 'update',
                        msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                        updateType: 'error',
                    });
                    return;
                }
                customToast({ updateId: id, type: 'update', msg: 'Resource Added Successfully', updateType: 'success' });
            } else if (addEditData?.mode === 'edit') {
                if (sendData.type === 'image') {
                    if (addEditData?.data?.src?.indexOf(S3BucketBaseUrl) === -1) {
                        const { resource } = await uploadImageToS3({
                            sendData: {
                                resource: addEditData?.data?.imgName?.split('/')?.pop() || '',
                                resourceType: getExtension(addEditData?.data?.src),
                            },
                            value: addEditData?.data?.src,
                        });

                        sendData.source = resource;
                    }
                } else if (sendData.type === 'doc') {
                    if (typeof addEditData?.data?.file !== 'string') {
                        const formData = new FormData();
                        formData.append('myFile', addEditData?.data?.file, addEditData?.data?.file.name);

                        const { resource } = await uploadImageToS3({
                            sendData: {
                                resource: addEditData?.data?.imgName?.split('/')?.pop() || '',
                                resourceType: addEditData?.data?.type,
                            },
                            body: formData,
                            headers: { 'Content-Type': 'multipart/form-data' },
                        });

                        sendData.source = resource;
                    }
                } else if (sendData.type === 'video') {
                    sendData.source = addEditData?.data?.src;
                }
                // sendData.resourceID = addEditData?.data?.id;
                sendData.itemID = addEditData?.data?.id;

                const res2 = await apiMiddleWare({ type: 'post', url: `/company/resource/edit`, data: sendData });
                if (!res2?.data?.ok) {
                    customToast({
                        updateId: id,
                        type: 'update',
                        msg: res2?.data?.errors[0]?.message || res2?.data?.errors[0]?.msg || defaultError,
                        updateType: 'error',
                    });
                    return;
                }

                customToast({ updateId: id, type: 'update', msg: 'Resource Updated Successfully', updateType: 'success' });
            }
            setIsLoading(false);

            setTimeout(() => {
                setCallApi((prev) => !prev);
            }, [1500]);
            setCallGuideStatus((prev) => !prev);
            onCloseAddEditModal();
            // return true;
        } catch (err) {
            setIsLoading(false);
            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
            // return false;
        }
    }

    function videoOnBlur() {
        const currentLink = addEditData?.data?.src;
        if (currentLink) {
            const allData = parseVideo(currentLink);
            if (allData?.provider !== 'youtube' && allData?.provider !== 'vimeo') {
                customToast({ type: 'error', msg: `URL is not valid` });
                setAddEditData((prev) => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        src: '',
                    },
                }));
            } else {
                setAddEditData((prev) => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        videoProvider: allData?.provider,
                        src: allData?.rawLink,
                    },
                }));
            }
        }
    }

    function getThumbnailLink(link) {
        const data = parseVideo(link);
        return data?.thumb;
    }

    function getImageHere(data) {
        if (!!data) {
            setAddEditData((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    src: data,
                },
            }));
        }
    }

    async function validateForm() {
        return new Promise((resolve, reject) => {
            if (addEditData?.data?.name === '') {
                reject(new Error(`Name is required`));
                return false;
            }
            if (addEditData?.data?.type === '') {
                reject(new Error(`Type is required`));
                return false;
            }
            if (addEditData?.subtype === 'gallery') {
                if (addEditData?.data?.type === 'image' && addEditData?.data?.src === '') {
                    reject(new Error(`Image is required`));
                    return false;
                }
                if (addEditData?.data?.type === 'video' && addEditData?.data?.src === '') {
                    reject(new Error(`Video Link is required`));
                    return false;
                }
            } else if (addEditData?.subtype === 'document') {
                if (!addEditData?.data?.file === '') {
                    reject(new Error(`File is required`));
                    return false;
                }
            }
            resolve();
            return true;
        });
    }

    // for gallery end

    const deleteSwal = (resourceID, type) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            customClass: { container: 'zIndex-10000 ' },
        }).then((result) => {
            if (result.isConfirmed) {
                deleteButton(resourceID, type);
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info');
            }
        });
    };

    const deleteButton = async (resourceID, type) => {
        try {
            const id = toast.loading('Please wait...');
            const sendData = {
                itemID: resourceID,
                type,
            };
            const res = await apiMiddleWare({ url: `/company/resource/delete`, type: 'post', data: sendData });
            if (res?.data?.ok) {
                customToast({ updateId: id, type: 'update', msg: 'Resource Deleted Successfully', updateType: 'success' });
                setCallApi((prev) => !prev);
                setCallGuideStatus((prev) => !prev);
            } else {
                customToast({
                    updateId: id,
                    type: 'update',
                    msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                    updateType: 'error',
                });
            }
        } catch (err) {
            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
        }
    };

    const submitAndNext = (type) => () => {
        const fields = [];
        if (allData?.gallery?.length === 0) fields.push('Gallery');
        if (allData?.document?.length === 0) fields.push('Document');

        if (fields.length) {
            setMissingFields(fields);
            setShowWarningModal(true);
        } else if (type === 'next') {
            navigate(`/${userRouteName}/showcase/product`);
        } else if (type === 'share') {
            navigate(`/${userRouteName}/showcase/share`);
        }
    };

    function handleTabChange(e, newValue) {
        setCurrentTab(newValue);
    }

    return (
        <>
            {/* warning modal */}
            <WarningModal
                sectionName="Gallery & Catalogue"
                missingFields={missingFields}
                open={showWarningModal}
                handleClose={handleClose}
                handleProceed={handleProceed}
            />
            <CommonModal
                noTitleLine
                title={`${addEditData?.mode === 'add' ? 'Add' : 'Edit'} ${addEditData.subtype === 'gallery' ? 'Gallery Content' : 'Catalogue'}`}
                open={addEditData?.modalOpen}
                onClose={onCloseAddEditModal}
                submitDisable={isLoading}
                onSubmit={onSubmitAddEditModal}
                body={
                    <>
                        <div className="p-3">
                            <Grid container spacing={3}>
                                <Grid item xs={12} className="side-by-side">
                                    {/* <Grid item xs={12} md={4}>
                                        <InputLabel>Content Name</InputLabel>
                                    </Grid> */}
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            className="fullWidth-responsive"
                                            // fullWidth
                                            label="Content name*"
                                            placeholder="Enter the content name"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                width: '80%',
                                                '& .MuiInputLabel-asterisk': {
                                                    display: 'none', // inherit the color from the parent
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: 'black', // Default black outline
                                                    },
                                                },
                                            }}
                                            name="name"
                                            value={addEditData?.data?.name}
                                            onChange={handleChange}
                                            // inputProps={{ maxLength: 30 }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className="side-by-side">
                                    {/* <Grid item xs={12} md={4}>
                                        <InputLabel>Content Type</InputLabel>
                                    </Grid> */}
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            // fullWidth
                                            className="fullWidth-responsive"
                                            name="type"
                                            id="outlined-select-currency"
                                            select
                                            label="Content type*"
                                            placeholder="Select the content type"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                textAlign: 'left',
                                                width: '80%',
                                                '& .MuiInputLabel-asterisk': {
                                                    display: 'none', // inherit the color from the parent
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: 'black', // Default black outline
                                                    },
                                                },
                                            }}
                                            disabled={addEditData?.mode === 'edit'}
                                            // label="Search Time"
                                            value={addEditData?.data?.type}
                                            onChange={handleChange}
                                            // required
                                        >
                                            <MenuItem disabled>Select the content type</MenuItem>
                                            {addEditData?.subtype === 'gallery'
                                                ? ['image', 'video'].map((i) => (
                                                      <MenuItem value={i} key={i}>
                                                          {i.charAt(0).toUpperCase() + i.substring(1)}
                                                      </MenuItem>
                                                  ))
                                                : ['pdf', 'xls', 'xlsx', 'doc', 'docx'].map((i) => (
                                                      <MenuItem value={i} key={i}>
                                                          {i.toUpperCase()}
                                                      </MenuItem>
                                                  ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                                {addEditData?.data?.type === 'image' && (
                                    <Grid item xs={12} className="">
                                        <Grid item xs={12} md={4}>
                                            <InputLabel>Image*</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} md={12} className='side-by-side' style={{ alignItems: 'center' }}>
                                           {!!addEditData?.data?.src && (
                                                <img
                                                    src={addEditData?.data?.src}
                                                    alt="gallery content"
                                                    height={120}
                                                    style={{ marginRight: '2rem' }}
                                                />
                                            )}
                                            <ImageUploadNew
                                                title="Upload Image"
                                                previousImgSrc={addEditData?.data?.src || ''}
                                                onClose={(data) => getImageHere(data)}
                                                propSize={2}
                                                imageHeight="10rem"
                                                imageWidth="60%"
                                            />
                                        </Grid>
                                        <div className="note-text-new">
                                            Note: Image File Size (Max 2MB), Image Dimension (16:9 ratio), Image Format (JPEG/PNG)
                                        </div>
                                    </Grid>
                                )}
                                {addEditData?.data?.type === 'video' && (
                                    <Grid item xs={12} className="side-by-side">
                                        {/* <Grid item xs={12} md={4} sx={{ flexDirection: 'column' }}>
                                            <InputLabel>Video Link</InputLabel>
                                            <span className="note-text">Only Youtube/Vimeo Link</span>
                                        </Grid> */}
                                        <Grid item xs={12} md={8}>
                                            <TextField
                                                // sx={{ width: '10%' }}
                                                fullWidth
                                                name="src"
                                                label="Video Link*"
                                                placeholder="Only Youtube/Vimeo Link"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                sx={{
                                                    width: '80%',
                                                    '& .MuiInputLabel-asterisk': {
                                                        display: 'none', // inherit the color from the parent
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: 'black', // Default black outline
                                                        },
                                                    },
                                                }}
                                                value={addEditData?.data?.src}
                                                onChange={handleChange}
                                                onBlur={videoOnBlur}
                                                // inputProps={{ maxLength: 30 }}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                {addEditData?.data?.type !== 'image' && addEditData?.data?.type !== 'video' && (
                                    <Grid item xs={12} className="">
                                        {/* <Grid item xs={12} md={2}>
                                            <InputLabel>Upload File*</InputLabel>
                                        </Grid> */}
                                        <Grid
                                            item
                                            xs={12}
                                            md={8}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {addEditData?.data?.type === 'pdf' && (
                                                <TextField
                                                    label="Upload File*"
                                                    placeholder="Upload the file"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    sx={{
                                                        width: '80%',
                                                        '& .MuiInputLabel-asterisk': {
                                                            display: 'none', // inherit the color from the parent
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: 'black', // Default black outline
                                                            },
                                                        },
                                                    }}
                                                    type="file"
                                                    name="file"
                                                    onChange={handleChange}
                                                    inputProps={{ accept: 'application/pdf' }}
                                                />
                                            )}
                                            {addEditData?.data?.type === 'xls' && (
                                                <TextField
                                                    label="Upload File*"
                                                    placeholder="Upload the file"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    sx={{
                                                        width: '80%',
                                                        '& .MuiInputLabel-asterisk': {
                                                            display: 'none', // inherit the color from the parent
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: 'black', // Default black outline
                                                            },
                                                        },
                                                    }}
                                                    type="file"
                                                    name="file"
                                                    onChange={handleChange}
                                                    inputProps={{
                                                        accept: 'application/vnd.ms-excel',
                                                    }}
                                                />
                                            )}
                                            {addEditData?.data?.type === 'xlsx' && (
                                                <TextField
                                                    label="Upload File*"
                                                    placeholder="Upload the file"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    sx={{
                                                        width: '80%',
                                                        '& .MuiInputLabel-asterisk': {
                                                            display: 'none', // inherit the color from the parent
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: 'black', // Default black outline
                                                            },
                                                        },
                                                    }}
                                                    type="file"
                                                    name="file"
                                                    onChange={handleChange}
                                                    inputProps={{
                                                        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                                    }}
                                                />
                                            )}

                                            {addEditData?.data?.type === 'doc' && (
                                                <TextField
                                                    label="Upload File*"
                                                    placeholder="Upload the file"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    sx={{
                                                        width: '80%',
                                                        '& .MuiInputLabel-asterisk': {
                                                            display: 'none', // inherit the color from the parent
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: 'black', // Default black outline
                                                            },
                                                        },
                                                    }}
                                                    type="file"
                                                    name="file"
                                                    onChange={handleChange}
                                                    inputProps={{ accept: 'application/msword' }}
                                                />
                                            )}
                                            {addEditData?.data?.type === 'docx' && (
                                                <TextField
                                                    label="Upload File*"
                                                    placeholder="Upload the file"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    sx={{
                                                        width: '80%',
                                                        '& .MuiInputLabel-asterisk': {
                                                            display: 'none', // inherit the color from the parent
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: 'black', // Default black outline
                                                            },
                                                        },
                                                    }}
                                                    type="file"
                                                    name="file"
                                                    onChange={handleChange}
                                                    inputProps={{
                                                        accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                    }}
                                                />
                                            )}
                                            <Typography variant="caption" className="mt-2">
                                                Maximum Size of the document should not be more than 5MB.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                    </>
                }
            />

            <Container maxWidth="xl" sx={{ px: smUp ? 4 : 2, pb: 2 }} className="h-100 w-100">
                {/* <Typography variant="h3" sx={{ mb: 5 }}>
                Showcase Resource
                </Typography> */}
                {isLoading ? (
                    <Loader />
                ) : (
                    <Box>
                        <Typography variant="h6" className="mb-3">
                            Note: {SHOWCASE_HEADER_NOTE.resource}
                        </Typography>
                        <Box>
                            <Tabs value={currentTab} onChange={(e, newV) => handleTabChange(e, newV)} aria-label="Gallery and Catalouge">
                                <Tab
                                    label={
                                        <div className=" d-flex align-items-center">
                                            <Iconify icon="material-symbols:perm-media-rounded" width={smUp ? 30 : 20} />
                                            <span className="ms-md-3 fs-6">
                                                Gallery <span style={{ color: 'red' }}>*</span>
                                            </span>
                                        </div>
                                    }
                                />
                                <Tab
                                    label={
                                        <div className=" d-flex align-items-center">
                                            <Iconify icon="mdi:file-document" width={smUp ? 30 : 20} />
                                            <span className="ms-md-3 fs-6 ">
                                                Catalouge <span style={{ color: 'red' }}>*</span>
                                            </span>
                                        </div>
                                    }
                                />
                            </Tabs>
                        </Box>
                        <div
                            role="tabpanel"
                            hidden={currentTab !== 0}
                            id={`simple-tabpanel-${0}`}
                            aria-labelledby={`simple-tab-${0}`}
                            className="pt-2 pt-md-0"
                        >
                            <Box>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sx={{ justifyContent: 'end', display: 'flex' }}>
                                        <Button
                                            type="Button"
                                            variant="contained"
                                            className="fs-6 "
                                            onClick={() => onOpenAddEditModal('add', 'gallery')}
                                            sx={{ fontSize: iconFontSize }}
                                        >
                                            Add Gallery
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: smUp ? 650 : 'auto' }} aria-label="simple table">
                                                <TableHead
                                                    sx={{
                                                        '& .MuiTableCell-head': {
                                                            color: 'white',
                                                            backgroundColor: wocOrange,
                                                            // textAlign: 'center',
                                                        },
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell align="left">Index</TableCell>
                                                        <TableCell align="center">Preview</TableCell>
                                                        <TableCell align="center">Name</TableCell>
                                                        <TableCell align="right">Type</TableCell>
                                                        <TableCell align="right">Actions</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {allData?.gallery?.length > 0 &&
                                                        allData?.gallery?.map((row, index) => (
                                                            <TableRow
                                                                key={row._id}
                                                                sx={{
                                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                                    '&:nth-child(odd)': {
                                                                        backgroundColor: 'white',
                                                                    },
                                                                    '&:nth-child(even)': {
                                                                        backgroundColor: wocOrangeLight,
                                                                    },
                                                                }}
                                                            >
                                                                <TableCell align="left">{index + 1}</TableCell>
                                                                <TableCell align="center">
                                                                    <div className="table-img">
                                                                        {row.type === 'image' ? (
                                                                            <img src={`${S3BucketBaseUrl}${row.source}`} alt="" />
                                                                        ) : (
                                                                            <img src={getThumbnailLink(row.source)} alt="" />
                                                                        )}
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center">{row.title}</TableCell>
                                                                <TableCell align="right">
                                                                    {row.type.charAt(0).toUpperCase() + row.type.substring(1)}
                                                                </TableCell>
                                                                <TableCell align="right" sx={{ minWidth: '265px' }}>
                                                                    <Button
                                                                        type="Button"
                                                                        variant="outlined"
                                                                        color="success"
                                                                        onClick={() => onOpenAddEditModal('edit', 'gallery', row)}
                                                                        sx={{ fontSize: '1.4rem', mr: 2 }}
                                                                        className="bg-white"
                                                                    >
                                                                        <Tooltip title="Edit">
                                                                            <Iconify icon="clarity:edit-line" />
                                                                        </Tooltip>
                                                                    </Button>
                                                                    <Button
                                                                        type="Button"
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        sx={{ fontSize: '1.4rem', mr: 2 }}
                                                                        className="bg-white"
                                                                        onClick={openInNewTab(
                                                                            row.type === 'image' ? `${S3BucketBaseUrl}${row.source}` : row.source
                                                                        )}
                                                                    >
                                                                        <Tooltip title="View">
                                                                            <Iconify icon="clarity:eye-line" />
                                                                        </Tooltip>
                                                                    </Button>
                                                                    <Button
                                                                        type="Button"
                                                                        variant="outlined"
                                                                        color="error"
                                                                        sx={{ fontSize: '1.4rem' }}
                                                                        onClick={() => deleteSwal(row?._id, row?.type)}
                                                                        className="bg-white"
                                                                    >
                                                                        <Tooltip title="Delete">
                                                                            <Iconify icon="gg:trash-empty" />
                                                                        </Tooltip>
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    {allData?.gallery?.length === 0 && (
                                                        <TableRow>
                                                            <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                                                                No Gallery Item Found
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>

                        <div
                            role="tabpanel"
                            hidden={currentTab !== 1}
                            id={`simple-tabpanel-${1}`}
                            aria-labelledby={`simple-tab-${1}`}
                            className="pt-2 pt-md-0"
                        >
                            {/* <input type="file" name="upload" accept="application/pdf , application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" /> */}
                            {/*  will support .pdf, .xls, .doc, .docx */}

                            <Box>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sx={{ justifyContent: 'end', display: 'flex' }}>
                                        <Button
                                            type="Button"
                                            variant="contained"
                                            className="fs-6 "
                                            onClick={() => onOpenAddEditModal('add', 'catalogue')}
                                            sx={{ fontSize: iconFontSize }}
                                        >
                                            Add Catalouge
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: smUp ? 650 : 'auto' }} aria-label="simple table">
                                                <TableHead
                                                    sx={{
                                                        '& .MuiTableCell-head': {
                                                            color: 'white',
                                                            backgroundColor: wocOrange,
                                                            // textAlign: 'center',
                                                        },
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell align="left">Index</TableCell>
                                                        <TableCell align="center">Name</TableCell>
                                                        <TableCell align="right">Type</TableCell>
                                                        <TableCell align="right">Actions</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {allData?.document?.length > 0 &&
                                                        allData?.document?.map((row, index) => (
                                                            <TableRow
                                                                key={row?._id}
                                                                sx={{
                                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                                    '&:nth-child(odd)': {
                                                                        backgroundColor: 'white',
                                                                    },
                                                                    '&:nth-child(even)': {
                                                                        backgroundColor: wocOrangeLight,
                                                                    },
                                                                }}
                                                            >
                                                                <TableCell align="left">{index + 1}</TableCell>
                                                                <TableCell align="center">{row?.title}</TableCell>
                                                                <TableCell align="right">{row?.extension.toUpperCase()}</TableCell>
                                                                <TableCell align="right" sx={{ minWidth: '265px' }}>
                                                                    <Button
                                                                        type="Button"
                                                                        variant="outlined"
                                                                        color="success"
                                                                        onClick={() => onOpenAddEditModal('edit', 'document', row)}
                                                                        sx={{ fontSize: '1.4rem', mr: 2 }}
                                                                        className="bg-white"
                                                                    >
                                                                        <Tooltip title="Edit">
                                                                            <Iconify icon="clarity:edit-line" />
                                                                        </Tooltip>
                                                                    </Button>
                                                                    <Button
                                                                        type="Button"
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        sx={{ fontSize: '1.4rem', mr: 2 }}
                                                                        onClick={openInNewTab(`${S3BucketBaseUrl}${row.source}`)}
                                                                        className="bg-white"
                                                                    >
                                                                        <Tooltip title="View">
                                                                            <Iconify icon="clarity:eye-line" />
                                                                        </Tooltip>
                                                                    </Button>
                                                                    <Button
                                                                        type="Button"
                                                                        variant="outlined"
                                                                        color="error"
                                                                        sx={{ fontSize: '1.4rem' }}
                                                                        onClick={() => deleteSwal(row?._id, row?.type)}
                                                                        className="bg-white"
                                                                    >
                                                                        <Tooltip title="Delete">
                                                                            <Iconify icon="gg:trash-empty" />
                                                                        </Tooltip>
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    {allData?.document?.length === 0 && (
                                                        <TableRow>
                                                            <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                                                                No Catalouge Found
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                        <Box
                            sx={{
                                position: 'fixed',
                                bottom: 0,
                                right: 0,
                                left: isLg ? 250 : 0,
                                bgcolor: 'white',
                                p: 1,
                                boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
                                gap: 2,
                            }}
                        >
                            <div className='d-flex justify-content-end'>
                            <Button sx={{background:confirmGreen,boxShadow:"none",":hover":{background:confirmGreen}}}  type="button" onClick={submitAndNext('next')} variant="contained">
                                Save & Next
                            </Button> 
                            </div>
                            {/* <div className="mt-3">
                                <Button type="button" onClick={submitAndNext('share')} variant="contained">
                                  Save & Share
                                </Button>
                              </div> */}
                        </Box>
                    </Box>
                )}
            </Container>
        </>
    );
}

export default UserResource;
