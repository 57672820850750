/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import moment from 'moment';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';

import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// @mui
import {
  Box,
  Container,
  Grid,
  MenuItem,
  TextField,
  Card,
  Table,
  Paper,
  Typography,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material/styles';
import { Form, InputGroup } from 'react-bootstrap';

import useLocalStorage from '../../../hooks/useLocalStorage';
import apiMiddleWare from '../../../utils/useApiMiddleWare';
import customToast from '../../../utils/customToast';
import { defaultError, wocOrange, tableBgColor } from '../../../utils/constants';
import Iconify from '../../../components/iconify/Iconify';
import { openInNewTab, parseVideo, timeZeroFormat, useQuery } from '../../../utils/helper';
import { AppConversionRates, AppWebsiteVisits } from '../../../sections/@dashboard/app';
import useResponsive from '../../../hooks/useResponsive';

function UserResourceAnalytics() {
  const theme = useTheme();
  const query = useQuery();

  const showcaseRef = useLocalStorage('showcaseID');
  const filterDataLocal = useLocalStorage('filterData');
  const sID = filterDataLocal?.showcaseID;

  const smUp = useResponsive('up', 'sm');

  const { showcaseList } = useOutletContext();

  const [isLoading, setIsLoading] = useState(false);
  const [callApi, setCallApi] = useState(false);
  const [filterData, setFilterData] = useState({
    showcaseID: filterDataLocal?.showcaseID || '',
    searchTime: filterDataLocal?.searchTime || 'last7',
    startDate: filterDataLocal?.startDate || '',
    endDate: filterDataLocal?.endDate || '',
  });
  const [storeData, setStoreData] = useState({
    docAnalyticTable: { data: [], copy: [], searchV: '', called: false, loading: false },
    docAccessGraph: { data: [], copy: [], called: false, loading: false },
    vidAnalyticTable: { data: [], copy: [], searchV: '', called: false, loading: false },
    vidAccessGraph: { data: [], copy: [], called: false, loading: false },
    prodAnalyticTable: { data: [], copy: [], searchV: '', called: false, loading: false },
    prodAccessGraph: { data: [], copy: [], called: false, loading: false },
    socialAnalyticTable: { data: [], copy: [], searchV: '', called: false, loading: false },
    socialAccessGraph: { data: [], copy: [], called: false, loading: false },
  });
  const tabParam = query.get('tab');
  const [currentTab, setCurrentTab] = useState(
    tabParam === 'document' ? 1 : tabParam === 'video' ? 2 : tabParam === 'social' ? 3 : 0
  );
  const [currentAccordion, setCurrentAccordion] = useState('');
  const [identifierObj, setIdentifierObj] = useState({});

  useEffect(() => {
    if (currentTab === 0 && !storeData?.prodAnalyticTable?.called)
      commonResourceFile({ url: '/dashboard/product/table', name: 'prodAnalyticTable' });
  }, [currentTab, callApi]);

  useEffect(() => {
    if (currentTab === 0 && !storeData?.prodAccessGraph?.called)
      commonResourceFile({ url: '/dashboard/product/daywise', name: 'prodAccessGraph' });
  }, [currentTab, callApi]);

  useEffect(() => {
    if (currentTab === 1 && !storeData?.docAnalyticTable?.called)
      commonResourceFile({ url: '/dashboard/resource/table/document', name: 'docAnalyticTable' });
  }, [currentTab, callApi]);

  useEffect(() => {
    if (currentTab === 1 && !storeData?.docAccessGraph?.called)
      commonResourceFile({ url: '/dashboard/resource/daywise/document', name: 'docAccessGraph' });
  }, [currentTab, callApi]);

  useEffect(() => {
    if (currentTab === 2 && !storeData?.vidAnalyticTable?.called)
      commonResourceFile({ url: '/dashboard/resource/table/video', name: 'vidAnalyticTable' });
  }, [currentTab, callApi]);

  useEffect(() => {
    if (currentTab === 2 && !storeData?.vidAccessGraph?.called)
      commonResourceFile({ url: '/dashboard/resource/daywise/video', name: 'vidAccessGraph' });
  }, [currentTab, callApi]);

  useEffect(() => {
    if (currentTab === 3 && !storeData?.socialAnalyticTable?.called)
      commonResourceFile({ url: '/dashboard/social/table', name: 'socialAnalyticTable' });
  }, [currentTab, callApi]);

  useEffect(() => {
    if (currentTab === 3 && !storeData?.socialAccessGraph?.called)
      commonResourceFile({ url: '/dashboard/social/daywise', name: 'socialAccessGraph' });
  }, [currentTab, callApi]);

  useEffect(() => {
    if (filterData?.searchTime === 'custom' && filterData?.startDate !== '' && filterData?.endDate !== '') {
      secureLocalStorage?.setItem('filterData', {
        showcaseID: filterData?.showcaseID,
        searchTime: 'custom',
        startDate: filterData?.startDate,
        endDate: filterData?.endDate,
      });
      window.dispatchEvent(new Event('storage'));

      calledObjectToFalse();
      setCallApi((prev) => !prev);
    }
  }, [filterData]);

  useEffect(() => {
    if (!filterDataLocal || !filterDataLocal?.showcaseID) {
      setFilterDataFunc();
    }
  }, []);

  const setFilterDataFunc = () => {
    setFilterData((prev) => ({
      ...prev,
      showcaseList,
      showcaseID: prev?.showcaseID || showcaseList?.[0]?._id || '',
    }));
    secureLocalStorage?.setItem('filterData', {
      showcaseID: filterData?.showcaseID || showcaseList?.[0]?._id || '',
      searchTime: filterData?.searchTime,
      startDate: filterData?.startDate,
      endDate: filterData?.endDate,
    });
    window.dispatchEvent(new Event('storage'));
  };

  const commonResourceFile = useCallback(
    async ({ url, name }) => {
      try {
        // setIsLoading(true);
        const sendData = {
          showcaseRef: filterData?.showcaseID,
          startDate: filterData?.startDate && timeZeroFormat({ date: filterData?.startDate }),
          endDate: filterData?.endDate && timeZeroFormat({ date: filterData?.endDate, hour: '48' }),
        };
        const res = await apiMiddleWare({
          type: 'post',
          callType: 'analytics',
          url,
          data: sendData,
        });
        if (!res) {
          // setIsLoading(false);
          return false;
        }
        if (!res?.data?.ok) {
          customToast({
            msg: res?.data?.errors?.[0]?.message || res?.data?.errors?.[0]?.msg || defaultError,
            type: 'error',
          });
          // setIsLoading(false);
          return false;
        }

        const dataHere = res?.data?.packet;
        setStoreData((prev) => ({
          ...prev,
          [name]: { loading: false, data: dataHere, copy: dataHere, called: true },
        }));
        // setIsLoading(false);
        return true;
      } catch (err) {
        customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
        // setIsLoading(false);
        return false;
      }
    },
    [storeData]
  );

  const onChangeFilter = (e) => {
    const { name, value } = e.target;

    setFilterData((prev) => ({
      ...prev,
      [name]: value,
      startDate: value === 'last7' || value === 'custom' ? '' : moment().subtract(1, 'month'),
      endDate: value === 'last7' || value === 'custom' ? '' : new Date(),
    }));

    const showcaseVal = name === 'showcaseID' ? value : filterData?.showcaseID;
    const searchTimeVal = name === 'showcaseID' ? filterData?.searchTime : value;
    secureLocalStorage?.setItem('filterData', {
      showcaseID: showcaseVal,
      searchTime: searchTimeVal,
      startDate: value === 'last7' || value === 'custom' ? '' : moment().subtract(1, 'month'),
      endDate: value === 'last7' || value === 'custom' ? '' : new Date(),
    });
    window.dispatchEvent(new Event('storage'));

    if (value !== 'custom') {
      calledObjectToFalse();
      setCallApi((prev) => !prev);
    }
  };

  const calledObjectToFalse = () => {
    setStoreData((prev) => ({
      ...prev,
      docAnalyticTable: { ...prev?.docAnalyticTable, called: false },
      docAccessGraph: { ...prev?.docAccessGraph, called: false },
      vidAnalyticTable: { ...prev?.vidAnalyticTable, called: false },
      vidAccessGraph: { ...prev?.vidAccessGraph, called: false },
      prodAnalyticTable: { ...prev?.prodAnalyticTable, called: false },
      prodAccessGraph: { ...prev?.prodAccessGraph, called: false },
      socialAnalyticTable: { ...prev?.socialAnalyticTable, called: false },
      socialAccessGraph: { ...prev?.socialAccessGraph, called: false },
    }));
  };

  const handleAccordionOpen = (panel) => (event, isExpanded) => {
    setCurrentAccordion(isExpanded ? panel : false);
  };

  function getThumbnailLink(link) {
    const data = parseVideo(link);
    return data?.thumb;
  }

  function handleTabChange(e, newValue) {
    setCurrentTab(newValue);
  }

  const filterTable = (name) => (e) => {
    const searchValue = e.target.value?.toLowerCase();
    const originalData = storeData?.[name]?.copy;
    let filteredData = [];
    if (name === 'docAnalyticTable' || name === 'vidAnalyticTable') {
      filteredData = originalData.filter((i) => i?.resourceRef?.title?.toLowerCase()?.indexOf(searchValue) !== -1);
    } else if (name === 'socialAnalyticTable') {
      filteredData = originalData.filter((i) => i?._id.toLowerCase()?.indexOf(searchValue) !== -1);
    } else {
      filteredData = originalData.filter((i) => i?.product?.title?.toLowerCase()?.indexOf(searchValue) !== -1);
    }
    setStoreData((prev) => ({
      ...prev,
      [name]: {
        ...prev?.[name],
        searchV: searchValue,
        data: filteredData,
      },
    }));
  };

  const getIdentifierName = async (sid) => {
    try {
      const res = await apiMiddleWare({ type: 'get', url: `/showcase/get-identifier/${sid}` });

      if (!res?.data?.ok) {
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        return false;
      }
      const dataHere = res?.data?.packet;
      setIdentifierObj((prev) => ({
        ...prev,
        [sid]: dataHere?.identifier,
      }));
      return dataHere?.identifier;
    } catch (err) {
      customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const onClickProduct = async (productID) => {
    const identifier = identifierObj[sID] || (await getIdentifierName(sID));
    openInNewTab(`/showcase/${identifier}/product/${productID}`)();
  };

  const TableHeadCustom = styled(TableHead)({
    '& .MuiTableCell-head': {
      color: 'white',
      backgroundColor: wocOrange,
      // textAlign: 'center',
    },
  });

  const TableRowStyle = {
    '&:last-child td, &:last-child th': { border: 0 },
    '&:nth-child(odd)': {
      backgroundColor: tableBgColor,
    },
    '&:nth-child(even)': {
      backgroundColor: 'white',
    },
  };

  return (
    <>
      <Container maxWidth="xl" className="mt-4 mt-md-0">
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Resource Analytics
          </Typography>
        </Stack> */}
        {isLoading ? (
          <>Loading...</>
        ) : (
          <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={3} sx={{ mb: '2rem' }}>
                <Grid item xs={12} sm={6} md={3} className='d-none'>
                  <TextField
                    fullWidth
                    name="showcaseID"
                    id="outlined-select-currency"
                    select
                    label="Showcase"
                    value={filterData?.showcaseID}
                    onChange={onChangeFilter}
                  >
                    {showcaseList?.length > 0 &&
                      showcaseList?.map((i) => (
                        <MenuItem key={i?._id} value={i?._id}>
                          {i?.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    name="searchTime"
                    id="outlined-select-currency"
                    select
                    label="Search Time"
                    value={filterData?.searchTime}
                    onChange={onChangeFilter}
                    required
                  >
                    <MenuItem key={'last7'} value={'last7'}>
                      Last 7 Days
                    </MenuItem>
                    <MenuItem key={'last30'} value={'last30'}>
                      Last 30 Days
                    </MenuItem>
                    <MenuItem key={'custom'} value={'custom'}>
                      Custom
                    </MenuItem>
                  </TextField>
                </Grid>
                {filterData?.searchTime === 'custom' && (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <DatePicker
                        disableFuture
                        label="Start Date"
                        openTo="day"
                        views={['year', 'month', 'day']}
                        value={filterData?.startDate}
                        onChange={(newValue) => {
                          setFilterData((prev) => ({
                            ...prev,
                            startDate: newValue,
                          }));
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <DatePicker
                        disableFuture
                        label="End Date"
                        openTo="day"
                        minDate={filterData?.startDate || dayjs()}
                        views={['year', 'month', 'day']}
                        value={filterData?.endDate}
                        onChange={(newValue) => {
                          setFilterData((prev) => ({
                            ...prev,
                            endDate: newValue,
                          }));
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </LocalizationProvider>
            <Box sx={{ width: '100%' }}>
              <Tabs
                value={currentTab}
                onChange={(e, newV) => handleTabChange(e, newV)}
                aria-label="Tabs where selection follows focus"
                sx={{
                  '& .MuiTabs-flexContainer': { gap: smUp ? '25px' : '10px', flexWrap: 'wrap' },
                  '& .Mui-selected': {
                    color: 'white !important',
                    backgroundColor: wocOrange,
                  },
                  '& .MuiTabs-indicator': {
                    width: '0 !important',
                  },
                  '& .MuiTab-root': {
                    minHeight: '60px',
                  },
                }}
                className="expand_tabs"
              >
                <Tab
                  iconPosition={smUp ? 'top' : 'start'}
                  icon={<Iconify icon="ion:cube" width={smUp ? 30 : 20} />}
                  label="Product"
                />
                <Tab
                  iconPosition={smUp ? 'top' : 'start'}
                  icon={<Iconify icon="mdi:file-document" width={smUp ? 30 : 20} />}
                  label="Document"
                />
                <Tab
                  iconPosition={smUp ? 'top' : 'start'}
                  icon={<Iconify icon="carbon:logo-youtube" width={smUp ? 30 : 20} />}
                  label="Video"
                />
                <Tab
                  iconPosition={smUp ? 'top' : 'start'}
                  icon={<Iconify icon="icon-park-solid:connect" width={smUp ? 30 : 20} />}
                  label="Social"
                />
              </Tabs>

              {/* Product Page Start */}
              <div
                role="tabpanel"
                hidden={currentTab !== 0}
                id={`simple-tabpanel-${0}`}
                aria-labelledby={`simple-tab-${0}`}
              >
                <Box sx={{ py: 3 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <div className="d-flex flex-column flex-md-row mb-3">
                        <Typography variant="h6" className=" black-login fs-6 my-1 my-md-3">
                          Daywise Access Graph
                        </Typography>
                        {smUp && <>&nbsp;&nbsp;</>}
                        <span className="note-text my-auto">( with respect to Visit Count )</span>
                      </div>
                      <AppWebsiteVisits
                        // title="Website Visits"
                        // subheader="Daywise Product with respect to Visit Count"
                        chartLabels={storeData?.prodAccessGraph?.data
                          ?.sort((a, b) => (new Date(a?._id) > new Date(b?._id) ? 1 : -1))
                          ?.map((i) => moment(i?._id).add({ hour: '24' }).format('ll'))}
                        chartData={[
                          {
                            name: 'Count',
                            type: 'area',
                            fill: 'gradient',
                            data: storeData?.prodAccessGraph?.data?.map((i) => i?.count),
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="d-flex flex-column flex-md-row mb-3">
                        <Typography variant="h6" className=" black-login fs-6 my-1 my-md-3">
                          Top 5 Popularity Graph
                        </Typography>
                        {smUp && <>&nbsp;&nbsp;</>}

                        <span className="note-text my-auto">( with respect to Visit Count )</span>
                      </div>
                      <AppConversionRates
                        // subheader="Product with respect to Visit Count (Top 5)"
                        tooltipTitle={'Count :'}
                        chartData={storeData?.prodAnalyticTable?.copy?.slice(0, 5)?.map((i) => ({
                          label: i?.product?.title,
                          value: i?.count,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div className="d-flex">
                        <Typography variant="h6" className=" black-login fs-6 my-3">
                          Product Analytics Table
                        </Typography>
                      </div>

                      <Card className="bg-transparent bordre-0 shadow-none row justify-content-between">
                        <Form className="col-md-5 col-12 mb-3 ">
                          <InputGroup>
                            <InputGroup.Text className="bg-white border-0 pe-0 me-0 shadow-none">
                              <Iconify icon="ic:outline-search" />
                            </InputGroup.Text>
                            <Form.Control
                              className="border-0 shadow-none"
                              placeholder="Search By Name"
                              aria-label="Search"
                              value={storeData?.prodAnalyticTable?.searchV}
                              onChange={filterTable('prodAnalyticTable')}
                            />
                          </InputGroup>
                        </Form>
                      </Card>
                      <Box>
                        <Grid item xs={12}>
                          <TableContainer component={Paper}>
                            <Table sx={{ minWidth: smUp ? 650 : 'auto' }} aria-label="simple table">
                              <TableHeadCustom>
                                <TableRow>
                                  <TableCell align="left">Index</TableCell>
                                  <TableCell align="center">Name</TableCell>
                                  <TableCell align="right">No. of Views</TableCell>
                                </TableRow>
                              </TableHeadCustom>
                              <TableBody>
                                {storeData?.prodAnalyticTable?.data?.length > 0 &&
                                  storeData?.prodAnalyticTable?.data?.map((row, index) => (
                                    <TableRow key={row._id} sx={TableRowStyle}>
                                      <TableCell align="left">{index + 1}</TableCell>
                                      <TableCell align="center">
                                        <span className="cursor-pointer me-2" onClick={() => onClickProduct(row._id)}>
                                          <Iconify icon="mdi:link-variant" sx={{ color: '#3f51b5' }} />
                                        </span>
                                        <span>{row?.product?.title}</span>
                                      </TableCell>
                                      <TableCell align="right">{row?.count}</TableCell>
                                    </TableRow>
                                  ))}
                                {storeData?.prodAnalyticTable?.data?.length === 0 && (
                                  <TableRow>
                                    <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                                      No Data Found
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </div>
              {/* Product Page End */}

              {/* Document Page Start */}
              <div
                role="tabpanel"
                hidden={currentTab !== 1}
                id={`simple-tabpanel-${1}`}
                aria-labelledby={`simple-tab-${1}`}
              >
                <Box sx={{ py: 3 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <div className="d-flex flex-column flex-md-row mb-3">
                        <Typography variant="h6" className=" black-login fs-6 my-1 my-md-3">
                          Daywise Access Graph
                        </Typography>
                        {smUp && <>&nbsp;&nbsp;</>}

                        <span className="note-text my-auto">( with respect to Visit Count )</span>
                      </div>

                      <AppWebsiteVisits
                        // title="Website Visits"
                        // subheader="Daywise Document with respect to Visit Count"
                        chartLabels={storeData?.docAccessGraph?.data
                          ?.sort((a, b) => (new Date(a?._id) > new Date(b?._id) ? 1 : -1))
                          ?.map((i) => moment(i?._id).add({ hour: '24' }).format('ll'))}
                        chartData={[
                          {
                            name: 'Count',
                            type: 'area',
                            fill: 'gradient',
                            data: storeData?.docAccessGraph?.data?.map((i) => i?.count),
                          },
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <div className="d-flex flex-column flex-md-row mb-3">
                        <Typography variant="h6" className=" black-login fs-6 my-1 my-md-3">
                          Top 5 Popularity Graph
                        </Typography>
                        {smUp && <>&nbsp;&nbsp;</>}

                        <span className="note-text my-auto">( with respect to Visit Count )</span>
                      </div>

                      <AppConversionRates
                        tooltipTitle={'Count :'}
                        chartData={storeData?.docAnalyticTable?.copy?.slice(0, 5)?.map((i) => ({
                          label: i?.resourceRef?.title,
                          value: i?.count,
                        }))}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <div className="d-flex">
                        <Typography variant="h6" className=" black-login fs-6 my-3">
                          Document Analytics Table
                        </Typography>
                      </div>

                      <Card className="bg-transparent bordre-0 shadow-none row justify-content-between">
                        <Form className="col-md-5 col-12 mb-3 ">
                          <InputGroup>
                            <InputGroup.Text className="bg-white border-0 pe-0 me-0 shadow-none">
                              <Iconify icon="ic:outline-search" />
                            </InputGroup.Text>
                            <Form.Control
                              className="border-0 shadow-none"
                              placeholder="Search By Name"
                              aria-label="Search"
                              value={storeData?.docAnalyticTable?.searchV}
                              onChange={filterTable('docAnalyticTable')}
                            />
                          </InputGroup>
                        </Form>
                      </Card>
                      <Box>
                        <Grid item xs={12}>
                          <TableContainer component={Paper}>
                            <Table sx={{ minWidth: smUp ? 650 : 'auto' }} aria-label="simple table">
                              <TableHeadCustom>
                                <TableRow>
                                  <TableCell align="left">Index</TableCell>
                                  <TableCell align="center">Name</TableCell>
                                  <TableCell align="right">No. of Downloads</TableCell>
                                </TableRow>
                              </TableHeadCustom>
                              <TableBody>
                                {storeData?.docAnalyticTable?.data?.length > 0 &&
                                  storeData?.docAnalyticTable?.data?.map((row, index) => (
                                    <TableRow key={row._id} sx={TableRowStyle}>
                                      <TableCell align="left">{index + 1}</TableCell>
                                      <TableCell align="center">{row?.resourceRef?.title}</TableCell>
                                      <TableCell align="right">{row?.count}</TableCell>
                                    </TableRow>
                                  ))}
                                {storeData?.docAnalyticTable?.data?.length === 0 && (
                                  <TableRow>
                                    <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                                      No Data Found
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </div>
              {/* Document Page End */}

              {/* Video Page Start */}
              <div
                role="tabpanel"
                hidden={currentTab !== 2}
                id={`simple-tabpanel-${2}`}
                aria-labelledby={`simple-tab-${2}`}
              >
                <Box sx={{ py: 3 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <div className="d-flex flex-column flex-md-row mb-3">
                        <Typography variant="h6" className=" black-login fs-6 my-1 my-md-3">
                          Daywise Access Graph
                        </Typography>
                        {smUp && <>&nbsp;&nbsp;</>}

                        <span className="note-text my-auto">( with respect to Visit Count )</span>
                      </div>

                      <AppWebsiteVisits
                        // title="Website Visits"
                        subheader="Daywise Video with respect to Visit Count"
                        chartLabels={storeData?.vidAccessGraph?.data
                          ?.sort((a, b) => (new Date(a?._id) > new Date(b?._id) ? 1 : -1))
                          ?.map((i) => moment(i?._id).add({ hour: '24' }).format('ll'))}
                        chartData={[
                          {
                            name: 'Count',
                            type: 'area',
                            fill: 'gradient',
                            data: storeData?.vidAccessGraph?.data?.map((i) => i?.count),
                          },
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <div className="d-flex flex-column flex-md-row mb-3">
                        <Typography variant="h6" className=" black-login fs-6 my-1 my-md-3">
                          Top 5 Popularity Graph
                        </Typography>
                        {smUp && <>&nbsp;&nbsp;</>}

                        <span className="note-text my-auto">( with respect to Visit Count )</span>
                      </div>

                      <AppConversionRates
                        tooltipTitle={'Count :'}
                        chartData={storeData?.vidAnalyticTable?.copy?.slice(0, 5)?.map((i) => ({
                          label: i?.resourceRef?.title,
                          value: i?.count,
                        }))}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <div className="d-flex">
                        <Typography variant="h6" className=" black-login fs-6 my-3">
                          Video Analytics Table
                        </Typography>
                      </div>

                      <Card className="bg-transparent bordre-0 shadow-none row justify-content-between">
                        <Form className="col-md-5 col-12 mb-3 ">
                          <InputGroup>
                            <InputGroup.Text className="bg-white border-0 pe-0 me-0 shadow-none">
                              <Iconify icon="ic:outline-search" />
                            </InputGroup.Text>
                            <Form.Control
                              className="border-0 shadow-none"
                              placeholder="Search By Name"
                              aria-label="Search"
                              value={storeData?.vidAnalyticTable?.searchV}
                              onChange={filterTable('vidAnalyticTable')}
                            />
                          </InputGroup>
                        </Form>
                      </Card>
                      <Box>
                        <Grid item xs={12}>
                          <TableContainer component={Paper}>
                            <Table sx={{ minWidth: smUp ? 650 : 'auto' }} aria-label="simple table">
                              <TableHeadCustom>
                                <TableRow>
                                  <TableCell align="left">Index</TableCell>
                                  <TableCell align="center">Name</TableCell>
                                  <TableCell align="right">No. of Views</TableCell>
                                </TableRow>
                              </TableHeadCustom>
                              <TableBody>
                                {storeData?.vidAnalyticTable?.data?.length > 0 &&
                                  storeData?.vidAnalyticTable?.data?.map((row, index) => (
                                    <TableRow key={row._id} sx={TableRowStyle}>
                                      <TableCell align="left">{index + 1}</TableCell>
                                      <TableCell align="center">{row?.resourceRef?.title}</TableCell>
                                      <TableCell align="right">{row?.count}</TableCell>
                                    </TableRow>
                                  ))}
                                {storeData?.vidAnalyticTable?.data?.length === 0 && (
                                  <TableRow>
                                    <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                                      No Data Found
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </div>
              {/* Video Page End */}

              {/* Social Page Start */}
              <div
                role="tabpanel"
                hidden={currentTab !== 3}
                id={`simple-tabpanel-${3}`}
                aria-labelledby={`simple-tab-${3}`}
              >
                <Box sx={{ py: 3 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <div className="d-flex flex-column flex-md-row mb-3">
                        <Typography variant="h6" className=" black-login fs-6 my-1 my-md-3">
                          Daywise Access Graph
                        </Typography>
                        {smUp && <>&nbsp;&nbsp;</>}

                        <span className="note-text my-auto">( with respect to Visit Count )</span>
                      </div>

                      <AppWebsiteVisits
                        // title="Website Visits"
                        subheader="Daywise Social with respect to Visit Count"
                        chartLabels={storeData?.socialAccessGraph?.data
                          ?.sort((a, b) => (new Date(a?._id) > new Date(b?._id) ? 1 : -1))
                          ?.map((i) => moment(i?._id).add({ hour: '24' }).format('ll'))}
                        chartData={[
                          {
                            name: 'Count',
                            type: 'area',
                            fill: 'gradient',
                            data: storeData?.socialAccessGraph?.data?.map((i) => i?.count),
                          },
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <div className="d-flex flex-column flex-md-row mb-3">
                        <Typography variant="h6" className=" black-login fs-6 my-1 my-md-3">
                          Top 5 Popularity Graph
                        </Typography>
                        {smUp && <>&nbsp;&nbsp;</>}

                        <span className="note-text my-auto">( with respect to Visit Count )</span>
                      </div>

                      <AppConversionRates
                        tooltipTitle={'Count :'}
                        chartData={storeData?.socialAnalyticTable?.copy?.map((i) => ({
                          label: i?._id,
                          value: i?.count,
                        }))}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <div className="d-flex">
                        <Typography variant="h6" className=" black-login fs-6 my-3">
                          Social Analytics Table
                        </Typography>
                      </div>

                      <Card className="bg-transparent bordre-0 shadow-none row justify-content-between">
                        <Form className="col-md-5 col-12 mb-3 ">
                          <InputGroup>
                            <InputGroup.Text className="bg-white border-0 pe-0 me-0 shadow-none">
                              <Iconify icon="ic:outline-search" />
                            </InputGroup.Text>
                            <Form.Control
                              className="border-0 shadow-none"
                              placeholder="Search By Name"
                              aria-label="Search"
                              value={storeData?.socialAnalyticTable?.searchV}
                              onChange={filterTable('socialAnalyticTable')}
                            />
                          </InputGroup>
                        </Form>
                      </Card>
                      <Box>
                        <Grid item xs={12}>
                          <TableContainer component={Paper}>
                            <Table sx={{ minWidth: smUp ? 650 : 'auto' }} aria-label="simple table">
                              <TableHeadCustom>
                                <TableRow>
                                  <TableCell align="left">Index</TableCell>
                                  <TableCell align="center">Name</TableCell>
                                  <TableCell align="right">No. of Clicks</TableCell>
                                </TableRow>
                              </TableHeadCustom>
                              <TableBody>
                                {storeData?.socialAnalyticTable?.data?.length > 0 &&
                                  storeData?.socialAnalyticTable?.data?.map((row, index) => (
                                    <TableRow key={row._id} sx={TableRowStyle}>
                                      <TableCell align="left">{index + 1}</TableCell>
                                      <TableCell align="center">
                                        {row?._id?.charAt(0)?.toUpperCase() + row?._id?.substring(1, row?._id?.length)}
                                      </TableCell>
                                      <TableCell align="right">{row?.count}</TableCell>
                                    </TableRow>
                                  ))}
                                {storeData?.socialAnalyticTable?.data?.length === 0 && (
                                  <TableRow>
                                    <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                                      No Data Found
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </div>
              {/* Social Page End */}
            </Box>
          </>
        )}
      </Container>
    </>
  );
}

export default UserResourceAnalytics;
