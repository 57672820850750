import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
// @mui
import { Box, ListItemText } from '@mui/material';
// components
import { StyledNavItem, StyledNavItemIcon } from './styles';
import Iconify from '../iconify/Iconify';
// utils
import { wocOrange, wocOrangeHover } from '../../utils/constants';
import apiMiddleWare from '../../utils/useApiMiddleWare';
import customToast from '../../utils/customToast';

export default function WebinarNavButton() {
    const webinarLogin = useCallback(async () => {
        try {
            const id =  toast.loading('Please wait...');
            const res = await apiMiddleWare({ url: '/users/buyer-login', type: 'post' });
            if(res?.data?.ok) {
                toast.dismiss(id);
                customToast({
                    updateId : id,
                    msg: 'Webinar login successful',
                    type: 'success',
                });
                setTimeout(() => window.open(res?.data?.url, '_blank'), 1500);
            }
        } catch (err) {
            console.log(err);
        }
    },[]);

    return (
        <Box onClick={webinarLogin}>
            <StyledNavItem
                sx={{
                    color: '#161521',
                    margin: '0 0.5rem',
                    '&.active': {
                        color: 'black',
                        bgcolor: wocOrange,
                        fontWeight: 'fontWeightBold',
                        '&:hover': {
                            color: 'white',
                        },
                    },
                    '&:hover': {
                        color: wocOrangeHover,
                    },
                }}
            >
                <StyledNavItemIcon>
                    <Iconify icon="mdi:virtual-meeting" width={25} />
                </StyledNavItemIcon>
                <ListItemText disableTypography primary={'Webinar'} />
            </StyledNavItem>
        </Box>
    );
};
