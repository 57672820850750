import { Box, Popover, Tooltip, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useNavigate } from 'react-router-dom';

import useResponsive from '../../hooks/useResponsive';
import { wocOrange, stepDetail } from '../../utils/constants';
import Iconify from '../iconify/Iconify';
import { Progresscontext } from './ProgressContext';

const doneIcon = 'mdi:tick-circle-outline';
const emptyIcon = 'material-symbols:error-outline';

// const NAV_WIDTH = 250;
const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;
// const toFixedNumber = 1;

const stepOrder = [
    'profile',
    'resource',
    'product',
    'contact',
    'showcase',
    'emailVerified',
    // 'phoneVerified'
];

function CommonCircularProgress() {
    const navigate = useNavigate();
    // const sessionInfo = useLocalStorage('sessionInfo');

    const {  
        status,
        currentScore,
    } = useContext(Progresscontext);
    const smUp = useResponsive('up', 'sm');

    const heightMinusD = 40;
    const heightMinusM = 15;
    const [open, setOpen] = useState(false);
    const id = open ? 'simple-popover' : undefined;

   
    const totalScore = 100;

    const handleOpenParent = (e) => {
        e.stopPropagation();
        handleOpenMenu(e);
    };
    const handleOpenMenu = (e) => {
        setOpen(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const numberCorrection = (ans) => {
        if (ans === Math.floor(ans)) {
            return ans;
        }
        return Number(ans).toFixed(1);
    };

    const goToFunc = (location) => () => {
        if (!location) return;
        navigate(location);
        handleCloseMenu();
    };

    return (
        <Box>
            <div
                className="flex-row d-flex py-2 justify-content-around m-2 align-items-center cursor-pointer"
                style={{
                    height: smUp ? `${HEADER_DESKTOP - heightMinusD}px` : `${HEADER_MOBILE - heightMinusM}px`,
                    backgroundColor: '#edeff2',
                    borderRadius: '10px',
                }}
                role="button"
                tabIndex={0}
                onClick={handleOpenParent}
                onKeyDown={handleOpenParent}
                aria-describedby={id}
            >
                <Typography className="flex-1" variant="button" sx={{ fontSize: '1rem' }}>
                    Profile completion
                </Typography>
                <Box
                    sx={{
                        width: '18%',
                    }}
                >
                    <CircularProgressbar
                        value={numberCorrection((currentScore / totalScore) * 100)}
                        text={`${numberCorrection((currentScore / totalScore) * 100)}%`}
                        styles={{
                            text: {
                                fontSize: smUp ? '1.5rem' : '1.3rem',
                                fontWeight: 600,
                                fill: 'black',
                            },
                            trail: {
                                // Trail color
                                // stroke: '#yellow',
                            },
                            path: {
                                // Path color
                                stroke:
                                    (currentScore / totalScore) * 100 <= 40
                                        ? '#fdc71a'
                                        : (currentScore / totalScore) * 100 < 80
                                        ? '#fdc71a'
                                        : wocOrange, // wocOrange
                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                strokeLinecap: 'butt',
                                // Customize transition animation
                                transition: 'stroke-dashoffset 2s ease 0s',
                                // Rotate the path
                            },
                            background: {
                                // fill: 'red',
                            },
                        }}
                    />
                </Box>
            </div>

            <Popover
                id={id}
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: smUp ? 'right' : 'center',
                }}
                // transformOrigin={{
                //     vertical: 'top',
                //     horizontal: smUp ? 'right' : 'center',
                // }}
                PaperProps={{
                    sx: {
                        // p: 1,
                        // px: 2,
                        width: 300,
                        '& .MuiMenuItem-root': {
                            // px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <div className="d-flex flex-column mb-3 px-2 px-md-3 mt-2 mt-md-3">
                    <div className="text-start font-bold mb-2">{numberCorrection((currentScore / totalScore) * 100)}% profile completed</div>
                    <div>
                        <ProgressBar
                            className='custom-progressBar-height' 
                            variant={
                                (currentScore / totalScore) * 100 <= 40 ? 'success' : (currentScore / totalScore) * 100 < 80 ? 'success' : 'success'
                            }
                            now={numberCorrection((currentScore / totalScore) * 100)}
                        />
                    </div>
                </div>
                {stepOrder?.map((i,index) => (
                    <>
                    <div
                        className="d-flex justify-content-between py-2 py-md-2 px-2 px-md-3 cursor-pointer hover-progress"
                        onClick={goToFunc(stepDetail?.[i]?.goToPage)}
                        onKeyDown={goToFunc(stepDetail?.[i]?.goToPage)}
                        role="button"
                        tabIndex={0}
                        key={i}
                    >
                        <div>{stepDetail[i]?.name}</div>
                        <div>
                            <Tooltip title={stepDetail[i]?.info} placement="left-start" className="cursor-pointer my-auto fs-2">
                                <Iconify icon={status?.[i] ? doneIcon : emptyIcon} color={status?.[i] ? 'green' : 'red'} width={30} />
                            </Tooltip>
                        </div>
                    </div> 
                    {index<stepOrder.length-1 && <div className='custom-hr' style={{height:'0.5px',background:'black',opacity:0.4}} />}
                    </>
                ))}
            </Popover>
        </Box>
    );
}

export default React.memo(CommonCircularProgress);
