/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Button, Container, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import QRCode from 'qrcode';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { Progresscontext } from '../../../../components/circular-progress/ProgressContext';
import Iconify from '../../../../components/iconify';
import Loader from '../../../../components/loader';
import CommonModal from '../../../../components/modal/CommonModal';
import useResponsive from '../../../../hooks/useResponsive';
import { BASE_URL, confirmGreen, defaultError, informaEventID, wocOrange, wocOrangeLight } from '../../../../utils/constants';
import customToast from '../../../../utils/customToast';
import { copyText, openInNewTab } from '../../../../utils/helper';
import apiMiddleWare from '../../../../utils/useApiMiddleWare';
import AddEditUserTemplate from './AddEditUserTemplate';

function UserTemplate() {
    const smUp = useResponsive('up', 'sm');
    const isLg = useResponsive('up', 'lg');

    const { setCallGuideStatus, status } = useContext(Progresscontext);

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [templateList, setTemplateList] = useState([]);
    const [eventStatusList, setEventStatusList] = useState({});
    const [callApi, setCallApi] = useState(false);
    const [defaultShowcase, setDefaultShowcase] = useState('');
    const [addTemplateData, setAddTemplateData] = useState({
        open: false,
        mode: 'add',
        data: {
            id: '',
        },
    });
    const [shareModalData, setShareModalData] = useState({
        open: false,
        data: {
            id: '',
            identifier: '',
            title: '',
            url: '',
            imgData: '',
        },
    });
    const [configureModal, setConfigureModal] = useState({
        open: false,
        data: {},
    });

    useEffect(() => {
        getInitialData();
        getEventStatus();
        getDefaultShowcase();
    }, [callApi, status?.profile, status?.resource, status?.product, status?.contact, status?.emailVerified]);

    useEffect(() => {
        if (shareModalData?.open) {
            createQR(shareModalData?.data?.url);
        }
    }, [shareModalData]);

    const getInitialData = async () => {
        try {
            setIsLoading(true);
            const toastID = toast.loading('Fetching Data...');

            const res = await apiMiddleWare({ url: `/showcase/list-all`, type: 'get' });
            if (!res?.data?.ok) {
                customToast({
                    updateId: toastID,
                    type: 'update',
                    msg: res?.response?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                    updateType: 'error',
                });
                setIsLoading(false);
                return false;
            }

            customToast({ updateId: toastID, type: 'update', msg: 'Data Fetched Successfully', updateType: 'success' });
            setTemplateList(res?.data?.packet);
            setIsLoading(false);
            return true;
        } catch (err) {
            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
            setIsLoading(false);
            return false;
        }
    };

    const getEventStatus = async () => {
        try {
            setIsLoading(true);
            const res = await apiMiddleWare({ url: `/showcase/get-event-request`, type: 'post' });
            if (!res?.data?.ok) {
                customToast({
                    type: 'error',
                    msg: res?.response?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                });
                setIsLoading(false);
                return false;
            }

            // customToast({ updateId: toastID, type: 'update', msg: 'Data Fetched Successfully', updateType: 'success' });
            const dataHere = res?.data?.packet;
            const statusObj = {};
            for (let i = 0; i < dataHere?.list?.length; i += 1) {
                const item = dataHere?.list?.[i];
                statusObj[item?._id] = item?.metaData;
            }
            setEventStatusList(statusObj);
            setIsLoading(false);
            return true;
        } catch (err) {
            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
            setIsLoading(false);
            return false;
        }
    };

    const getShowcaseData = async (id) => {
        try {
            const toastID = toast.loading('Fetching Data...');

            const res = await apiMiddleWare({ url: `/showcase/get/${id}`, type: 'get' });
            if (!res?.data?.ok) {
                customToast({
                    updateId: toastID,
                    type: 'update',
                    msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                    updateType: 'error',
                });
                return false;
            }

            setAddTemplateData((prev) => ({ ...prev, data: res?.data?.packet, open: true, mode: 'edit' }));
            customToast({ updateId: toastID, type: 'update', msg: 'Data Fetched Successfully', updateType: 'success' });
            return true;
        } catch (err) {
            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
            return false;
        }
    };

    // Add Edit Function
    const closeAddEditModal = (callApiAagin = false) => {
        setAddTemplateData((prev) => ({ ...prev, open: false }));

        if (callApiAagin === true) {
            setCallGuideStatus((prev) => !prev);
            setCallApi((prev) => !prev);
        }
    };

    const openAddEditModal = (modeParam = 'add', id) => {
        if (modeParam === 'edit') getShowcaseData(id);
        else setAddTemplateData((prev) => ({ ...prev, open: true, mode: modeParam }));
    };

    // Share Function
    const openShareModal = (modeParam, id, identifier, title) => {
        setShareModalData((prev) => ({
            ...prev,
            open: true,
            data: {
                ...prev.data,
                id,
                identifier,
                title,
                url: `${BASE_URL}/qr/showcase/${id}`,
                imgData: '',
            },
        }));
    };
    const closeShareModal = () => {
        setShareModalData((prev) => ({
            ...prev,
            open: false,
            data: {
                ...prev.data,
                id: '',
                identifier: '',
                title: '',
                url: '',
                imgData: '',
            },
        }));
    };

    const createQR = (url1) => {
        QRCode?.toDataURL(
            url1,
            {
                width: 300,
                color: {
                    dark: '#252525',
                    light: '#fff',
                },
            },
            (err, url2) => {
                setShareModalData((prev) => ({
                    ...prev,
                    data: {
                        ...prev.data,

                        imgData: url2,
                    },
                }));
            }
        );
    };

    // View Function
    const openViewModal = (modeParam, identifier) => {
        openInNewTab(`${window.location.origin}/showcase/${identifier}`)();
    };

    // Delete Function
    const openDeleteModal = (modeParam, id, name) => {
        Swal.fire({
            title: `Are you sure you want to delete Showcase ${name}?`,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            customClass: { container: 'zIndex-10000 ' },
        }).then((result) => {
            if (result.isConfirmed) {
                callDeleteShowcase(id);
            }
        });
    };
    const callDeleteShowcase = async (id) => {
        try {
            // setIsLoading(true);
            const toastID = toast.loading('Please wait...');

            const res = await apiMiddleWare({ url: `/showcase/template/delete/${id}`, type: 'delete' });
            if (res?.data?.ok) {
                customToast({
                    updateId: toastID,
                    type: 'update',
                    msg: 'Showcase Deleted Successfully',
                    updateType: 'success',
                });
                setCallApi((prev) => !prev);
                setCallGuideStatus((prev) => !prev);
            } else {
                customToast({
                    updateId: toastID,
                    type: 'update',
                    msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                    updateType: 'error',
                });
            }
            // setIsLoading(false);
        } catch (err) {
            // setIsLoading(false);
            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
            console.error('callDeleteShowcase error', err);
        }
    };

    const onSubmitEventID = async ({ id, type = 'publish' }) => {
        if (id === '') {
            customToast({
                msg: 'Select Showcase ID !!!',
                type: 'error',
            });
            return false;
        }
        try {
            let url = '';
            if (type === 'publish') url = '/showcase/event-request';
            else if (type === 'unpublish') url = '/showcase/event-request-remove';

            const sendData = {
                eventID: informaEventID,
                showcaseRef: id,
            };
            toast.loading(`${type.charAt(0).toUpperCase() + type.slice(1)}ing Data...`);
            const res = await apiMiddleWare({ url, type: 'post', data: sendData });
            if (!res?.data?.ok) {
                // * removed error toast because apiMiddleWare already handles it
                // * the apiMiddleWare using axios to send a request to the server -> if any error occurs it will be handled by apiMiddleWare catch block
                // * so need show 2 error message to the user
                // if (res?.data?.errType === 'verify') {
                //   openVerifySwal({ message: res?.data?.message });
                //   customToast({
                //     msg: 'Verify Error' || defaultError,
                //     type: 'error',
                //   });
                // } else {
                //   customToast({
                //     msg: res?.data?.message || res?.data?.errors?.[0]?.message || res?.data?.errors?.[0]?.msg || defaultError,
                //     type: 'error',
                //   });
                // }
                return false;
            }
            toast.dismiss();
            let successMsg = '';
            if (type === 'publish') successMsg = 'Request sent to the REI Connect Team';
            else if (type === 'unpublish') successMsg = 'Showcase Successfully Unpublished';
            customToast({
                msg: successMsg,
                type: 'success',
            });
            setCallApi((prev) => !prev);
            setCallGuideStatus((prev) => !prev);
            return true;
        } catch (err) {
            customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
            return false;
        }
    };

    const openVerifySwal = ({ message }) => {
        Swal.fire({
            icon: 'error',
            title: `${message}`,
            // html: `<div>${}</div>`,
            showCancelButton: true,
            confirmButtonText: 'Verify Now',
            confirmButtonColor: wocOrange,
            customClass: { container: 'zIndex-10000 ' },
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/user/myprofile/verify');
            }
        });
    };

    const publishRequestSwal =
        ({ id, name }) =>
        () => {
            // check if this user already has published one showcase in event
            if (Object.keys(eventStatusList)?.length) {
                // Published List Not Empty

                Swal.fire({
                    icon: 'warning',
                    title: `Are You Sure?`,
                    html: '<div>One of the showcase is already connected with the event.</div><div>If you publish this showcase the other showcase data will be removed from the event.<div>',
                    showCancelButton: true,
                    confirmButtonText: 'Publish',
                    confirmButtonColor: wocOrange,
                    customClass: { container: 'zIndex-10000 ' },
                }).then((result) => {
                    if (result.isConfirmed) {
                        onSubmitEventID({ id, type: 'publish' });
                    }
                });
            } else {
                // Published List Empty
                Swal.fire({
                    icon: 'question',
                    title: `Are You Sure?`,
                    html: `<div>You want to publish ${name} ? </div>`,
                    showCancelButton: true,
                    confirmButtonText: 'Publish',
                    confirmButtonColor: wocOrange,
                    customClass: { container: 'zIndex-10000 ' },
                }).then((result) => {
                    if (result.isConfirmed) {
                        onSubmitEventID({ id, type: 'publish' });
                    }
                });
            }
        };

    const unpublishRequestSwal =
        ({ id, name }) =>
        () => {
            Swal.fire({
                icon: 'question',
                title: `Are You Sure`,
                html: `<div>You want to unpublish ${name} ?</div>`,
                showCancelButton: true,
                confirmButtonText: 'Unpublish',
                confirmButtonColor: wocOrange,
                customClass: { container: 'zIndex-10000 ' },
            }).then((result) => {
                if (result.isConfirmed) {
                    onSubmitEventID({ id, type: 'unpublish' });
                }
            });
        };

    const openConfigurePage =
        ({ data }) =>
        () => {
            navigate('/user/showcase/template/product', { state: data });

            // setConfigureModal((prev) => ({ ...prev, open: true, data }));
        };

    const onCloseConfigure = (status) => {
        setConfigureModal((prev) => ({ ...prev, open: false, data: {} }));
    };

    const createWithAllDataSwal = ({ type = 'add', id = null }) => {
        Swal.fire({
            icon: 'question',
            title: `Are You Sure`,
            html:
                type === 'add'
                    ? `<div>You want to create a showcase with all the data ?</div>`
                    : `<div>You want to update all the showcase data ?</div>`,
            showCancelButton: true,
            confirmButtonText: 'Create',
            confirmButtonColor: wocOrange,
            customClass: { container: 'zIndex-10000 ' },
        }).then((result) => {
            if (result.isConfirmed) {
                createWithAllData({ id });
            }
        });
    };

    const createWithAllData = async ({ id }) => {
        try {
            const toastID = toast.loading('Fetching Data...');

            const url = '/showcase/template/create-all-data';
            const sendData = { id: id || null };

            const res = await apiMiddleWare({ url, type: 'post', data: sendData });

            if (!res?.data?.ok) {
                // customToast({
                //   updateId: toastID,
                //   type: 'update',
                //   msg: res?.data?.errors[0]?.msg || defaultError,
                //   updateType: 'error',
                // });
                return false;
            }

            const successMsg = id ? 'Showcase Updated Successfully' : 'Successfully Created Showcase';

            customToast({ updateId: toastID, type: 'update', msg: successMsg, updateType: 'success' });
            setCallApi((prev) => !prev);
            setCallGuideStatus((prev) => !prev);
            return true;
        } catch (err) {
            customToast({
                msg: err?.response?.data?.errors?.[0]?.message || err?.message || err?.msg || defaultError,
                type: 'error',
            });
            return false;
        }
    };

    const getDefaultShowcase = async () => {
        try {
            const url = '/company/default-showcase';

            const res = await apiMiddleWare({ url, type: 'get' });

            if (!res?.data?.ok) {
                customToast({
                    type: 'error',
                    msg: res?.data?.errors[0]?.msg || defaultError,
                });
                return false;
            }
            setDefaultShowcase(res?.data?.packet?.defaultShowcaseRef);

            return true;
        } catch (err) {
            customToast({
                msg: err?.response?.data?.errors?.[0]?.message || err?.message || err?.msg || defaultError,
                type: 'error',
            });
            return false;
        }
    };

    return (
        <Container maxWidth="xl" sx={{ px: smUp ? 4 : 2, pb: 2 }} className="contact-page h-100">
            {addTemplateData?.open && (
                <AddEditUserTemplate
                    open={addTemplateData?.open}
                    onClose={closeAddEditModal}
                    templateList={templateList}
                    mode={addTemplateData?.mode}
                    editData={addTemplateData?.data}
                />
            )}
            {shareModalData?.open && (
                <CommonModal
                    open={shareModalData?.open}
                    onClose={closeShareModal}
                    customBodyStyle={' extra-height '}
                    title={`Share - ${shareModalData?.data?.title}`}
                    customFooter={<></>}
                    noFooterLine
                    body={
                        <div className="d-flex flex-column">
                            <div className="mx-auto d-flex mb-2 flex-wrap">
                                <div className="mx-auto mb-3">
                                    <TextField
                                        className="mx-2 "
                                        sx={{ width: 'auto', minWidth: '320px', maxWidth: '550px' }}
                                        value={shareModalData?.data?.url}
                                        disabled
                                    />
                                </div>
                                <div className="mx-auto mb-3">
                                    <Button variant="contained" className="display-5 mx-2 h-100" onClick={openInNewTab(shareModalData?.data?.url)}>
                                        <Tooltip title={'Open Link'}>
                                            <Iconify icon="mdi:link-variant" />
                                        </Tooltip>
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className="display-5 mx-2 h-100"
                                        onClick={() => copyText(shareModalData?.data?.url, 'Link Copied')}
                                    >
                                        <Tooltip title={'Copy Link'}>
                                            <Iconify icon="material-symbols:content-copy-outline-rounded" />
                                        </Tooltip>
                                    </Button>
                                </div>
                            </div>
                            <div className="mx-auto border-custom p-1 mb-2">
                                <img src={shareModalData?.data?.imgData} alt="QR CODE" width={250} />
                            </div>
                            <div className="mx-auto">
                                <Button
                                    variant="contained"
                                    href={shareModalData?.data?.imgData}
                                    download={`${shareModalData?.data?.identifier || 'unnamed'}.png`}
                                >
                                    <span>Download QR Code</span>
                                </Button>
                            </div>
                            <div className="mx-auto d-flex gap-2 display-6 flex-wrap d-flex justify-content-center mt-4 mt-md-3">
                                <div
                                    className="circle-icon"
                                    onClick={() =>
                                        copyText(
                                            `<div style="height: 100vh;width:100vw;"> <!-- Change dimensions according to your need -->
                                                <iframe id="samaaro-showcase" style="height: 100%;width:100%;margin: 0 auto ; overflow: auto;"src="${shareModalData?.data?.url}" frameborder="0"> </iframe>
                                            </div>`,
                                            'Link Copied'
                                        )
                                    }
                                >
                                    <Tooltip title={'Copy Embeded Code'}>
                                        <Iconify icon="fluent-mdl2:embed" width="10" />
                                    </Tooltip>
                                </div>
                                <div
                                    className="circle-icon"
                                    onClick={openInNewTab(`https://twitter.com/intent/tweet?url=${shareModalData?.data?.url}`)}
                                >
                                    <Tooltip title={'Share in Twitter'}>
                                        <Iconify icon="logos:twitter" width="10" />
                                    </Tooltip>
                                </div>

                                <div
                                    className="circle-icon"
                                    onClick={openInNewTab(`https://www.linkedin.com/sharing/share-offsite/?url=${shareModalData?.data?.url}`)}
                                >
                                    <Tooltip title={'Share in Linkedin'}>
                                        <Iconify icon="logos:linkedin-icon" width="10" />
                                    </Tooltip>
                                </div>

                                <div
                                    className="circle-icon"
                                    onClick={openInNewTab(`https://www.facebook.com/sharer.php?u=${shareModalData?.data?.url}`)}
                                >
                                    <Tooltip title={'Share in Facebook'}>
                                        <Iconify icon="ic:baseline-facebook" width="10" />
                                    </Tooltip>
                                </div>

                                <div
                                    className="circle-icon"
                                    onClick={openInNewTab(`https://web.whatsapp.com/send?text=${shareModalData?.data?.url}`)}
                                >
                                    <Tooltip title={'Share in Whatsapp'}>
                                        <Iconify icon="ic:baseline-whatsapp" width="10" />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    }
                />
            )}

            <div className="row">
                {!isLoading ? (
                    <Box>
                        <Typography variant="h6">You need to complete the following tasks to publish your Seller Profile:</Typography>
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <Iconify icon={status?.profile ? 'mdi:check' : 'mdi:close'} color={status?.profile ? 'green' : 'red'} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Button variant="contained" onClick={() => navigate('/user/showcase/profile')}>
                                            Company Profile
                                        </Button>
                                    }
                                    secondary="All the fields are mandatory to publish the Seller Profile"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Iconify icon={status?.resource ? 'mdi:check' : 'mdi:close'} color={status?.resource ? 'green' : 'red'} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Button variant="contained" onClick={() => navigate('/user/showcase/resource')}>
                                            Media & Documents
                                        </Button>
                                    }
                                    secondary="At least 1 Gallery item and 1 Catalogue item is mandatory to publish the Seller Profile"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Iconify icon={status?.product ? 'mdi:check' : 'mdi:close'} color={status?.product ? 'green' : 'red'} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Button variant="contained" onClick={() => navigate('/user/showcase/product')}>
                                            Product Portfolio
                                        </Button>
                                    }
                                    secondary="At least 1 Product is mandatory to publish the Seller Profile"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Iconify icon={status?.contact ? 'mdi:check' : 'mdi:close'} color={status?.contact ? 'green' : 'red'} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Button variant="contained" onClick={() => navigate('/user/showcase/contact')}>
                                            Contact Details
                                        </Button>
                                    }
                                    secondary="At least 1 Representative and all the Company Contact details are mandatory to publish the Seller Profile"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Iconify
                                        icon={status?.emailVerified ? 'mdi:check' : 'mdi:close'}
                                        color={status?.emailVerified ? 'green' : 'red'}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Button variant="contained" onClick={() => navigate('/user/myprofile/verify')}>
                                            Verify Email Id
                                        </Button>
                                    }
                                />
                            </ListItem>
                        </List>
                        {/* fist showcase */}
                        {templateList?.map(
                            (item, index) =>
                                index === 0 && (
                                    <Box
                                        key={index}
                                        sx={{
                                            position: 'fixed',
                                            bottom: 0,
                                            right: 0,
                                            left: isLg ? 250 : 0,
                                            bgcolor: 'white',
                                            display: 'flex',
                                            alignItems: 'center',
                                            p: 1,
                                            boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
                                            gap: 2,
                                        }}
                                    >
                                        <div className="d-flex justify-content-end w-100 gap-3">
                                            <Button sx={{color:'black',background:"none",boxShadow:"none"}} onClick={() => openViewModal('view', item?.identifier)}>
                                                <Iconify icon="mdi:eye" color={'black'} style={{ marginRight: '10px' }} />
                                                Preview
                                            </Button>
                                            <Button
                                                sx={{background:confirmGreen,":hover":{background:confirmGreen},boxShadow:"none"}}
                                                variant="contained"
                                                onClick={
                                                    item?.accessType === 'event'
                                                        ? unpublishRequestSwal({ id: item?._id, name: item?.name })
                                                        : publishRequestSwal({ id: item?._id, name: item?.name })
                                                }
                                                disabled={
                                                    !(
                                                        status?.profile &&
                                                        status?.resource &&
                                                        status?.product &&
                                                        status?.contact &&
                                                        status?.emailVerified
                                                    )
                                                }
                                            >
                                                <Iconify
                                                    icon={item?.accessType === 'event' ? 'mdi:close' : 'mdi:check'}
                                                    color={'white'}
                                                    style={{ marginRight: '10px' }}
                                                />
                                                {item?.accessType === 'event' ? 'Unpublish' : 'Publish'}
                                            </Button>
                                        </div>
                                        {item?.accessType === 'event' && (
                                            <Tooltip
                                                title={eventStatusList?.[item?._id]?.eventStatus || 'Rejected'}
                                                placement="bottom"
                                                className="cursor-pointer"
                                            >
                                                <Iconify
                                                    width={30}
                                                    icon="octicon:dot-fill-16"
                                                    style={{
                                                        color:
                                                            eventStatusList?.[item?._id]?.eventStatus === 'Pending'
                                                                ? 'yellow'
                                                                : eventStatusList?.[item?._id]?.eventStatus === 'Accepted'
                                                                ? 'green'
                                                                : 'red',
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                    </Box>
                                )
                        )}
                    </Box>
                ) : (
                    <Loader />
                )}
            </div>
        </Container>
    );
}

export default UserTemplate;
