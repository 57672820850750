import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { AppBar, Box, IconButton, Stack, Tab, Tabs, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
// components
import useResponsive from '../../../../hooks/useResponsive';
// import AccountPopover from './AccountPopover';
import Iconify from '../../../../components/iconify';
import { wocOrange } from '../../../../utils/constants';

// ----------------------------------------------------------------------

const NAV_WIDTH = 250;
const HEADER_MOBILE = 100;
const HEADER_DESKTOP = 100;

const StyledRoot = styled(AppBar)(({ theme }) => ({
    // ...bgBlur({ color: theme.palette.background.default }),
    // backgroundColor: '#f2f7ff',
    backgroundColor: 'white',
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    },
    boxShadow: '5px 2px 6px 0px #0022443D',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_MOBILE,
    flexWrap: 'wrap',
    [theme.breakpoints.up('lg')]: {
        minHeight: HEADER_DESKTOP,
        padding: theme.spacing(0, 5),
    },
    flexDirection: 'column',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'baseline',
}));

// ----------------------------------------------------------------------

Header.propTypes = {
    onOpenNav: PropTypes.func,
};

const TAB_CONFIG = {
    overview: 'Overview',
    'resource-analytics': 'Resource Analytics',
    queries: 'Queries',
    marketplace: 'Marketplace',
    'lead-capture': 'Lead Capture',
};

export default function Header({ onOpenNav }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('overview');
    const smUp = useResponsive('up', 'sm');

    // * re render the tab when location changes
    useEffect(() => {
        const path = location.pathname.split('/').pop();
        if (Object.keys(TAB_CONFIG).includes(path)) {
            setActiveTab(path);
        }
    }, [location]);

    // * navigate to different page
    const navigateTo = (path) => {
        navigate(`/user/dashboard/${path}`);
    };

    return (
        <StyledRoot>
            <StyledToolbar>
                <Stack direction={'row'} alignItems={'center'}>
                    <IconButton
                        onClick={onOpenNav}
                        sx={{
                            mr: 1,
                            color: 'text.primary',
                            display: { lg: 'none' },
                        }}
                    >
                        <Iconify icon="eva:menu-2-fill" />
                    </IconButton>
                    <Typography variant="h5" gutterBottom className="black-login my-0 ms-0" sx={{ fontSize: smUp ? '1.25rem' : '.9rem' }}>
                        Analytics
                    </Typography>
                </Stack>
                <Box sx={{ overflowX: 'auto', minWidth: '100%' }}>
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        value={Object.keys(TAB_CONFIG).indexOf(activeTab)}
                        sx={{
                            height: '35px',
                            minHeight: '35px',
                        }}
                    >
                        {/* tab nav bar for navigate to different page */}
                        {Object.keys(TAB_CONFIG).map((key) => (
                            <Tab
                                key={key}
                                label={TAB_CONFIG[key]}
                                onClick={() => {
                                    navigateTo(key);
                                    setActiveTab(key);
                                }}
                                sx={{
                                    fontWeight: activeTab === key ? 'bold' : 'normal',
                                    color: activeTab === key ? wocOrange : '#161521',
                                    margin: '0 0.5rem',
                                    borderBottom: activeTab === key ? `2px solid ${wocOrange}` : 'none',
                                    hight: '35px !important',
                                    minHeight: '35px !important',
                                    padding: '0px 30px 0px 10px',
                                }}
                            />
                        ))}
                    </Tabs>
                </Box>
            </StyledToolbar>
            {/* <StyledToolbar>
                <IconButton
                    onClick={onOpenNav}
                    sx={{
                        mr: 1,
                        color: 'text.primary',
                        display: { lg: 'none' },
                    }}
                >
                    <Iconify icon="eva:menu-2-fill" />
                </IconButton>

                <Searchbar />
                <Stack direction="row" alignItems="center">
                    <Typography variant="h4" gutterBottom className="black-login my-auto ms-0" sx={{ fontSize: smUp ? '1.25rem' : '.9rem' }}>
                        { TAB_CONFIG[activeTab] }
                    </Typography>
                </Stack>
                <Box sx={{ flexGrow: 1 }} />

                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{
                        xs: 0.5,
                        sm: 1,
                    }}
                    className="mx-auto me-md-0 my-2 my-md-auto"
                >
                    <AccountPopover />
                </Stack>
            </StyledToolbar> */}
        </StyledRoot>
    );
}
