import { Button } from '@mui/material';
import { TourProvider } from '@reactour/tour';

import useResponsive from '../../hooks/useResponsive';
import { wocOrange } from '../../utils/constants';

const stepsData = {
    profile: {
        selector: 'tour__profile',
        name: 'profile',
        image: 'profile-guide.jpg',
        description: `Create your company profile to showcase essential details about your business, such as your company name,industry, and an engaging company overview.`,
    },
    resource: {
        selector: 'tour__resource',
        name: 'resource',
        image: 'resource-guide.jpg',
        description: `Upload relevant media and documents that highlight your company's strengths, including images, brochures,videos, or any other media assets that represent your brand effectively.`,
    },
    product: {
        selector: 'tour__product',
        name: 'profile',
        image: 'product-guide.jpg',
        description: `Showcase your products by uploading detailed information, images, and descriptions of the products your company offers. Highlight their unique features and benefits to attract potential customers.`,
    },
    contact: {
        selector: 'tour__contact',
        name: 'contact',
        image: 'contact-guide.png',
        description: `Provide your company's contact information, such as email addresses, phone numbers, and social media links, to ensure potential customers can easily reach out and connect with you.`,
    },
    template: {
        selector: 'tour__template',
        name: 'template',
        image: 'showcase-guide.jpg',
        description: `You can preview your profile and make any changes if needed. Once your seller profile is ready to publish on the marketplace, click publish and get started with generating leads.`,
    },
};

const steps =
    Object.keys(stepsData)?.map((i) => {
        const thisData = stepsData[i] || {};
        return {
            name: i,
            selector: `[data-tour="${thisData?.selector}"]`,
            content: () => (
                <>
                    <div className="fs-5 fw-bold text-center">User Guide</div>
                    <div>
                        <img src={`/assets/images/welcomeModal/${thisData?.image}`} alt="" className="user-guide-img-h mx-auto" />
                    </div>
                    <div>{thisData?.description}</div>
                </>
            ),
        };
    }) || [];

export default function CustomTourProvider({ children }) {
    const radius = '12px';
    const smUp = useResponsive('up', 'sm');
    return (
        <TourProvider
            steps={steps}
            // position={(p) => {
            //     const totalHeightFromTop = p.top + p.height;
            //     let distanceTop;
            //     if (totalHeightFromTop > p.bottom) {
            //         distanceTop = p.top - p.height - 20;
            //     } else {
            //         distanceTop = p.top + p.height + 20;
            //     }

            //     return [smUp ? 250 : 30, distanceTop];
            // }}
            startAt={0}
            padding={{
                mask: 5,
            }}
            styles={{
                popover: (base) => ({
                    ...base,
                    '--reactour-accent': wocOrange,
                    borderRadius: radius,
                }),
                maskArea: (base) => ({ ...base, rx: radius }),
                // maskWrapper: (base) => ({ ...base, color: wocOrange }),
                badge: (base) => ({ ...base, right: 'auto', left: '0.8125em' }),
                controls: (base) => ({ ...base, marginTop: 10 }),
                close: (base) => ({
                    ...base,
                    left: 'auto',
                    right: '0.8125em',
                    top: -8,
                    height: 20,
                    width: 20,
                    padding: 4,
                    background: wocOrange,
                    borderRadius: '4px',
                    color: 'white',
                }),
            }}
            badgeContent={({ totalSteps, currentStep }) => `${currentStep + 1}/${totalSteps}`}
            prevButton={({ currentStep, setCurrentStep, steps }) => {
                const first = currentStep === 0;
                return (
                    <Button
                        variant="contained"
                        disabled={first}
                        className={first ? 'cursor-not-allowed' : ''}
                        onClick={() => {
                            if (first) {
                                setCurrentStep((s) => steps.length - 1);
                            } else {
                                setCurrentStep((s) => s - 1);
                            }
                        }}
                    >
                        Prev
                    </Button>
                );
            }}
            nextButton={({ currentStep, setCurrentStep, stepsLength, steps }) => {
                const last = currentStep === stepsLength - 1;
                return (
                    <Button
                        variant="contained"
                        disabled={last}
                        className={last ? 'cursor-not-allowed' : ''}
                        onClick={() => {
                            setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1));
                        }}
                    >
                        Next
                    </Button>
                );
            }}
        >
            {children}
        </TourProvider>
    );
}
