export const BASE_URL = 'https://woc.samaaro.com';
// export const BASE_ANALYTICS_URL = 'https://analytics.sellers.reiconnect.online';
export const BASE_ANALYTICS_URL = process.env.BASE_ANALYTICS_URL || 'https://analytics.sellers.woc.samaaro.com';
// export const BASE_URL = 'http://localhost:3000';
export const BUYER_BASE_MARKETPLACE = 'https://woc.samaaro.com/marketplace';

export const API_VER = 'v1';
export const API_BASE = '/api/';
export const API_BASE_ANALYTICS = '/analytics/';

export const informaEventID = 'woc.samaaro.com/UXtE-eIdo-qLWo-tSaW3';
export const defaultError = 'Something Went Wrong !!';
export const S3BucketBaseUrl = process.env.REACT_APP_PROJECT_BUCKET_BASE_URL;
export const defaultProductImg = '__static/thumbnail/no-product-image.png';
export const defaultLogoImg = '__static/thumbnail/no-logo.png';
export const defaultBannerImg = '__static/thumbnail/no-banner.png';
// "__static/banner/informa-banner-1.png"

export const userRouteName = 'user';
export const visitorRouteName = 'u';
export const showcaseRouteName = 'showcase';
export const adminRouteName = 'admin';
export const homePageVisitor = '/profile';
export const wocOrange = '#F26E33';
export const wocOrangeHover = '#F26E33';
export const wocOrangeLight = '#fed6c7';
export const confirmGreen="#007C47";

export const userRegSource = {
    edm: 'EDM',
    'social-media': 'Social Media',
    website: 'Website',
    whatsapp: 'Whatsapp',
    sms: 'SMS',
};

export const ckEditorConfig = {
    // placeholder :"Tell us about your company..",
    alignment: {
        options: ['left', 'right', 'center', 'justify'],
    },
    // wordCount: {
    //   onUpdate: (stats) => {
    //     // Prints the current content statistics.
    //     console.log(`Characters: ${stats.characters}\nWords: ${stats.words}`);
    //   },
    // },
    toolbar: {
        items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'alignment',
            'outdent',
            'indent',
            '|',
            'blockQuote',
            'insertTable',
            'undo',
            'redo',
        ],
    },
};

export const oldProductList = {
    'Solar Energy': [
        'Annual maintenance contractors',
        'Electrical test and measuring systems',
        'Emission monitoring systems',
        'Glass / substrates / coatings / resins / encapsulation materials / films / gasses',
        'Inverters / charge controllers / batteries / module connectors',
        'Light Manufacture',
        'Manufacturers of cell and module encapsulation',
        'Manufacturers of metal piles for foundation of Solar Installations',
        'Photovoltaic (PV) modules, hybrid systems, materials and equipments',
        'Screen-printing and metallization equipments',
        'Silicon feedstock / ingot / wafer manufacturing',
        'Software solutions and providers of irradiation data',
        'Solar cells manufacturers',
        'Solar drying and desalination systems',
        'Solar farm developers and owners',
        'Solar Pump Manufacturer',
        'Solar street and billboard lighting systems',
        'Solar water heating / cooling systems / solar pumps Testing',
        'Suppliers of raw materials',
        'System integrators / assemblers / distributors',
        'Turnkey solution providers',
        'Wire saws and consumables',
    ],
    'Bio-Energy': [
        'Analytical instruments manufacturers and service providers',
        'Bio-diesel / bio-ethanol distributors or refining companies',
        'Bio-diesel dispensing and mixing equipment manufacturers',
        'Biomass heating and drying equipment manufacturers',
        'Bioreactor / boiler / turbine and turbo-generator manufacturers',
    ],
    'Wind Energy': [
        'Assessment and forecasting service providers',
        'Buyers and sellers of old WEGs',
        'Civil engineering surveyors and contractors',
        'Component repairers (other than OEMs)',
        'Consultants and certification agencies',
        'Electrical and electronics items manufacturers',
        'Freight forwarders',
        'Hydraulic and mechanical items manufacturers and suppliers',
        'Insurance companies / surveyors / valuers Operations',
        'Stand-alone small aero-generator manufacturers & Training providers',
        'Tools and lubricants manufacturers',
        'erection / contractors / spares / components',
        'Wind electric generator (WEG) manufacturers',
        'Wind farm owner',
        'Wind monitoring and analysis systems suppliers',
        'Wind-Solar hybrid power system integrators',
    ],
    'Energy Storage and Electric and Hybrid Vehicle': [
        'Automation Equipment',
        'Automotive Components',
        'Battery Components & Materials',
        'Battery Manufacturer',
        'Battery Packs',
        'Channel Partners / Distributors',
        'Component Manufacturers',
        'Engineering Consultancy',
        'Fuel Cells',
        'Manufacturing Equipment',
        'Motors',
        'Policy Makers & Government representatives',
        'Power Management / BMS',
        'Prototyping',
        'Research',
        'Simulation / Software',
        'Testing',
        'Thermal Management',
    ],
    'Battery Technology': [
        'Automotive Components',
        'Battery Components',
        'Battery Management Systems',
        'Battery Materials',
        'Battery Manufacturer',
        'Battery Pack Assembly/Integrator',
        'Battery Safety Solutions',
        "Battery Testing Equipment's & services",
        'Cabling & Connectors',
        'Cell manufacturers',
        'Charging Infrastructure',
        'Coil Winding',
        'Engineering Services',
        'Engineering services,',
        'Fuel Cell',
        'Research & Development (R&D)',
        'Simulation',
        'Thermal Management',
    ],
};

export const productList = {
    'Concrete Batching Plant Manufactures': [
      'Random volumetric batching',
      'Volumetric batching',
      'Weight batching of concrete',
    ],
    'Concrete Mixer Trucks, Concrete Pump': [
      'Concrete Boom Pumps',
      'Concrete Mixer Pump',
      'Boom Pumps',
      'Trailer Pumps',
      'Grout Pumps',
      'Stationary Pumps',
    ],
    'Concrete Volumetric Mixer': [
      'Batch Mixer',
      'Drum Mixers',
      'Pan Type Mixers',
      'Tilting Drum Mixer',
      'Non-Tilting Drum Mixer',
      'Reversing Drum Mixer',
      'Continuous Mixer',
    ],
    'Material Handling for Concrete Production': [
      'Storage and Handling Equipment',
      'Engineered Material Handling Systems',
      'Industrial Material Handling Trucks',
      'Bulk Material Handling Equipment',
    ],
    'Additives': [
      'Contact Cement',
      'Non-structural adhesives',
      'Structural adhesives',
    ],
    'Aggregates & Chemicals for Concrete': [
      'Gravel',
      'Sand',
      'Recycled Concrete',
      'Slag',
      'Topsoil',
      'Ballast',
      'Type 1 MOT',
      'Geosynthetic Aggregates',
    ],
    'Precast Production Equipment': [
      'Precast piles',
      'Precast façade',
      'Hollow cored slab',
      'Precast staircases',
      'BEAMS',
      'Precast columns',
      'Double Tees',
      'Cement Blocks',
    ],
    'Scaffolding & Formwork': [
      'Plastic',
      'Wall formwork',
      'Steel formwork',
      'Plywood formwork',
      'Lumber',
      'Stay-in-place formwork',
      'Fabric formwork',
      'Aluminium',
      'Beam formwork',
    ],
    'Testing Equipment': [
      'Vicat apparatus',
      'Grout flow cones',
      'Blaine air permeability',
      'ASTM test sand',
      'Cube Molds',
      'Prism Molds',
    ],
    'AAC Block & Paver Production Equipment': [
      'Concrete blocks',
      'Interlocking Paver Block',
      'AAC blocks',
      'CLC blocks',
      'Paver Blocks',
      'Grey Concrete Block',
      'AAC Siporex Block',
      'Reflective Paver Blocks',
    ],
    'Construction Design & Management Software (BIM Software)': [
      'Procore',
      'Autodesk',
      'CoConstruct',
      'LLC',
      'Buildertrend',
      'Fieldwire',
      'Knowify',
    ],
  };

  export const typeOfBuisness=[
    'Contractors',
    'Sub-Contractors',
    'Purchasing Managers',
    'Construction Managers',
    'Project Managers',
    'Architects',
    'Consultants',
    'Structural Engineers',
    'Civil Engineers',
    'Real Estate Developers',
    'Government',
    'Municipal Authorities',
    'State Corporations',
    'Investors',
    'Planning Engineers'  
  ];

  

export const tableBgColor = '#e6e6e6';

// ================= SUB PRODUCT CATEGORY ALPHAETIZE =================
// Analytical instruments manufacturers and service providers,
// Annual maintenance contractors,
// Assessment and forecasting service providers,
// Automation Equipment,
// Automotive Components,
// Battery Components & Materials,
// Battery Manufacturer,
// Battery Packs,
// Bio-diesel / bio-ethanol distributors or refining companies,
// Bio-diesel dispensing and mixing equipment manufacturers,
// Biomass heating and drying equipment manufacturers,
// Bioreactor / boiler / turbine and turbo-generator manufacturers,
// Buyers and sellers of old WEGs,
// Channel Partners / Distributors,
// Civil engineering surveyors and contractors,
// Component Manufacturers,
// Component repairers (other than OEMs),
// Consultants and certification agencies,
// Electrical and electronics items manufacturers,
// Electrical test and measuring systems,
// Emission monitoring systems,
// Engineering Consultancy,
// Freight forwarders,
// Fuel Cells,
// Glass / substrates / coatings / resins / encapsulation materials / films / gasses,
// Hydraulic and mechanical items manufacturers and suppliers,
// Insurance companies / surveyors / valuers Operations,
// Inverters / charge controllers / batteries / module connectors,
// Manufacturers of cell and module encapsulation,
// Manufacturing Equipment,
// Motors,
// Photovoltaic (PV) modules / hybrid systems / materials and equipments,
// Policy Makers & Government representatives,
// Power Management / BMS,
// Prototyping,
// Research,
// Screen-printing and metallization equipments,
// Silicon feedstock / ingot / wafer manufacturing,
// Simulation / Software,
// Software solutions and providers of irradiation data,
// Solar cells manufacturers,
// Solar drying and desalination systems,
// Solar farm developers and owners,
// Solar street and billboard lighting systems,
// Solar water heating / cooling systems / solar pumps Testing,
// Stand-alone small aero-generator manufacturers & Training providers,
// Suppliers of raw materials,
// System integrators / assemblers / distributors,
// Testing,
// Thermal Management
// Tools and lubricants manufacturers,
// Turnkey solution providers,
// WEG erection / contractors / spares / components,
// Wind electric generator (WEG) manufacturers,
// Wind farm owner,
// Wind monitoring and analysis systems suppliers,
// Wind-Solar hybrid power system integrators,
// Wire saws and consumables

export const SHOWCASE_HEADER_NOTE = {
    profile: 'All the fields are mandatory',
    resource: 'Uploading 1 Gallery and 1 Catalogue Item is mandatory',
    product: 'Adding atleast 1 Product is mandatory',
    contact: 'Adding 1 Representative and all the Company Contact details are mandatory',
};

export const stepDetail = {
    profile: {
        name: 'Company Details',
        info: 'All the fields needed to complete the task',
        goToPage: '/user/showcase/profile',
        score: 18,
    },
    resource: {
        name: 'Gallery & Catalogue',
        info: 'At least 1 Media & 1 Document is needed to complete the task',
        goToPage: '/user/showcase/resource',
        score: 18,
    },
    product: {
        name: 'Product Portfolio',
        info: 'At least 1 Product is needed to complete the task',
        goToPage: '/user/showcase/product',
        score: 18,
    },
    contact: {
        name: 'Contact Details',
        info: 'At least 1 Representative, 1 Social Media Link and All Contact Details needed to complete the task',
        goToPage: '/user/showcase/contact',
        score: 18,
    },
    showcase: {
        name: 'Publish Profile',
        info: 'You need to publish the seller profile to complete the task',
        goToPage: '/user/showcase/template',
        score: 18,
    },
    emailVerified: {
        name: 'Verify Email',
        info: 'Verify your email to complete the task',
        goToPage: '/user/myprofile/verify',
        score: 10,
    },
    // phoneVerified: {
    //   name: 'Verify Phone Number',
    //   info: 'Verify your phone number to complete the task',
    //   goToPage: '/user/myprofile/verify',
    //   score: 10,
    // },
};