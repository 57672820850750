/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Modal, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Iconify from '../iconify/Iconify';
import { confirmGreen } from '../../utils/constants';

const borderRadiusModal = '20px';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  borderRadius: `${borderRadiusModal}`,
  // maxHeight: '80vh',
  minWidth: '60vw',
  maxWidth: '70vw',
};
const bigStyle = {};
const titleStyle = {
  p: 3,
  borderBottom: '1px solid #d0d0d0',
  borderRadius: `${borderRadiusModal} ${borderRadiusModal} 0 0`,
};
const bodyStyle = {
  ...titleStyle,
  // mt: 2,
  borderRadius: 0,
  maxHeight: '53vh',
  overflowY: 'auto',
  maxWidth: '100%',
};
const footerStyle = {
  ...titleStyle,
  display: 'flex',
  justifyContent: 'end',
  borderRadius: `0 0 ${borderRadiusModal} ${borderRadiusModal} `,
};

CommonModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  submitDisable: PropTypes.bool,
  onSubmit: PropTypes.func,
  submitText: PropTypes.string,
  title: PropTypes.string || PropTypes.element,
  customStyle: PropTypes.string,
  customFooter: PropTypes.element,
  body: PropTypes.element,
  size: PropTypes.string,
  noTitleLine: PropTypes.bool,
  noFooterLine: PropTypes.bool,
  modalStyle: PropTypes.element,
};

export default function CommonModal({
  open,
  onClose,
  submitDisable,
  onSubmit,
  submitText,
  title,
  body,
  customStyle,
  customBodyStyle,
  modalStyle,
  customFooter,
  size,
  noTitleLine,
  noFooterLine,
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEscapeKeyDown
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box
        sx={{
          ...style,
          ...modalStyle,
          minWidth: size === 'sm' ? '40vw' : style?.minWidth,
          '@media (max-width: 768px)': {
            width: '87vw',
            maxWidth: '90vw',
          },
        }}
        className={customStyle}
      >
        <div
          className="d-flex justify-content-between modal-modal-title"
          style={{
            ...titleStyle,
            borderBottom: noTitleLine === true ? '0px' : titleStyle?.borderBottom,
          }}
        >
          <Typography id="" className="m-3" variant="h5" component="h3">
            {title}
          </Typography>
          {onClose && (
            <div onClick={onClose} className="h-100 me-4 cursor-pointer my-auto d-flex justify-content-center">
              <Iconify icon="radix-icons:cross-2" sx={{ color: 'black' }} width={23} />
            </div>
          )}
        </div>
        <Typography
          sx={{
            ...bodyStyle,
            ...customBodyStyle,
            borderBottom: noFooterLine === true ? '0px' : bodyStyle?.borderBottom,
            padding: '.8rem',
            maxHeight: '69vh',
            '@media (max-width: 768px)': {
              maxWidth: '90vw',
              maxHeight: '66vh',
            },
          }}
          id="modal-modal-description"
          component="div"
        >
          {body}
        </Typography>
        <Typography sx={footerStyle} className="p-2 p-md-3" id="modal-modal-description">
          {customFooter || (
            <>
              <Button type="button" variant="outlined" color="error" onClick={onClose}>
                Cancel
              </Button>
              {onSubmit && (
                <Button
                  sx={{ ml: 2,background:confirmGreen,":hover":{background:confirmGreen} }}
                  type="button"
                  variant="contained"  
                  onClick={onSubmit}
                  disabled={submitDisable || false}
                >
                  { submitText || 'Submit'}
                </Button>
              )}
            </>
          )}
        </Typography>
      </Box>
    </Modal>
  );
}
