/* eslint-disable no-lonely-if */
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Box, Button, Container, Grid, IconButton, InputLabel, MenuItem, Popover, TextField } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Swal from 'sweetalert2';

import CommonAddButton from '../../../components/add-button/CommonAddButton';
import { Progresscontext } from '../../../components/circular-progress/ProgressContext';
import CustomInputLabel from '../../../components/label/CustomInputLabel';
import Iconify from '../../../components/iconify';
import ImageUpload from '../../../components/image-upload/ImageUpload';
import Loader from '../../../components/loader';
import WarningModal from '../../../components/modal/WarningModal';
import CommonModal from '../../../components/modal/CommonModal';

import useResponsive from '../../../hooks/useResponsive';

import { S3BucketBaseUrl, SHOWCASE_HEADER_NOTE, confirmGreen, defaultError, userRouteName } from '../../../utils/constants';
import customToast from '../../../utils/customToast';
import { copyText, getExtension, randomNumber, uploadImageToS3 } from '../../../utils/helper';
import apiMiddleWare from '../../../utils/useApiMiddleWare';
import ImageUploadNew from '../../../components/image-upload/imageUploadNew';

const commonBoxStyles = {
    bgcolor: '#fff',
    borderColor: 'transparent',
    p: 2,
    // border: 1,
    width: '100%',
    minHeight: '5rem',
};

const iconFontSize = '1.9rem';

function UserContact() {
    const navigate = useNavigate();

    const { setCallGuideStatus } = useContext(Progresscontext);

    const smUp = useResponsive('up', 'sm');
    const isLg = useResponsive('up', 'lg');

    const [open, setOpen] = useState(false);
    const [callApi, setCallApi] = useState(false);
    const [callRepApi, setCallRepApi] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [addEditData, setAddEditData] = useState({
        mode: 'add',
        modalOpen: false,
        data: {
            repID: '',
            name: '',
            designation: '',
            email: '',
            phone: '',
            gender: '',
            photo: {
                name: '',
                value: '',
                testImg: '',
            },
        },
    });
    const [inputData, setInputData] = useState({
        contactID: '',
        meetingS: {
            calendlyLink: '',
        },
        showcaseR: [
            // {
            //   name: '',
            //   designation: '',
            //   email: '',
            //   phone: '',
            // gender : '',
            // photo : { value :'', name: ''}
            // },
        ],
        socialM: {
            facebook: '',
            twitter: '',
            instagram: '',
            linkedin: '',
            pinterest: '',
            youtube: '',
        },
        company: {
            website: '',
            email: '',
            phone: '',
            address: '',
        },
    });

    // * waring modal state
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [missingFields, setMissingFields] = useState([]);

    // * warning modal functions
    // * close handler
    const handleClose = () => {
        setShowWarningModal(false);
    };
    const handleProceed = () => {
        navigate(`/${userRouteName}/showcase/template`);
    };

    useEffect(() => {
        getContactData();
    }, [callApi]);
    useEffect(() => {
        getRepData();
    }, [callRepApi]);

    const getContactData = async () => {
        try {
            setIsLoading(true);
            const res = await apiMiddleWare({ type: 'get', url: `/company/contact` });
            if (!res?.data?.ok) {
                customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
                setIsLoading(false);
                return false;
            }

            const dataHere = res?.data?.packet;
            setInputData((prev) => ({
                ...prev,
                contactID: dataHere?._id,
                meetingS: {
                    calendlyLink: dataHere?.calendlyLink,
                },
                // showcaseR: dataHere?.contactList,
                socialM: {
                    facebook: dataHere?.socialMedia?.facebook,
                    twitter: dataHere?.socialMedia?.twitter,
                    instagram: dataHere?.socialMedia?.instagram,
                    linkedin: dataHere?.socialMedia?.linkedin,
                    pinterest: dataHere?.socialMedia?.pinterest,
                    youtube: dataHere?.socialMedia?.youtube,
                },
                company: {
                    website: dataHere?.website,
                    email: dataHere?.email,
                    phone: dataHere?.phone,
                    address: dataHere?.address,
                },
            }));

            setIsLoading(false);
            return true;
        } catch (err) {
            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
            setIsLoading(false);
            return false;
        }
    };

    const getRepData = async () => {
        try {
            setIsLoading(true);
            const res = await apiMiddleWare({ type: 'get', url: `/company/reps` });
            if (!res?.data?.ok) {
                customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
                setIsLoading(false);
                return false;
            }

            const dataHere = res?.data?.packet;
            const updatedData =
                dataHere?.map((i) => {
                    let value;
                    let testImg;
                    if (i?.photo) {
                        value = i?.photo ? S3BucketBaseUrl + i?.photo : '';
                    } else {
                        if (i?.gender === 'Male') {
                            testImg = `/assets/icons/avatar/M-${randomNumber(1, 4)}.svg`;
                        } else if (i?.gender === 'Female') {
                            testImg = `/assets/icons/avatar/F-${randomNumber(1, 4)}.svg`;
                        } else {
                            testImg = `/assets/icons/avatar/M-1.svg`;
                        }
                    }
                    return {
                        ...i,
                        photo: { name: i?.photo || '', value, testImg },
                    };
                }) || [];
            setInputData((prev) => ({
                ...prev,
                showcaseR: updatedData,
            }));
            setIsLoading(false);
            return true;
        } catch (err) {
            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
            setIsLoading(false);
            return false;
        }
    };

    const handleOnChange = (type) => (e) => {
        const { name, value } = e.target;
        let valueChange = value;
        if (name === 'phone') {
            valueChange = valueChange.replace(/[^0-9-]/g, '').replace(/(\..*?)\..*/g, '$1');
            if (valueChange.length >= 14) valueChange = valueChange.substring(0, 14);
        }
        setInputData((prev) => ({
            ...prev,
            [type]: {
                ...prev[type],
                [name]: valueChange,
            },
        }));
    };

    const submitForm = async (e) => {
        try {
            e.preventDefault();
            const { name: nextType } = e.nativeEvent.submitter;
            const missingFields = [];
            if (inputData?.showcaseR?.length === 0) missingFields.push('Company Representatives');
            if (inputData?.company?.website === '') missingFields.push('Website');
            if (inputData?.company?.email === '') missingFields.push('Email');
            if (inputData?.company?.phone === '') missingFields.push('Phone');
            if (inputData?.company?.address === '') missingFields.push('Address');
            if (missingFields.length > 0) {
                setMissingFields(missingFields);
                setShowWarningModal(true);
                return;
            }

            const toastID = toast.loading('Please wait...');

            const sendData = await validateForm();

            const res = await apiMiddleWare({ type: 'post', url: `/company/contact`, data: sendData });
            if (!res?.data?.ok) {
                customToast({
                    updateId: toastID,
                    type: 'update',
                    msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                    updateType: 'error',
                });
                setIsLoading(false);
                return;
            }
            customToast({ updateId: toastID, type: 'update', msg: 'Contact Updated Successfully', updateType: 'success' });
            setIsLoading(false);
            setCallGuideStatus((prev) => !prev);

            if (nextType === 'next') {
                navigate(`/${userRouteName}/showcase/template`);
            } else if (nextType === 'share') {
                navigate(`/${userRouteName}/showcase/template`);
            }
            return;
        } catch (err) {
            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
        }
    };

    const validateForm = () =>
        new Promise((resolve, reject) => {
            const sendData = {
                calendlyLink: inputData?.meetingS?.calendlyLink,
                website: inputData?.company?.website,
                email: inputData?.company?.email,
                phone: inputData?.company?.phone,
                address: inputData?.company?.address,
                socialMedia: {
                    facebook: inputData?.socialM?.facebook,
                    instagram: inputData?.socialM?.instagram,
                    linkedin: inputData?.socialM?.linkedin,
                    twitter: inputData?.socialM?.twitter,
                    pinterest: inputData?.socialM?.pinterest,
                    youtube: inputData?.socialM?.youtube,
                },
            };

            if (sendData?.website === '') {
                reject(new Error(`Website is required !`));
                return false;
            }

            if (sendData?.email === '') {
                reject(new Error(`Email is required !`));
                return false;
            }

            if (sendData?.phone === '') {
                reject(new Error(`Phone is required !`));
                return false;
            }

            if (sendData?.address === '') {
                reject(new Error(`Address is required !`));
                return false;
            }

            resolve(sendData);
            return true;
        });

    const resetForm = async () => {
        try {
            const toastID = toast.loading('Please wait...');

            const sendData = {
                calendlyLink: [''],
                website: '',
                email: '',
                phone: '',
                address: '',
                socialMedia: {
                    facebook: '',
                    instagram: '',
                    linkedin: '',
                    twitter: '',
                    pinterest: '',
                    youtube: '',
                },
            };
            const res = await apiMiddleWare({ type: 'post', url: `/company/contact`, data: sendData });
            if (!res?.data?.ok) {
                customToast({
                    updateId: toastID,
                    type: 'update',
                    msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                    updateType: 'error',
                });
            }
            customToast({ updateId: toastID, type: 'update', msg: 'Contact Updated Successfully', updateType: 'success' });
            setIsLoading(false);
            setCallApi((prev) => !prev);
            return;
        } catch (err) {
            setIsLoading(false);
            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
        }
    };

    const resetFormSwal = () => {
        setIsLoading(true);
        Swal.fire({
            title: 'Are you sure you want to reset every field?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            customClass: { container: 'zIndex-10000 ' },
        }).then((result) => {
            if (result.isConfirmed) {
                resetForm();
            } else {
                setIsLoading(false);
                // Swal.fire('Changes are not saved', '', 'info');
            }
        });
    };

    const openRepModal = (type = 'add', data = {}) => {
        const dataObj = inputData?.showcaseR?.[data?.index] || {};
        setAddEditData((prev) => ({
            ...prev,
            mode: type,
            modalOpen: true,
            data: {
                repID: dataObj?._id || '',
                name: dataObj?.name || '',
                designation: dataObj?.designation || '',
                email: dataObj?.email || '',
                phone: dataObj?.phone || '',
                gender: dataObj?.gender || '',
                photo: dataObj?.photo || {},
            },
        }));
    };

    const resendInvite = async ({ id }) => {
        try {
            handleCloseMenu();
            setIsLoading(true);
            const toastID = toast.loading('Please wait...');

            const res = await apiMiddleWare({ url: `/company/rep/resend-email/${id}`, type: 'get' });
            if (res?.data?.ok) {
                customToast({
                    updateId: toastID,
                    type: 'update',
                    msg: 'Email sent successfully',
                    updateType: 'success',
                });
            } else {
                customToast({
                    updateId: toastID,
                    type: 'update',
                    msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                    updateType: 'error',
                });
            }

            setIsLoading(false);
        } catch (err) {
            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
            setIsLoading(false);
        }
    };

    const handleRepChange = (e) => {
        const { name, value } = e.target;
        let valueChange = value;
        if (name === 'phone') {
            valueChange = valueChange.replace(/[^0-9-]/g, '').replace(/(\..*?)\..*/g, '$1');
            if (valueChange.length >= 14) valueChange = valueChange.substring(0, 14);
        }
        setAddEditData((prev) => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: valueChange,
            },
        }));
    };

    const deleteRep = async (idHere) => {
        try {
            setIsLoading(true);
            const toastID = toast.loading('Please wait...');
            const sendData = { repID: idHere };
            const res = await apiMiddleWare({ url: `/company/rep/delete`, type: 'post', data: sendData });
            if (res?.data?.ok) {
                customToast({
                    updateId: toastID,
                    type: 'update',
                    msg: 'Representative Deleted Successfully',
                    updateType: 'success',
                });
                handleCloseMenu();
                setCallRepApi((prev) => !prev);
                setCallGuideStatus((prev) => !prev);
            } else {
                customToast({
                    updateId: toastID,
                    type: 'update',
                    msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                    updateType: 'error',
                });
            }
            setIsLoading(false);
        } catch (err) {
            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
            setIsLoading(false);
        }
    };

    const deleteSwalRep = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            customClass: { container: 'zIndex-10000 ' },
        }).then((result) => {
            if (result.isConfirmed) {
                deleteRep(id);
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info');
            }
        });
    };

    const submitRepModal = async () => {
        // e.preventDefault();
        try {
            setIsLoading(true);

            // await validateRepForm();
            const toastID = toast.loading('Please wait...');
            const sendData = {
                mode: addEditData?.mode,
                repID: addEditData?.data?.repID || '',
                name: addEditData?.data?.name,
                email: addEditData?.data?.email,
                designation: addEditData?.data?.designation,
                phone: addEditData?.data?.phone,
                gender: addEditData?.data?.gender,
                photo: '',
            };
            if (!sendData?.name) {
                customToast({
                    updateId: toastID,
                    type: 'update',
                    msg: 'Name is required',
                    updateType: 'error',
                });
                setIsLoading(false);
                return false;
            }
            if (!sendData?.email) {
                customToast({
                    updateId: toastID,
                    type: 'update',
                    msg: 'Email is required',
                    updateType: 'error',
                });
                setIsLoading(false);
                return false;
            }

            if (addEditData?.data?.photo?.value?.indexOf(S3BucketBaseUrl) === -1) {
                const { repPhoto } = await uploadImageToS3({
                    sendData: {
                        rep: addEditData?.data?.photo?.name?.split('/')?.pop() || '',
                        repType: getExtension(addEditData?.data?.photo?.value),
                    },
                    value: addEditData?.data?.photo?.value,
                });
                sendData.photo = repPhoto;
            }

            let res;
            if (addEditData?.mode === 'add') {
                res = await apiMiddleWare({ type: 'post', url: `/company/rep/add`, data: sendData });
            } else {
                res = await apiMiddleWare({ type: 'post', url: `/company/rep/update`, data: sendData });
            }

            if (!res?.data?.ok) {
                customToast({
                    updateId: toastID,
                    type: 'update',
                    msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                    updateType: 'error',
                });
                setIsLoading(false);
                return false;
            }
            setCallGuideStatus((prev) => !prev);

            closeRepModal(true);
            handleCloseMenu();
            customToast({
                updateId: toastID,
                type: 'update',
                msg: addEditData?.mode === 'add' ? 'Representative Added Successfully' : 'Representative Updated Successfully',
                updateType: 'success',
            });
            setIsLoading(false);
            return true;
        } catch (err) {
            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
            return false;
        }
    };

    const closeRepModal = (status = false) => {
        clearAddEditData();
        handleCloseMenu();
        if (status === true) {
            setTimeout(() => {
                setCallRepApi((prev) => !prev);
            }, [1200]);
        }
    };

    const clearAddEditData = () => {
        setAddEditData({
            mode: 'add',
            modalOpen: false,
            data: {
                repID: '',
                name: '',
                designation: '',
                email: '',
                phone: '',
            },
        });
    };

    const validateRepForm = async () =>
        new Promise((resolve, reject) => {
            if (addEditData?.data?.name === '') {
                reject(new Error(`Name is required`));
                return false;
            }
            if (addEditData?.data?.designation === '') {
                reject(new Error(`Designation is required`));
                return false;
            }
            if (addEditData?.data?.email === '') {
                reject(new Error(`Email is required`));
                return false;
            }
            if (addEditData?.data?.phone === '') {
                reject(new Error(`Phone is required`));
                return false;
            }
            if (addEditData?.data?.gender === '') {
                reject(new Error(`Gender is required`));
                return false;
            }
            resolve();
            return true;
        });

    const handleOpenMenu = (e) => {
        setOpen(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    function getPhotoHere(data) {
        if (data) {
            setAddEditData((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    photo: {
                        ...prev.data.photo,
                        value: data,
                    },
                },
            }));
        }
    }

    function clearImg(type) {
        if (type === 'photo') {
            setAddEditData((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    photo: {
                        ...prev.data.photo,
                        value: '',
                    },
                },
            }));
        }
    }

    return (
        <>
            {/* warning modal */}
            <WarningModal
                sectionName="Product Portfolio"
                missingFields={missingFields}
                open={showWarningModal}
                handleClose={handleClose}
                handleProceed={handleProceed}
            />
            <CommonModal
                title={`${addEditData?.mode === 'add' ? 'Add' : 'Edit'} Representative`}
                open={addEditData?.modalOpen}
                onClose={closeRepModal}
                submitDisable={isLoading}
                onSubmit={submitRepModal}
                noTitleLine
                body={
                    <>
                        <div className="p-3">
                            <Grid container spacing={3}>
                                <Grid item xs={12} className="side-by-side">
                                    {/* <Grid item xs={12} md={4}>
                                        <CustomInputLabel label="Name" labelProps={{ required: true }} />
                                    </Grid> */}
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            // sx={{ width: '80%' }}
                                            className="fullWidth-responsive"
                                            fullWidth
                                            name="name"
                                            value={addEditData?.data?.name}
                                            onChange={handleRepChange}
                                            inputProps={{ maxLength: 100 }}
                                            label="Representative name*"
                                            placeholder="Enter the name of the representatve"
                                            sx={{
                                                width: '70%',
                                                textAlign: 'left',
                                                backgroundColor: 'white',
                                                '& .MuiInputLabel-asterisk': {
                                                    display: 'none', // inherit the color from the parent
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: 'black', // Default black outline
                                                    },
                                                },
                                            }}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className="side-by-side">
                                    {/* <Grid item xs={12} md={4}>
                                        <CustomInputLabel label="Designation" />
                                    </Grid> */}
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            // sx={{ width: '80%' }}
                                            className="fullWidth-responsive"
                                            fullWidth
                                            name="designation"
                                            label="Designation"
                                            placeholder="Enter the designation"
                                            sx={{
                                                width: '70%',
                                                textAlign: 'left',
                                                backgroundColor: 'white',
                                                '& .MuiInputLabel-asterisk': {
                                                    display: 'none', // inherit the color from the parent
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: 'black', // Default black outline
                                                    },
                                                },
                                            }}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={addEditData?.data?.designation}
                                            onChange={handleRepChange}
                                            inputProps={{ maxLength: 100 }}
                                            // required
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} style={{ gap: '3rem' }} className="side-by-side">
                                    {/* <Grid item xs={12} md={4}>
                                        <CustomInputLabel label="Email" labelProps={{ required: true }} />
                                    </Grid> */}
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            // sx={{ width: '80%' }}
                                            label="Phone*"
                                            placeholder="Enter the phone number"
                                            sx={{
                                                width: '100%',
                                                textAlign: 'left',
                                                backgroundColor: 'white',
                                                '& .MuiInputLabel-asterisk': {
                                                    display: 'none', // inherit the color from the parent
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: 'black', // Default black outline
                                                    },
                                                },
                                            }}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            className="fullWidth-responsive"
                                            fullWidth
                                            name="phone"
                                            value={addEditData?.data?.phone}
                                            onChange={handleRepChange}
                                            inputProps={{ maxLength: 10 }}
                                            // required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            // sx={{ width: '80%' }}
                                            className="fullWidth-responsive"
                                            fullWidth
                                            name="email"
                                            label="Email*"
                                            placeholder="Enter the email"
                                            sx={{
                                                width: '100%',
                                                textAlign: 'left',
                                                backgroundColor: 'white',
                                                '& .MuiInputLabel-asterisk': {
                                                    display: 'none', // inherit the color from the parent
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: 'black', // Default black outline
                                                    },
                                                },
                                            }}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={addEditData?.data?.email}
                                            disabled={addEditData?.mode !== 'add'}
                                            onChange={handleRepChange}
                                            inputProps={{ maxLength: 100 }}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className="side-by-side">
                                    {/* <Grid item xs={12} md={4}>
                                        <CustomInputLabel label="Gender" />
                                    </Grid> */}
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            className="fullWidth-responsive"
                                            fullWidth
                                            name="gender"
                                            id="outlined-select-currency"
                                            label="Gender"
                                            placeholder="Select from drop-down"
                                            sx={{
                                                width: '70%',
                                                textAlign: 'left',
                                                backgroundColor: 'white',
                                                '& .MuiInputLabel-asterisk': {
                                                    display: 'none', // inherit the color from the parent
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: 'black', // Default black outline
                                                    },
                                                },
                                            }}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            select
                                            // label="Search Time"
                                            value={addEditData?.data?.gender}
                                            onChange={handleRepChange}
                                            required
                                        >
                                            {['Male', 'Female', 'Others'].map((i) => (
                                                <MenuItem value={i} key={i}>
                                                    {i}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className="">
                                    {/* <Grid item xs={12} md={4}>
                                        <CustomInputLabel label="Photo" />
                                    </Grid> */}
                                    <div className="custom-heading">Image*</div>
                                    <Grid item xs={12} md={12} style={{ alignItems: 'center', flexWrap: 'wrap' }}>
                                        <ImageUploadNew
                                            title="Upload Photo"
                                            onClose={(data) => getPhotoHere(data)}
                                            previousImgSrc={addEditData?.data?.photo?.value}
                                            // propAspect={1}
                                            propHeight={400}
                                            propWidth={400}
                                            propSize={5}
                                            imageHeight="13rem"
                                            imageWidth="20rem"
                                        />

                                        <div className="side-by-side gap-4 align-items-start my-3 my-md-2">
                                            {!!addEditData?.data?.photo?.value && (
                                                <img
                                                    src={addEditData?.data?.photo?.value}
                                                    alt="Representatve"
                                                    height={150}
                                                    // onError={handleImgErrorLogo}
                                                    style={{ marginRight: '2rem' }}
                                                />
                                            )}
                                            {!!addEditData?.data?.photo?.value && (
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    color="error"
                                                    sx={{ ml: 2 }}
                                                    onClick={() => clearImg('photo')}
                                                >
                                                    Clear Image
                                                </Button>
                                            )}
                                        </div>
                                    </Grid>
                                    <div className="note-text-new">
                                        Note : Image File Size (Max 2MB), Image dimension (400x400),
                                        <br /> Image Format (JPEG/PNG)
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </>
                }
            />
            <Container maxWidth="xl" sx={{ px: smUp ? 4 : 2, pb: 2 }} className="contact-page h-100 w-100">
                {/* <Typography variant="h3" sx={{ mb: 5 }}>
                Showcase Contact
                </Typography> */}
                {isLoading ? (
                    <Loader />
                ) : (
                    <Box sx={{ maxWidth: '100%' }}>
                        <Typography variant="h6" className="mb-3">
                            Note: {SHOWCASE_HEADER_NOTE.contact}
                        </Typography>
                        <form action="" onSubmit={submitForm}>
                            <Grid container spacing={3}>
                                {/* <Grid item xs={12}>
                                    <Typography variant="h6">Meeting Scheduler</Typography>
                                    <Box sx={{ ...commonBoxStyles, borderRadius: 1 }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} className="side-by-side">
                                        <Grid item xs={12} md={4} lg={3}>
                                            <InputLabel>Calendly Link</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} md={8} lg={9}>
                                            <TextField
                                            // sx={{ width: '80%' }}
                                            className="fullWidth-responsive"
                                            fullWidth
                                            name="calendlyLink"
                                            value={inputData?.meetingS?.calendlyLink}
                                            onChange={handleOnChange('meetingS')}
                                            inputProps={{ maxLength: 100 }}
                                            // required
                                            />
                                        </Grid>
                                        </Grid>
                                    </Grid>
                                    </Box>
                                </Grid> */}
                                {/* Company Representatives */}
                                <Grid item xs={12}>
                                    <div className="d-none">
                                        <Typography variant="h6">
                                            Company Representatives <span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <div className="ms-auto">
                                            <Tooltip
                                                title={'Click on the fields to copy them'}
                                                placement="left-start"
                                                className="cursor-pointer my-auto fs-2"
                                            >
                                                <Iconify icon="material-symbols:info-outline-rounded" width="10" />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <Box sx={{ ...commonBoxStyles, borderRadius: 1 }}>
                                        <Grid item xs={12} className="mt-3">
                                            <div className="row">
                                                <div className="col-6 col-lg-3 mb-3 ">
                                                    <CommonAddButton
                                                        onClickParent={() => openRepModal('add')}
                                                        customClass={'min-h-auto'}
                                                        buttonClass={'px-2'}
                                                        text={
                                                            <>
                                                                <div>Add Representative</div>
                                                            </>
                                                        }
                                                    />
                                                </div>
                                                {inputData?.showcaseR?.length > 0 &&
                                                    inputData?.showcaseR?.map((i, index) => (
                                                        <div className="col-6 col-lg-3  mb-3" key={i?._id}>
                                                            <div className=" border border_radius_8px p-3 position-relative pt-5 pt-md-3">
                                                                {/* <span
                                                                    className=" position-absolute d-flex scale-like"
                                                                    style={{ right: '5px', top: '5px' }}
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                    }}
                                                                    >
                                                                    <Checkbox
                                                                        onClick={addEditSelectContact}
                                                                        name={'contact'}
                                                                        value={i?._id}
                                                                        checked={formData?.contact?.repSelected?.includes(i?._id) || false}
                                                                    />
                                                                    </span> */}

                                                                <IconButton
                                                                    size="large"
                                                                    color="inherit"
                                                                    className="position-absolute"
                                                                    style={{
                                                                        right: '5px',
                                                                        top: '5px',
                                                                        // marginLeft: 'auto',
                                                                        // marginRight: 3,
                                                                    }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleOpenMenu(e);
                                                                    }}
                                                                    // data-name="showcase"
                                                                    data-index={index}
                                                                    data-id={i?._id}
                                                                >
                                                                    <Iconify icon={'eva:more-vertical-fill'} />
                                                                </IconButton>
                                                                <div className="d-flex justify-content-center mb-3">
                                                                    <img
                                                                        src={i?.photo?.value || i?.photo?.testImg || ''}
                                                                        alt=""
                                                                        className="border_radius_100 round-border h-100px overflow-hidden aspect-ratio-1 background-white fit-contain"
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <Tooltip title={i?.name} placement="bottom">
                                                                        <div
                                                                            className="ellipse-text cursor-pointer text-center fw-bold fs-6"
                                                                            role="button"
                                                                            tabIndex={0}
                                                                            onKeyDown={() => copyText(i?.name, 'Name Copied')}
                                                                            onClick={() => copyText(i?.name, 'Name Copied')}
                                                                        >
                                                                            {i?.name || ''}
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                                <div>
                                                                    <Tooltip title={i?.designation} placement="bottom">
                                                                        <div
                                                                            style={{ fontSize: '0.8rem' }}
                                                                            className="ellipse-text cursor-pointer text-center fw-light font-body-14 "
                                                                            role="button"
                                                                            tabIndex={0}
                                                                            onKeyDown={() => copyText(i?.designation, 'Designation Copied')}
                                                                            onClick={() => copyText(i?.designation, 'Designation Copied')}
                                                                        >
                                                                            {i?.designation || ''}
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                                <div>
                                                                    <Tooltip title={i?.email} placement="bottom">
                                                                        <div
                                                                            style={{ fontSize: '0.8rem' }}
                                                                            className="ellipse-text cursor-pointer text-center fw-bold font-body-14 "
                                                                            role="button"
                                                                            tabIndex={0}
                                                                            onKeyDown={() => copyText(i?.email, 'Email ID Copied')}
                                                                            onClick={() => copyText(i?.email, 'Email ID Copied')}
                                                                        >
                                                                            {i?.email || ''}
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                                <div>
                                                                    <Tooltip title={i?.phone} placement="bottom">
                                                                        <div
                                                                            style={{ fontSize: '0.8rem' }}
                                                                            className="ellipse-text cursor-pointer text-center fw-bold font-body-14 "
                                                                            role="button"
                                                                            tabIndex={0}
                                                                            onKeyDown={() => copyText(i?.email, 'Email ID Copied')}
                                                                            onClick={() => copyText(i?.email, 'Email ID Copied')}
                                                                        >
                                                                            {i?.phone || ''}
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </Grid>
                                    </Box>
                                </Grid>
                                {/* Company Contact Details */}
                                <Grid item xs={12}>
                                    <hr className="contact-section-hr" style={{ background: 'black', height: '1.4px' }} />
                                    <Typography variant="h6">Company Info</Typography>
                                    <Box sx={{ ...commonBoxStyles, borderRadius: 1 }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} className="side-by-side">
                                                {/* <Grid item xs={12} md={4} lg={3}>
                                                    <CustomInputLabel label="Website" labelProps={{ required: true }} />
                                                </Grid> */}
                                                <Grid item xs={12} md={8} lg={5.85}>
                                                    <TextField
                                                        label="Website*"
                                                        placeholder="Enter the webiste URL"
                                                        sx={{
                                                            width: '100%',
                                                            textAlign: 'left',
                                                            backgroundColor: 'white',
                                                            '& .MuiInputLabel-asterisk': {
                                                                display: 'none', // inherit the color from the parent
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: 'black', // Default black outline
                                                                },
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        className="fullWidth-responsive"
                                                        // fullWidth
                                                        name="website"
                                                        value={inputData?.company?.website}
                                                        onChange={handleOnChange('company')}
                                                        inputProps={{ maxLength: 100 }}
                                                        required
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item container spacing={3} xs={12} className="">
                                                {/* <Grid item xs={12} md={4} lg={3}>
                                                    <CustomInputLabel label="Email" labelProps={{ required: true }} />
                                                </Grid> */}
                                                <Grid item xs={12} md={8.1} lg={6}>
                                                    <TextField
                                                        label="Phone*"
                                                        placeholder="Enter the phone number"
                                                        sx={{
                                                            width: '100%',
                                                            textAlign: 'left',
                                                            backgroundColor: 'white',
                                                            '& .MuiInputLabel-asterisk': {
                                                                display: 'none', // inherit the color from the parent
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: 'black', // Default black outline
                                                                },
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        className="fullWidth-responsive"
                                                        // fullWidth
                                                        name="phone"
                                                        value={inputData?.company?.phone}
                                                        onChange={handleOnChange('company')}
                                                        inputProps={{ maxLength: 100 }}
                                                        required
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={8.1} lg={6}>
                                                    <TextField
                                                        label="Email*"
                                                        placeholder="Enter the email"
                                                        sx={{
                                                            width: '100%',
                                                            textAlign: 'left',
                                                            backgroundColor: 'white',
                                                            '& .MuiInputLabel-asterisk': {
                                                                display: 'none', // inherit the color from the parent
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: 'black', // Default black outline
                                                                },
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        className="fullWidth-responsive"
                                                        // fullWidth
                                                        name="email"
                                                        value={inputData?.company?.email}
                                                        onChange={handleOnChange('company')}
                                                        inputProps={{ maxLength: 100 }}
                                                        required
                                                    />
                                                </Grid>
                                            </Grid>
                                            {/* <Grid item xs={12} className="side-by-side">
                                                <Grid item xs={12} md={4} lg={3}>
                                                    <CustomInputLabel label="Phone" labelProps={{ required: true }} />
                                                </Grid>
                                                <Grid item xs={12} md={8} lg={9}>
                                                    <TextField
                                                        sx={{ width: '50%' }}
                                                        className="fullWidth-responsive"
                                                        // fullWidth
                                                        name="phone"
                                                        value={inputData?.company?.phone}
                                                        onChange={handleOnChange('company')}
                                                        inputProps={{ maxLength: 100 }}
                                                        required
                                                    />
                                                </Grid>
                                            </Grid> */}
                                            <Grid item xs={12} className="side-by-side">
                                                {/* <Grid item xs={12} md={4} lg={3}>
                                                    <CustomInputLabel label="Address" labelProps={{ required: true }} />
                                                </Grid> */}
                                                <Grid item xs={12} md={8} lg={12}>
                                                    <TextField
                                                        // sx={{ width: '80%' }}
                                                        className="fullWidth-responsive"
                                                        fullWidth
                                                        name="address"
                                                        label="Address*"
                                                        placeholder="Enter the address"
                                                        sx={{
                                                            width: '100%',
                                                            textAlign: 'left',
                                                            backgroundColor: 'white',
                                                            '& .MuiInputLabel-asterisk': {
                                                                display: 'none', // inherit the color from the parent
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: 'black', // Default black outline
                                                                },
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        multiline
                                                        rows={3}
                                                        maxRows={6}
                                                        value={inputData?.company?.address}
                                                        onChange={handleOnChange('company')}
                                                        inputProps={{ maxLength: 200 }}
                                                        required
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                {/* social media links */}
                                <Grid item xs={12}>
                                    <hr className="contact-section-hr" style={{ background: 'black', height: '1.4px' }} />
                                    <Typography variant="h6">Social Media</Typography>
                                    <Box sx={{ ...commonBoxStyles, borderRadius: 1 }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} className="side-by-side">
                                                {/* <Grid item xs={12} md={4}>
                                                    <InputLabel>Facebook</InputLabel>
                                                </Grid> */}
                                                <Grid item xs={12} md={12}>
                                                    <TextField
                                                        // sx={{ width: '80%' }}
                                                        className="fullWidth-responsive"
                                                        fullWidth
                                                        name="facebook"
                                                        label="Facebook"
                                                        placeholder="Enter the Facebook"
                                                        sx={{
                                                            textAlign: 'left',
                                                            backgroundColor: 'white',
                                                            '& .MuiInputLabel-asterisk': {
                                                                display: 'none', // inherit the color from the parent
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: 'black', // Default black outline
                                                                },
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={inputData?.socialM?.facebook}
                                                        onChange={handleOnChange('socialM')}
                                                        inputProps={{ maxLength: 100 }}
                                                        // required
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={6} className="side-by-side">
                                                {/* <Grid item xs={12} md={4}>
                                                    <InputLabel>Twitter</InputLabel>
                                                </Grid> */}
                                                <Grid item xs={12} md={12}>
                                                    <TextField
                                                        // sx={{ width: '80%' }}
                                                        className="fullWidth-responsive"
                                                        fullWidth
                                                        name="twitter"
                                                        label="Twitter"
                                                        placeholder="Enter the Twitter"
                                                        sx={{
                                                            textAlign: 'left',
                                                            backgroundColor: 'white',
                                                            '& .MuiInputLabel-asterisk': {
                                                                display: 'none', // inherit the color from the parent
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: 'black', // Default black outline
                                                                },
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={inputData?.socialM?.twitter}
                                                        onChange={handleOnChange('socialM')}
                                                        inputProps={{ maxLength: 100 }}
                                                        // required
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={6} className="side-by-side">
                                                {/* <Grid item xs={12} md={4}>
                                                    <InputLabel>Instagram</InputLabel>
                                                </Grid> */}
                                                <Grid item xs={12} md={12}>
                                                    <TextField
                                                        // sx={{ width: '80%' }}
                                                        className="fullWidth-responsive"
                                                        fullWidth
                                                        name="instagram"
                                                        label="Instagram"
                                                        placeholder="Enter the Instagram"
                                                        sx={{
                                                            textAlign: 'left',
                                                            backgroundColor: 'white',
                                                            '& .MuiInputLabel-asterisk': {
                                                                display: 'none', // inherit the color from the parent
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: 'black', // Default black outline
                                                                },
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={inputData?.socialM?.instagram}
                                                        onChange={handleOnChange('socialM')}
                                                        inputProps={{ maxLength: 100 }}
                                                        // required
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={6} className="side-by-side">
                                                {/* <Grid item xs={12} md={4}>
                                                    <InputLabel>Linkedin</InputLabel>
                                                </Grid> */}
                                                <Grid item xs={12} md={12}>
                                                    <TextField
                                                        // sx={{ width: '80%' }}
                                                        className="fullWidth-responsive"
                                                        fullWidth
                                                        name="linkedin"
                                                        label="Linkedin"
                                                        placeholder="Enter the Linkedin"
                                                        sx={{
                                                            textAlign: 'left',
                                                            backgroundColor: 'white',
                                                            '& .MuiInputLabel-asterisk': {
                                                                display: 'none', // inherit the color from the parent
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: 'black', // Default black outline
                                                                },
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={inputData?.socialM?.linkedin}
                                                        onChange={handleOnChange('socialM')}
                                                        inputProps={{ maxLength: 100 }}
                                                        // required
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={6} className="side-by-side">
                                                {/* <Grid item xs={12} md={4}>
                                                    <InputLabel>Pinterest</InputLabel>
                                                </Grid> */}
                                                <Grid item xs={12} md={12}>
                                                    <TextField
                                                        // sx={{ width: '80%' }}
                                                        className="fullWidth-responsive"
                                                        fullWidth
                                                        name="pinterest"
                                                        label="Pinterest"
                                                        placeholder="Enter the Pinterest"
                                                        sx={{
                                                            textAlign: 'left',
                                                            backgroundColor: 'white',
                                                            '& .MuiInputLabel-asterisk': {
                                                                display: 'none', // inherit the color from the parent
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: 'black', // Default black outline
                                                                },
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={inputData?.socialM?.pinterest}
                                                        onChange={handleOnChange('socialM')}
                                                        inputProps={{ maxLength: 100 }}
                                                        // required
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={6} className="side-by-side">
                                                {/* <Grid item xs={12} md={4}>
                                                    <InputLabel>Youtube</InputLabel>
                                                </Grid> */}
                                                <Grid item xs={12} md={12}>
                                                    <TextField
                                                        // sx={{ width: '80%' }}
                                                        className="fullWidth-responsive"
                                                        fullWidth
                                                        name="youtube"
                                                        label="Youtube"
                                                        placeholder="Enter the Youtube"
                                                        sx={{
                                                            textAlign: 'left',
                                                            backgroundColor: 'white',
                                                            '& .MuiInputLabel-asterisk': {
                                                                display: 'none', // inherit the color from the parent
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: 'black', // Default black outline
                                                                },
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={inputData?.socialM?.youtube}
                                                        onChange={handleOnChange('socialM')}
                                                        inputProps={{ maxLength: 100 }}
                                                        // required
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Box
                                    style={{ zIndex: 15 }}
                                    sx={{
                                        position: 'fixed',
                                        bottom: 0,
                                        right: 0,
                                        left: isLg ? 250 : 0,
                                        bgcolor: 'white',
                                        p: 1,
                                        display: 'flex',
                                        boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
                                        gap: 2,
                                    }}
                                >
                                    {/* <div className="me-2">
                                    <Button type="save" name="next" variant="contained">
                                        Save & Next
                                    </Button>
                                    </div> */}
                                    <div className="d-flex justify-content-end w-100">
                                        <Button sx={{ mr: 2, color: 'black' }} type="button" disabled={isLoading} onClick={resetFormSwal}>
                                            <Iconify icon={'carbon-reset'} style={{ height: '0.9rem', width: '0.9rem', marginRight: '0.3rem' }} />
                                            Reset
                                        </Button>
                                        <Button
                                            type="save"
                                            sx={{ background: confirmGreen, boxShadow: 'none', ':hover': { background: confirmGreen } }}
                                            name="share"
                                            variant="contained"
                                        >
                                            Save & Next
                                        </Button>
                                    </div>
                                </Box>
                            </Grid>
                        </form>
                    </Box>
                )}
            </Container>
            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 160,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem
                    sx={{ color: 'success.main' }}
                    onClick={() =>
                        openRepModal('edit', {
                            _id: open?.dataset?.id,
                            index: open?.dataset?.index,
                        })
                    }
                >
                    <Iconify icon="clarity:edit-line" sx={{ mr: smUp ? 2 : 1 }} />
                    Edit
                </MenuItem>

                <MenuItem
                    sx={{ color: 'info.main' }}
                    onClick={() =>
                        resendInvite({
                            id: open?.dataset?.id,
                        })
                    }
                >
                    <Iconify icon="material-symbols:mail-outline" sx={{ mr: smUp ? 2 : 1 }} />
                    Resend Mail
                </MenuItem>

                <MenuItem sx={{ color: 'error.main' }} onClick={() => deleteSwalRep(open?.dataset?.id)}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: smUp ? 2 : 1 }} />
                    Delete
                </MenuItem>
            </Popover>
        </>
    );
}

export default UserContact;
