/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { AppBar, Box, IconButton, Stack, Tab, Tabs, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
// components
// import CommonCircularProgress from '../../../../components/circular-progress/CommonCircularProgress';
import { Progresscontext } from '../../../../components/circular-progress/ProgressContext';
import Iconify from '../../../../components/iconify/Iconify';
import useResponsive from '../../../../hooks/useResponsive';
import { wocOrange } from '../../../../utils/constants';
// import AccountPopover from './AccountPopover';


const NAV_WIDTH = 250;
const HEADER_MOBILE = 100;
const HEADER_DESKTOP = 100;

const StyledRoot = styled(AppBar)(({ theme }) => ({
    // ...bgBlur({ color: theme.palette.background.default }),
    // backgroundColor: '#f2f7ff',
    backgroundColor: 'white',
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    },
    boxShadow: '5px 2px 6px 0px #0022443D',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_MOBILE,
    flexWrap: 'wrap',
    [theme.breakpoints.up('lg')]: {
        minHeight: HEADER_DESKTOP,
        // padding: theme.spacing(0, 5),
    },
    flexDirection: 'column',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'baseline',
}));

// ----------------------------------------------------------------------

Header.propTypes = {
    onOpenNav: PropTypes.func,
};
// const doneIcon = 'mdi:tick-circle-outline';
// const emptyIcon = 'material-symbols:error-outline';

const TAB_CONFIG = {
    profile: 'Company Details',
    resource: 'Gallery & Catalouge',
    product: 'Product Portfolio',
    contact: 'Contact Details',
    template: 'Publish Profile',
};

const TAB_TOUR_CONFIG = {
    profile: 'tour__profile',
    resource: 'tour__resource',
    product: 'tour__product',
    contact: 'tour__contact',
    template: 'tour__template',
};

export default function Header({ onOpenNav }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('profile');
    const smUp = useResponsive('up', 'sm');

    const { status } = useContext(Progresscontext);

    // * re render the tab when location changes
    useEffect(() => {
        const path = location.pathname.split('/').pop();
        if (Object.keys(TAB_CONFIG).includes(path)) {
            setActiveTab(path);
        }
    }, [location]);

    // * navigate to different page
    const navigateTo = (path) => {
        navigate(`/user/showcase/${path}`);
    };

    return (
        <StyledRoot>
            <StyledToolbar>
                {/* tob tab navbar */}
                <Stack direction={"row"} alignItems={"center"}>
                    <IconButton
                        onClick={onOpenNav}
                        sx={{
                            mr: 1,
                            color: 'text.primary',
                            display: { lg: 'none' },
                        }}
                    >
                        <Iconify icon="eva:menu-2-fill" />
                    </IconButton>
                    <Typography variant="h5" gutterBottom className="black-login my-0 ms-0" sx={{ fontSize: smUp ? '1.25rem' : '.9rem' }}>
                        Seller Profile
                    </Typography>
                </Stack>
                <Box sx={{ overflowX: 'auto', minWidth: '100%', maxWidth: '100%' }}>
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        value={Object.keys(TAB_CONFIG).indexOf(activeTab)}
                        TabIndicatorProps={{ style: { display: 'none' } }}
                        sx={{
                            height: '35px',
                            minHeight: '35px',
                            '& .MuiTabScrollButton-root': {
                                color: '#17683d', // Set the color for the scroll buttons
                            },
                            '@media (max-width: 600px)': {
                                "& .MuiTabs-scrollButtons": {
                                    display: "flex !important",
                                }
                            },
                        }}
                    >
                        {/* tab nav bar for navigate to different page */}
                        {Object.keys(TAB_CONFIG).map((key, index) => (
                            <div key={key} className="d-flex" style={{ minWidth: 'fit-content' }}>
                                <Tab
                                    data-tour={TAB_TOUR_CONFIG[key]}
                                    onClick={() => {
                                        navigateTo(key);
                                        setActiveTab(key);
                                    }}
                                    label={
                                        <Box className="d-flex align-items-center" sx={{ gap: '10px' }}>
                                            <span
                                                style={{
                                                    borderRadius: '50%',
                                                    backgroundColor: activeTab === key ? wocOrange : '#c6c7c7',
                                                    width: '15px',
                                                    height: '15px',
                                                    color: activeTab === key ? 'white' : 'black',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    fontSize: '12px',
                                                    padding: '0.5rem',
                                                }}
                                            >
                                                {index + 1}
                                            </span>
                                            {TAB_CONFIG[key]}
                                            {(key === "template" && status?.showcase) || status?.[key] ? (
                                                <Iconify icon="mdi:check-circle" color={activeTab ===  key ? '#93c641' : '#93c641'} />
                                            ) : (
                                                <Iconify icon="mdi:alert-circle" color={'#e4ac02'} />
                                            )}
                                        </Box>
                                    }
                                    sx={{
                                        fontWeight: activeTab === key ? 'bold' : 'normal',
                                        color: activeTab === key ? wocOrange : 'black',
                                        margin: '0',
                                        border: `2px solid`,
                                        borderColor: activeTab === key ? wocOrange : '#c6c7c7',
                                        backgroundColor: activeTab === key ? 'white' : 'white',
                                        borderRadius: '12px',
                                        display: 'flex',
                                        padding: '0px 10px 0px 10px',
                                        height: '35px !important',
                                        minHeight: '35px !important',
                                        minWidth: 'fit-content',
                                        opacity: 1,
                                        fontSize: '12px',
                                    }}
                                />
                                {index !== Object.keys(TAB_CONFIG).length - 1 && (
                                    <Box
                                        sx={{
                                            height: '1px',
                                            width: '10px',
                                            backgroundColor: '#212b36',
                                            alignSelf: 'center',
                                        }}
                                    />
                                )}
                            </div>
                        ))}
                    </Tabs>
                </Box>
            </StyledToolbar>
            {/* <StyledToolbar>
                <IconButton
                    onClick={onOpenNav}
                    sx={{
                        mr: 1,
                        color: 'text.primary',
                        display: { lg: 'none' },
                    }}
                >
                    <Iconify icon="eva:menu-2-fill" />
                </IconButton>

                <Searchbar />
                <Stack direction="column">
                    <Typography variant="h4" gutterBottom className="black-login my-auto ms-0" sx={{ fontSize: smUp ? '1.25rem' : '.9rem' }}>
                        {TAB_CONFIG[activeTab]}
                    </Typography>
                    <Typography variant="body2" gutterBottom className="black-login my-auto ms-0">
                        {TAB_SUB_TEXT[activeTab]}
                    </Typography>
                </Stack>
                <Box sx={{ flexGrow: 1 }} />
                <CommonCircularProgress />

                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{
                        xs: 0.5,
                        sm: 1,
                    }}
                >
                    <AccountPopover />
                </Stack>
            </StyledToolbar> */}
        </StyledRoot>
    );
}
