import { useState } from 'react';
// @mui
import { Box, Button, ListItemText } from '@mui/material';
// components
import Iconify from '../iconify/Iconify';
import { StyledNavItem, StyledNavItemIcon } from './styles';
// utils
import { wocOrange, wocOrangeHover } from '../../utils/constants';
import CommonModal from '../modal/CommonModal';
import { openInNewTab } from '../../utils/helper';

const helpVideoURL = 'https://www.loom.com/embed/97a83bedbedb41b9b03b250162086187?sid=315fce1e-cfa5-46eb-8148-738489802488';

export default function HelpNavButton() {
    const [helpCenterModal, setHelpCenterModal] = useState({ open: false });

    const openHelpCenter = () => {
        setHelpCenterModal((prev) => ({ ...prev, open: true }));
    };

    const closeHelpCenter = () => {
        setHelpCenterModal((prev) => ({ ...prev, open: false }));
    };

    return (
        <>
            {/* help center modal */}
            <CommonModal
                open={helpCenterModal?.open}
                onClose={closeHelpCenter}
                title={'Help Video'}
                customFooter={
                    <div className="d-flex justify-content-center w-100">
                        <Button onClick={()=>window.open(helpVideoURL, '_blank', 'noopener,noreferrer')} variant="contained">
                            <Iconify icon="majesticons:open" className="me-3" />
                            Open Video here
                        </Button>
                    </div>
                }
                body={
                    <div className="d-flex justify-content-center align-items-center" style={{ position: 'relative', paddingBottom: '49.926793557833086%', height: 0 }}>
                        <iframe
                            title="Help Video"
                            src={helpVideoURL}
                            frameBorder="0"
                            allowFullScreen
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                        />
                    </div>
                }
            />
            {/* help center btn */}
            <Box onClick={openHelpCenter}>
                <StyledNavItem
                    sx={{
                        color: '#161521',
                        margin: '0 0.5rem',
                        '&.active': {
                            color: 'black',
                            bgcolor: wocOrange,
                            fontWeight: 'fontWeightBold',
                            '&:hover': {
                                color: 'white',
                            },
                        },
                        '&:hover': {
                            color: wocOrangeHover,
                        },
                    }}
                >
                    <StyledNavItemIcon>
                        <Iconify icon="mdi:video-outline" width={25} />
                    </StyledNavItemIcon>
                    <ListItemText disableTypography primary={'Help Video'} />
                </StyledNavItem>
            </Box>
        </>
    );
}
