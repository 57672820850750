import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Grid,
  TextField,
  Box,
  InputLabel,
  Button,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  Select,
  OutlinedInput,
  Tooltip,
} from '@mui/material';
import { toast } from 'react-toastify';

import apiMiddleWare from '../../../utils/useApiMiddleWare';
import customToast from '../../../utils/customToast';
import { defaultError, S3BucketBaseUrl, userRouteName, wocOrange } from '../../../utils/constants';
import Loader from '../../../components/loader';
import Iconify from '../../../components/iconify/Iconify';
import { Progresscontext } from '../../../components/circular-progress/ProgressContext';
import useResponsive from '../../../hooks/useResponsive';

export default function UserVerify() {
  const smUp = useResponsive('up', 'sm');

  const doneIcon = 'mdi:tick-circle-outline';

  const { setCallGuideStatus } = useContext(Progresscontext);

  const [isLoading, setIsLoading] = useState(false);
  const [callApi, setCallApi] = useState(false);

  const defaultData = {
    email: {
      value: '',
      status: false,
      otpSent: false,
      otp: '',
    },
    phone: {
      value: '',
      status: false,
      otpSent: false,
      otp: '',
    },
  };
  const [userData, setUserData] = useState(defaultData);

  useEffect(() => {
    getInitialData();
  }, [callApi]);

  const getInitialData = async () => {
    try {
      setIsLoading(true);
      const data = await apiMiddleWare({ type: 'get', url: `/users/profile-verify` });
      if (!data) {
        setIsLoading(false);
        return false;
      }
      if (data?.data?.ok) {
        const dataHere = data?.data?.packet;
        setUserData((prev) => ({
          ...prev,
          email: {
            ...prev.email,
            value: dataHere?.email,
            status: dataHere?.emailVerified || false,
          },
          phone: {
            ...prev.phone,
            value: dataHere?.phone,
            status: dataHere?.phoneVerified || false,
          },
        }));

        setIsLoading(false);
        return true;
      }
      customToast({
        msg: data?.response?.data?.errors[0]?.message || data?.data?.errors[0]?.msg || defaultError,
        type: 'error',
      });
      setIsLoading(false);
      return false;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);
      return false;
    }
  };

  const sendOtp =
    (type = 'email') =>
    async () => {
      try {
        const id = toast.loading('Please wait...');

        setIsLoading(true);
        const sendData = { type };
        const data = await apiMiddleWare({ type: 'post', url: `/users/send-profile-verify`, data: sendData });
        if (!data) {
          setIsLoading(false);
          customToast({ updateId: id, type: 'update', msg: defaultError, updateType: 'error' });
          return false;
        }
        if (data?.data?.ok) {
          setIsLoading(false);

          let typeName;
          let value;
          if (type === 'email') {
            typeName = 'Email';
            value = userData?.email?.value;
            setUserData((prev) => ({ ...prev, email: { ...prev.email, otpSent: true } }));
          } else if (type === 'phone') {
            typeName = 'Phone';
            value = userData?.phone?.value;
            setUserData((prev) => ({ ...prev, phone: { ...prev.phone, otpSent: true } }));
          }

          customToast({
            updateId: id,
            type: 'update',
            msg: `OTP Successfully Sent to ${typeName} ${value}`,
            updateType: 'success',
          });
          return true;
        }
        customToast({
          updateId: id,
          type: 'update',
          msg: data?.data?.errors[0]?.message || data?.data?.errors[0]?.msg || defaultError,
          updateType: 'error',
        });
        setIsLoading(false);
        return false;
      } catch (err) {
        customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
        setIsLoading(false);
        return false;
      }
    };
  const submitOTP =
    (type = 'email') =>
    async () => {
      try {
        const id = toast.loading('Please wait...');

        setIsLoading(true);
        const sendData = { type, otp: userData?.[type]?.otp || '' };
        const data = await apiMiddleWare({ type: 'post', url: `/users/profile-verify`, data: sendData });
        if (!data) {
          setIsLoading(false);
          customToast({ updateId: id, type: 'update', msg: defaultError, updateType: 'error' });
          return false;
        }
        if (data?.data?.ok) {
          let typeName;
          if (type === 'email') typeName = 'Email';
          else if (type === 'phone') typeName = 'phone';
          customToast({
            updateId: id,
            type: 'update',
            msg: `${typeName} Successfully Verified`,
            updateType: 'success',
          });
          setIsLoading(false);
          setUserData((prev) => ({
            ...prev,
            [type]: {
              ...prev?.[type],
              status: true,
              otpSent: false,
              otp: '',
            },
          }));
          setCallGuideStatus((prev) => !prev);
          return true;
        }

        customToast({
          updateId: id,
          type: 'update',
          msg: data?.data?.errors[0]?.message || data?.data?.errors[0]?.msg || defaultError,
          updateType: 'error',
        });
        setIsLoading(false);
        return false;
      } catch (err) {
        customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
        setIsLoading(false);
        return false;
      }
    };

  const resetForm =
    (type = 'all') =>
    () => {
      if (type === 'all') {
        setUserData(defaultData);
      } else if (type === 'email' || type === 'phone') {
        setUserData((prev) => ({
          ...prev,
          [type]: {
            ...prev?.[type],
            status: false,
            otpSent: false,
            otp: '',
          },
        }));
      }
    };

  const verifiedComp = (
    <Tooltip title={'Verified'} placement="bottom" className="cursor-pointer ms-3 fs-2">
      <Iconify icon={doneIcon} color={'green'} width={30} />
    </Tooltip>
  );

  return (
    <>
      <Container maxWidth="xl" sx={{ px: smUp ? 4 : 2, pb: 2 }} className="h-100 w-100">
        {isLoading ? (
          <Loader />
        ) : (
          <Box>
            <Grid container spacing={2} className="">
              <Grid item xs={12} className="side-by-side ">
                <Grid item xs={12} md={3}>
                  <InputLabel>Email</InputLabel>
                  {userData?.email?.status === true && verifiedComp}
                </Grid>
                <Grid item xs={12} md={9} className="row">
                  <div className="col-md-4">
                    <TextField fullWidth name="email" value={userData?.email?.value} disabled />
                  </div>

                  {userData?.email?.status === false && userData?.email?.otpSent === false && (
                    <div className="col-md-4 h-100 text-start d-flex align-items-center">
                      <Button variant="contained" className="my-auto" onClick={sendOtp('email')}>
                        Send OTP
                      </Button>
                    </div>
                  )}

                  {userData?.email?.otpSent === true && (
                    <>
                      <div className="col-md-4">
                        <TextField
                          fullWidth
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]{4}' }}
                          placeholder="Email OTP"
                          value={userData?.email?.otp}
                          onChange={(e) =>
                            setUserData((prev) => ({ ...prev, email: { ...prev.email, otp: e.target.value } }))
                          }
                        />
                      </div>

                      <div className="col-md-4 h-100 text-start d-flex align-items-center">
                        <Button variant="contained" className="my-auto " onClick={submitOTP('email')}>
                          Submit OTP
                        </Button>
                      </div>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} className="side-by-side ">
                <Grid item xs={12} md={3}>
                  <InputLabel>Phone</InputLabel>
                  {userData?.phone?.status === true && verifiedComp}
                </Grid>
                <Grid item xs={12} md={9} className="row">
                  <div className="col-md-4">
                    <TextField fullWidth name="phone" value={userData?.phone?.value} disabled />
                  </div>
                  {userData?.phone?.status === false && userData?.phone?.otpSent === false && (
                    <div className="col-md-4 h-100 text-start d-flex align-items-center">
                      <Button variant="contained" className="my-auto" onClick={sendOtp('phone')}>
                        Send OTP
                      </Button>
                    </div>
                  )}
                  {userData?.phone?.otpSent === true && (
                    <>
                      <div className="col-md-4">
                        <TextField
                          fullWidth
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]{4}' }}
                          placeholder="Phone OTP"
                          value={userData?.phone?.otp}
                          onChange={(e) =>
                            setUserData((prev) => ({ ...prev, phone: { ...prev.phone, otp: e.target.value } }))
                          }
                        />
                      </div>

                      <div className="col-md-4 h-100 text-start d-flex align-items-center">
                        <Button variant="contained" className="my-auto " onClick={submitOTP('phone')}>
                          Submit OTP
                        </Button>
                      </div>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
    </>
  );
}
