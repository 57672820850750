import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';

import Loader from '../../../components/loader';
import useResponsive from '../../../hooks/useResponsive';
import { defaultError, productList, typeOfBuisness } from '../../../utils/constants';
import customToast from '../../../utils/customToast';
import apiMiddleWare from '../../../utils/useApiMiddleWare';

const defaultData = {
    firstName: { value: '' },
    lastName: { value: '' },
    email: { value: '' },
    countryCode: { value: '91', list: [] },
    phone: { value: '' },

    company: { value: '' },
    designation: { value: '' },
    industry: { value: '' },

    title: { value: '', list: ['Mr.', 'Ms.', 'Mrs.', 'Dr.', 'Prof.'] },
    address1: { value: '' },
    address2: { value: '' },
    country: { value: '', list: [] },
    state: { value: '', list: [] },
    city: { value: '', list: [] },
    postalCode: { value: '' },
    typeOfBusiness: {
        value: '',
        list: typeOfBuisness,
    },
    productInt: { value: [], list: Object.keys(productList) },
    subProductInt: { value: [], list: [] },
};

function UserMyProfile() {
    const smUp = useResponsive('up', 'sm');

    const [isLoading, setIsLoading] = useState(false);
    const [callApi, setCallApi] = useState(false);

    const [userData, setUserData] = useState(defaultData);

    useEffect(() => {
        getInitialData();
        if (!userData?.countryCode?.list || userData?.countryCode?.list?.length === 0) getCountryCode();
        if (!userData?.country?.list || userData?.country?.list?.length === 0) getCountryList();
    }, [callApi]);

    // useEffect(() => {
    //   if (userData?.country?.value) {
    //     getStateList(userData?.country?.value);
    //     // clearCityValue();
    //   }
    // }, [userData?.country?.value]);

    // useEffect(() => {
    //   if (userData?.state?.value && userData?.country?.value) {
    //     getCityList(userData?.country?.value, userData?.state?.value);
    //   }
    // }, [userData?.state?.value, userData?.country?.value]);

    const getInitialData = async () => {
        try {
            setIsLoading(true);
            const data = await apiMiddleWare({ type: 'get', url: '/users/profile' });
            if (!data) {
                setIsLoading(false);
                return false;
            }
            if (data?.data?.ok) {
                const dataHere = data?.data?.packet;

                let subprodList = [];
                dataHere?.metaData?.productInterested?.forEach((i) => {
                    const values = productList[i];
                    subprodList = [...subprodList, ...values];
                });

                setUserData((prev) => ({
                    ...prev,
                    userID: { value: dataHere?._id },
                    firstName: { value: dataHere?.firstName || '' },
                    lastName: { value: dataHere?.lastName || '' },
                    email: { value: dataHere?.email || '' },
                    countryCode: { ...prev.countryCode, value: dataHere?.countryCode || '91' },
                    phone: { value: dataHere?.phone || '' },

                    company: { value: dataHere?.company || '' },
                    designation: { value: dataHere?.designation || '' },
                    industry: { value: '' },

                    title: { ...prev.title, value: dataHere?.title || '' },
                    address1: { value: dataHere?.metaData?.address1 || '' },
                    address2: { value: dataHere?.metaData?.address2 || '' },
                    country: { ...prev.country, value: dataHere?.metaData?.country || '' },
                    state: { ...prev.state, value: dataHere?.metaData?.state || '' },
                    city: { ...prev.city, value: dataHere?.metaData?.city || '' },
                    postalCode: { value: dataHere?.metaData?.postalCode || '' },
                    typeOfBusiness: {
                        ...prev.typeOfBusiness,
                        value: dataHere?.metaData?.typeOfBusiness || '',
                    },
                    productInt: {
                        ...prev.productInt,
                        value: dataHere?.metaData?.productInterested || [],
                        list: Object.keys(productList),
                    },
                    subProductInt: { list: subprodList, value: dataHere?.metaData?.subProductInterested || [] },
                }));

                if (dataHere?.metaData?.country) {
                    getStateList(dataHere?.metaData?.country);
                }
                if (dataHere?.metaData?.country && dataHere?.metaData?.state) {
                    getCityList(dataHere?.metaData?.country, dataHere?.metaData?.state);
                }
                setIsLoading(false);
                return true;
            }
            customToast({
                msg: data?.response?.data?.errors[0]?.message || data?.data?.errors[0]?.msg || defaultError,
                type: 'error',
            });
            setIsLoading(false);
            return false;
        } catch (err) {
            customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
            setIsLoading(false);
            return false;
        }
    };

    async function getCountryCode() {
        setIsLoading(true);
        try {
            const resData = await apiMiddleWare({ url: '/json/country-code-informa', type: 'get' });
            setUserData((prev) => ({
                ...prev,
                countryCode: {
                    ...prev.countryCode,
                    list: resData.data.countryCode,
                },
            }));

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    }

    const clearCityValue = () => {
        setUserData((prev) => ({
            ...prev,
            city: {
                ...prev.city,
                value: '',
            },
        }));
    };
    const clearCityList = () => {
        setUserData((prev) => ({
            ...prev,
            city: {
                ...prev.city,
                list: [],
            },
        }));
    };
    const clearStateValue = () => {
        setUserData((prev) => ({
            ...prev,
            state: {
                ...prev.state,
                value: '',
            },
        }));
    };
    const clearStateList = () => {
        setUserData((prev) => ({
            ...prev,
            state: {
                ...prev.state,
                list: [],
            },
        }));
    };

    async function submitForm(e) {
        try {
            e.preventDefault();
            const sendData = await validateForm();
            const id = toast.loading('Please wait...');

            const res = await apiMiddleWare({ url: '/users/profile', type: 'patch', data: sendData });
            if (res?.data?.ok) {
                // setProfileData((prev) => ({
                //   ...prev,
                //   showcaseId: res?.data?.packet?.showcaseID,
                // }));

                secureLocalStorage.setItem('sessionInfo', {
                    roles: [`${res?.data?.role}`],
                    json: res?.data?.userData,
                    xAuth: res?.data?.token,
                });
                window.dispatchEvent(new Event('storage'));

                customToast({
                    updateId: id,
                    type: 'update',
                    msg: 'Profile Details Successfully Updated',
                    updateType: 'success',
                });
            } else {
                customToast({
                    updateId: id,
                    type: 'update',
                    msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                    updateType: 'error',
                });
                return false;
            }

            setCallApi((prev) => !prev);

            return true;
        } catch (err) {
            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
            return false;
        }
    }

    const validateForm = () =>
        new Promise((resolve, reject) => {
            const sendData = {
                userID: userData?.userID?.value,
                title: userData?.title?.value || '',
                firstName: userData?.firstName?.value || '',
                lastName: userData?.lastName?.value || '',
                countryCode: userData?.countryCode?.value || '',
                // phone: userData?.phone?.value || '',
                // email: userData?.email?.value || '',

                designation: userData?.designation?.value || '',
                company: userData?.company?.value || '',
                industry: '',
                typeOfBusiness: userData?.typeOfBusiness?.value || '',

                address1: userData?.address1?.value || '',
                address2: userData?.address2?.value || '',
                country: userData?.country?.value || '',
                state: userData?.state?.value || '',
                city: userData?.city?.value || '',
                postalCode: userData?.postalCode?.value || '',
                productInterested: userData?.productInt?.value || [],
                subProductInterested: userData?.subProductInt?.value || [],
            };

            if (!sendData?.userID) {
                reject(new Error(`userID is required`));
                return false;
            }
            if (!sendData?.title) {
                reject(new Error(`Title is required`));
                return false;
            }
            if (!sendData?.firstName) {
                reject(new Error(`First Name is required`));
                return false;
            }
            if (!sendData?.lastName) {
                reject(new Error(`Last Name is required`));
                return false;
            }
            if (!sendData?.designation) {
                reject(new Error(`Designation is required`));
                return false;
            }
            if (!sendData?.company) {
                reject(new Error(`Company is required`));
                return false;
            }
            if (!sendData?.typeOfBusiness) {
                reject(new Error(`Type of Business is required`));
                return false;
            }
            if (!sendData?.country) {
                reject(new Error(`Country is required`));
                return false;
            }
            if (!sendData?.productInterested || sendData?.productInterested?.length === 0) {
                reject(new Error(`Select atleast 1 Product Interested`));
                return false;
            }
            if (!sendData?.subProductInterested || sendData?.subProductInterested?.length === 0) {
                reject(new Error(`Select atleast 1 Subproduct Interested`));
                return false;
            }

            resolve(sendData);
            return true;
        });

    const handleChange1 = async (e) => {
        const { value, name } = e.target;
        // eslint-disable-next-line prefer-const
        let changedValue = value;
        if (name === 'phone') {
            changedValue = changedValue.replace(/[^0-9-]/g, '').replace(/(\..*?)\..*/g, '$1');
            if (changedValue.length >= 14) changedValue = changedValue.substring(0, 14);
        }
        if (name === 'otp') {
            changedValue = changedValue.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');
            if (changedValue.length >= 4) changedValue = changedValue.substring(0, 4);
        }
        if (name === 'country') {
            await getStateList(value);
            clearStateValue();
            clearCityValue();
            clearCityList();
        }
        if (name === 'state') {
            await getCityList(userData?.country?.value, value);
        }
        setUserData((prev) => ({
            ...prev,
            [name]: {
                ...prev[name],
                value: changedValue,
            },
        }));
    };

    async function getCountryList() {
        try {
            const resData = await apiMiddleWare({ url: '/json/country-list', type: 'get' });
            setUserData((prev) => ({
                ...prev,
                country: {
                    ...prev.country,
                    list: resData.data.packet,
                },
            }));

            return true;
        } catch (err) {
            return false;
        }
    }

    async function getStateList(countryCode) {
        try {
            const resData = await apiMiddleWare({ url: `/json/state-list/${countryCode}`, type: 'get' });
            setUserData((prev) => ({
                ...prev,
                state: {
                    ...prev.state,
                    list: resData.data.packet,
                },
            }));

            return true;
        } catch (err) {
            return false;
        }
    }
    async function getCityList(countryCode, stateCode) {
        try {
            const resData = await apiMiddleWare({ url: `/json/city-list/${countryCode}/${stateCode}`, type: 'get' });
            setUserData((prev) => ({
                ...prev,
                city: {
                    ...prev.city,
                    list: resData.data.packet,
                },
            }));

            return true;
        } catch (err) {
            return false;
        }
    }
    const handleMultiSelect = (e) => {
        const { name, value } = e.target;
        const changedValue = typeof value === 'string' ? value.split(',') : value;

        if (name === 'productInt') {
            const subProIntVal = userData?.subProductInt?.value;
            const newSubProVal = [...subProIntVal];

            const productIntList = userData?.productInt?.list;
            const differenceProdList = productIntList.filter((i) => value?.indexOf(i) === -1);

            subProIntVal.forEach((i) => {
                differenceProdList.forEach((j) => {
                    if (productList?.[j]?.indexOf(i) !== -1) {
                        newSubProVal.splice(newSubProVal?.indexOf(i), 1);
                    }
                });
            });

            setUserData((prev) => ({
                ...prev,
                subProductInt: {
                    ...prev?.subProductInt,
                    value: newSubProVal,
                },
                productInt: {
                    ...prev?.productInt,
                    value: changedValue,
                },
            }));
        } else if (name === 'subProductInt') {
            setUserData((prev) => ({
                ...prev,
                subProductInt: {
                    ...prev?.subProductInt,
                    value: changedValue,
                },
            }));
        }
    };

    const subProductList = Object.keys(productList)?.map((name) => {
        if (userData?.productInt?.value?.indexOf(name) === -1) return null;
        return productList?.[name]?.map((j) => (
            <MenuItem
                key={name + j}
                value={j}
                data-key={name}
                // style={getStyles(name, personName, theme)}
            >
                <Checkbox checked={userData?.subProductInt?.value.indexOf(j) > -1} />
                <ListItemText primary={j} />
            </MenuItem>
        ));
    });

    const resetForm = () => {
        setCallApi((prev) => !prev);
    };

    return (
        <>
            <Container className="h-100 w-100">
                {/* <Typography variant="h3" sx={{ mb: 5 }}>
          Showcase Profile
        </Typography> */}
                {isLoading ? (
                    <Loader />
                ) : (
                    <Box>
                        <form onSubmit={submitForm}>
                            <Grid container spacing={2} className="">
                                <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="title"
                                        id="outlined-select-currency"
                                        select
                                        label="Title"
                                        value={userData?.title?.value}
                                        onChange={handleChange1}
                                        required
                                    >
                                        <MenuItem key={''} value={''}>
                                            -- Select --
                                        </MenuItem>
                                        {userData?.title?.list?.length > 0 &&
                                            userData?.title?.list?.map((i) => (
                                                <MenuItem key={i} value={i}>
                                                    {i}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="firstName"
                                        label="First Name"
                                        value={userData?.firstName?.value}
                                        onChange={handleChange1}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="lastName"
                                        label="Last Name"
                                        value={userData?.lastName?.value}
                                        onChange={handleChange1}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="countryCode"
                                        id="outlined-select-currency"
                                        select
                                        label="Country Code"
                                        value={userData?.countryCode?.value}
                                        onChange={handleChange1}
                                    >
                                        <MenuItem key={''} value={''}>
                                            -- Select --
                                        </MenuItem>
                                        {userData?.countryCode?.list?.length > 0 &&
                                            userData?.countryCode?.list?.map((i) => (
                                                <MenuItem key={`${i?.country}-${i?.code}`} value={i?.code}>
                                                    {`${i?.country} (+${i?.code})`}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="phone"
                                        label="Phone"
                                        value={userData?.phone?.value}
                                        onChange={handleChange1}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="email"
                                        label="Official Email"
                                        value={userData?.email?.value}
                                        onChange={handleChange1}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="designation"
                                        label="Designation"
                                        value={userData?.designation?.value}
                                        onChange={handleChange1}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="company"
                                        label="Company Name"
                                        value={userData?.company?.value}
                                        onChange={handleChange1}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="typeOfBusiness"
                                        id="outlined-select-currency"
                                        select
                                        label="Type Of Business"
                                        value={userData?.typeOfBusiness?.value}
                                        onChange={handleChange1}
                                        required
                                    >
                                        <MenuItem key={''} value={''}>
                                            -- Select --
                                        </MenuItem>
                                        {userData?.typeOfBusiness?.list?.length > 0 &&
                                            userData?.typeOfBusiness?.list?.map((i) => (
                                                <MenuItem key={i} value={i}>
                                                    {i}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="address1"
                                        label="Address Line 1"
                                        value={userData?.address1?.value}
                                        onChange={handleChange1}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="address2"
                                        label="Address Line 2"
                                        value={userData?.address2?.value}
                                        onChange={handleChange1}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="postalCode"
                                        label="Postal Code"
                                        value={userData?.postalCode?.value}
                                        onChange={handleChange1}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="country"
                                        id="outlined-select-currency"
                                        select
                                        label="Country"
                                        value={userData?.country?.value}
                                        onChange={handleChange1}
                                        required
                                    >
                                        <MenuItem key={''} value={''}>
                                            -- Select --
                                        </MenuItem>
                                        {userData?.country?.list &&
                                            userData?.country?.list?.length > 0 &&
                                            userData?.country?.list?.map((i) => (
                                                <MenuItem key={i?.id} value={i?.iso2}>
                                                    {i?.name}
                                                </MenuItem>
                                            ))}
                                        <MenuItem key={'Other'} value={'Other'}>
                                            Other
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="state"
                                        id="outlined-select-currency"
                                        select
                                        label="State"
                                        value={userData?.state?.value}
                                        onChange={handleChange1}
                                    >
                                        <MenuItem key={''} value={''}>
                                            -- Select --
                                        </MenuItem>
                                        {userData?.state?.list?.length > 0 &&
                                            userData?.state?.list?.map((i) => (
                                                <MenuItem key={i?.id} value={i?.iso2}>
                                                    {i?.name}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="city"
                                        id="outlined-select-currency"
                                        select
                                        label="City"
                                        value={userData?.city?.value}
                                        onChange={handleChange1}
                                    >
                                        <MenuItem key={''} value={''}>
                                            -- Select --
                                        </MenuItem>
                                        {userData?.city?.list?.length > 0 &&
                                            userData?.city?.list?.map((i) => (
                                                <MenuItem key={i?.id} value={i?.name}>
                                                    {i?.name}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} flexGrow={1}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-multiple-checkbox-label">Products Interested*</InputLabel>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            fullWidth
                                            multiple
                                            name="productInt"
                                            value={userData?.productInt?.value}
                                            onChange={handleMultiSelect}
                                            input={<OutlinedInput label="Products Interested*" />}
                                            // renderValue={(selected) => selected.join(', ')}
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                  return 'No categories selected';
                                                }
                                                return selected.length === 1 ? selected[0] : `${selected[0]}..`;
                                              }}
                                            // MenuProps={MenuProps}
                                            required
                                        >
                                            {userData?.productInt?.list?.map((name) => (
                                                <MenuItem
                                                    key={name}
                                                    value={name}
                                                    // style={getStyles(name, personName, theme)}
                                                >
                                                    <Checkbox checked={userData?.productInt?.value.indexOf(name) > -1} />
                                                    <ListItemText primary={name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} flexGrow={1}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-multiple-name-label">Sub Products Interested*</InputLabel>
                                        <Select
                                            labelId="demo-multiple-name-label"
                                            id="demo-multiple-name"
                                            fullWidth
                                            multiple
                                            name="subProductInt"
                                            value={userData?.subProductInt?.value}
                                            onChange={handleMultiSelect}
                                            input={<OutlinedInput label="Sub Products Interested*" />}
                                            // renderValue={(selected) => selected.join(', ')}
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                  return 'No categories selected';
                                                }
                                                return selected.length === 1 ? selected[0] : `${selected[0]}..`;
                                              }}
                                            MenuProps={{
                                                style: {
                                                    maxHeight: 400,
                                                    maxWidth: 600,
                                                },
                                            }}
                                            required
                                        >
                                            {subProductList}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid className=" d-flex justify-content-end mt-3">
                                <div className="mx-3">
                                    <Button type="button" variant="outlined" color="error" disabled={isLoading} onClick={resetForm}>
                                        Reset
                                    </Button>
                                </div>
                                <div>
                                    <Button type="submit" variant="contained" disabled={isLoading}>
                                        Update Data
                                    </Button>
                                </div>
                            </Grid>
                        </form>
                    </Box>
                )}
            </Container>
        </>
    );
}

export default UserMyProfile;

// requiredList = ['title','firstName','lastName','phone','email','designation','company','typeOfBusiness','country','productInt','subProductInt']
// inputList = ['title','firstName','lastName','countryCode','phone','email','designation','company','typeOfBusiness','address1','address2','postalCode','country','state','city', 'productInt','subProductInt']
// inputObj = {
//   title : { sendName: 'title', label: 'Title',isNullValue: (value) => !Boolean(value) ,isRequired: true,errorMsg : 'Title is required', get getValue(){ return userData?.title?.value || this.defaultValue} , defaultValue: '' } ,
//   country : { sendName: 'country',label: 'Country',  isNullValue: (value) => !Boolean(value) ,isRequired: true,errorMsg : 'Country is required', get getValue(){ return userData?.conuntry?.value || this.defaultValue} , defaultValue: '',type:'select' },
//   country : { sendName: 'country',label: 'Country',  isNullValue: (value) => !Boolean(value) ,isRequired: true,errorMsg : 'Country is required', get getValue(){ return userData?.conuntry?.value || this.defaultValue} , defaultValue: '',type:'select' },
//   state : { sendName: 'state', label: 'State', isNullValue: (value) => !Boolean(value) ,isRequired: true,errorMsg : 'Country is required', get getValue(){ return userData?.conuntry?.value || this.defaultValue} , defaultValue: '',type:'select' },
//   productInt : { sendName: 'productInterested', label: 'State', isNullValue: (value) => !Boolean(value) ,isRequired: true,errorMsg : 'Pordu is required', get getValue(){ return userData?.productInt?.value || this.defaultValue} , defaultValue: [],type:'multiselect', onChange : handleMultiSelect },
//   subProductInt : { sendName: 'subProductInterested', label: 'State', isNullValue: (value) => !Boolean(value) ,isRequired: true,errorMsg : 'Sub Pordu is required', get getValue(){ return userData?.subProductInt?.value || this.defaultValue} , defaultValue: [],type:'multiselect' ,onChange : handleMultiSelect},
// }
