/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  makeStyles,
  styled,
} from '@mui/material';
import { Form, InputGroup } from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';

import Loader from '../../../components/loader';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useResponsive from '../../../hooks/useResponsive';
import { copyText, handleDownload, openInNewTab, useQuery } from '../../../utils/helper';
import { defaultError, wocOrange, tableBgColor } from '../../../utils/constants';
import Iconify from '../../../components/iconify/Iconify';
import apiMiddleWare from '../../../utils/useApiMiddleWare';
import customToast from '../../../utils/customToast';

function UserMarketplace() {
  const query = useQuery();

  const smUp = useResponsive('up', 'sm');
  const [isLoading, setIsLoading] = useState(false);
  const filterDataLocal = useLocalStorage('filterData');
  const sID = filterDataLocal?.showcaseID;
  const [callApi, setCallApi] = useState(false);

  const tabParam = query.get('tab');
  const [currentTab, setCurrentTab] = useState(
    tabParam === 'document' ? 1 : tabParam === 'video' ? 2 : tabParam === 'social' ? 3 : 0
  );
  const [storeData, setStoreData] = useState({
    reviewTable: { data: [], copy: [], searchV: '', called: false, loading: false },
    wishlistTable: { data: [], copy: [], searchV: '', called: false, loading: false },
    bookmarkTable: { data: [], copy: [], searchV: '', called: false, loading: false },
    addToBagTableGal: { data: [], copy: [], searchV: '', called: false, loading: false },
    addToBagTableDoc: { data: [], copy: [], searchV: '', called: false, loading: false },
  });

  useEffect(() => {
    if (currentTab === 0 && !storeData?.reviewTable?.called)
      commonResourceFile({ url: `/showcase/review-product/${sID}`, type: 'get', name: 'reviewTable' });
  }, [currentTab, callApi, sID]);
  useEffect(() => {
    if (currentTab === 1 && !storeData?.wishlistTable?.called)
      commonResourceFile({ url: `/showcase/wishlist-company/${sID}`, type: 'get', name: 'wishlistTable' });
  }, [currentTab, callApi, sID]);
  useEffect(() => {
    if (currentTab === 2 && !storeData?.bookmarkTable?.called)
      commonResourceFile({ url: `/showcase/bookmark-company/${sID}`, type: 'get', name: 'bookmarkTable' });
  }, [currentTab, callApi, sID]);
  useEffect(() => {
    if (currentTab === 3 && !storeData?.addToBagTableDoc?.called)
      commonResourceFile({
        url: `/showcase/add-to-bag-company/document/${sID}`,
        type: 'get',
        name: 'addToBagTableDoc',
      });
  }, [currentTab, callApi, sID]);

  // if (currentTab === 3 && !storeData?.addToBagTableGal?.called)
  // commonResourceFile({ url: `/showcase/add-to-bag-company/gallery/${sID}`, type: 'get', name: 'addToBagTableGal' });
  // }, [currentTab, callApi]);

  const commonResourceFile = useCallback(
    async ({ url, name, type = 'post' }) => {
      try {
        setIsLoading(true);
        const res = await apiMiddleWare({ type, url });

        if (!res) {
          setIsLoading(false);
          return false;
        }
        if (!res?.data?.ok) {
          customToast({
            msg: res?.data?.errors?.[0]?.message || res?.data?.errors?.[0]?.msg || defaultError,
            type: 'error',
          });
          setIsLoading(false);
          return false;
        }

        const dataHere = res?.data?.packet;
        setStoreData((prev) => ({
          ...prev,
          [name]: { loading: false, data: dataHere, copy: dataHere, called: true },
        }));
        setIsLoading(false);
        return true;
      } catch (err) {
        customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
        setIsLoading(false);
        return false;
      }
    },
    [storeData]
  );

  const filterTable = (name) => (e) => {
    const searchValue = e.target.value?.toLowerCase();
    const originalData = storeData?.[name]?.copy;
    let filteredData = [];
    if (name === 'reviewTable') {
      filteredData = originalData.filter((i) => i?.productRef?.title?.toLowerCase()?.indexOf(searchValue) !== -1);
    } else if (name === 'wishlistTable') {
      filteredData = originalData.filter((i) => i?.productRef?.title.toLowerCase()?.indexOf(searchValue) !== -1);
    } else if (name === 'bookmarkTable') {
      filteredData = originalData.filter(
        (i) =>
          i?.visitorRef?.email?.toLowerCase()?.indexOf(searchValue) !== -1 ||
          i?.visitorRef?.profile?.fullName?.toLowerCase()?.indexOf(searchValue) !== -1 ||
          i?.visitorRef?.profile?.phone?.toString()?.indexOf(searchValue) !== -1 ||
          i?.visitorRef?.profile?.designation?.toLowerCase()?.indexOf(searchValue) !== -1 ||
          i?.visitorRef?.profile?.company?.toLowerCase()?.indexOf(searchValue) !== -1
      );
    } else if (name === 'addToBagTableDoc') {
      filteredData = originalData.filter(
        (i) =>
          i?.documentRef?.title?.toLowerCase()?.indexOf(searchValue) !== -1 ||
          i?.documentRef?.extension?.toLowerCase()?.indexOf(searchValue) !== -1
      );
    }
    setStoreData((prev) => ({
      ...prev,
      [name]: {
        ...prev?.[name],
        searchV: searchValue,
        data: filteredData,
      },
    }));
  };

  const onClickShowcase = async ({ showcaseID }) => {
    const identifier = await getIdentifierName(showcaseID);
    openInNewTab(`/showcase/${identifier}`)();
  };
  const onClickProduct = async ({ productID, showcaseID }) => {
    const identifier = await getIdentifierName(showcaseID);
    openInNewTab(`/showcase/${identifier}/product/${productID}`)();
  };

  const getIdentifierName = async (sid) => {
    try {
      const res = await apiMiddleWare({ type: 'get', url: `/showcase/get-identifier/${sid}` });

      if (!res?.data?.ok) {
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        return false;
      }
      const dataHere = res?.data?.packet;
      return dataHere?.identifier;
    } catch (err) {
      customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  function handleTabChange(e, newValue) {
    setCurrentTab(newValue);
  }

  const TableHeadCustom = styled(TableHead)({
    '& .MuiTableCell-head': {
      color: 'white',
      backgroundColor: wocOrange,
      // textAlign: 'center',
    },
  });

  const TableRowStyle = {
    '&:last-child td, &:last-child th': { border: 0 },
    '&:nth-child(odd)': {
      backgroundColor: tableBgColor,
    },
    '&:nth-child(even)': {
      backgroundColor: 'white',
    },
  };

  const callDownload = async (type) => {
    try {
      const keys = Object.keys(storeData);
      if (keys.indexOf(type) === -1) {
        customToast({ msg: 'Invalid Type', type: 'error' });
        return false;
      }
      let data = storeData?.[type]?.copy;

      if (!data || data.length === 0) {
        customToast({ msg: 'No Data Found', type: 'error' });
        return false;
      }
      customToast({ type: 'success', msg: 'Download will start in few seconds' });

      let dataOrder;
      let dataObj;

      if (type === 'reviewTable') {
        data = data.flatMap((obj) =>
          obj.review.map((review) => ({
            showcaseRef: obj.showcaseRef,
            productRef: obj.productRef,
            companyRef: obj.companyRef,
            ...review,
          }))
        );

        dataOrder = ['index', 'productName', 'name', 'email', 'subject', 'description', 'rating'];
        dataObj = {
          index: { label: '#', valueLocation: 'index' },
          productName: { label: 'Product Name', valueLocation: 'productRef.title' },
          visitor: { label: 'Visitor', valueLocation: 'review', format: (data) => JSON.stringify(data) },
          name: { label: 'Name', valueLocation: 'visitorRef.profile.fullName' },
          email: { label: 'Email', valueLocation: 'visitorRef.email' },
          subject: { label: 'Subject', valueLocation: 'subject' },
          phone: { label: 'Phone Number', valueLocation: 'visitorRef.profile.phone' },
          company: { label: 'Company', valueLocation: 'visitorRef.profile.company' },
          designation: { label: 'Designation', valueLocation: 'visitorRef.profile.designation' },
          description: { label: 'Description', valueLocation: 'description' },
          rating: { label: 'Rating', valueLocation: 'rating' },
        };
      } else if (type === 'wishlistTable') {
        data = data.flatMap((obj) =>
          obj.visitorRef.map((visitorRef) => ({
            productRef: obj.productRef,
            companyRef: obj.companyRef,
            ...visitorRef,
          }))
        );
        dataOrder = ['index', 'productName', 'name', 'email', 'company', 'designation', 'phone'];
        dataObj = {
          index: { label: '#', valueLocation: 'index' },
          productName: { label: 'Product Name', valueLocation: 'productRef.title' },
          visitor: { label: 'Visitor', valueLocation: 'visitorRef', format: (data) => JSON.stringify(data) },
          name: { label: 'Name', valueLocation: 'profile.fullName' },
          email: { label: 'Email', valueLocation: 'email' },
          company: { label: 'Company', valueLocation: 'profile.company' },
          designation: { label: 'Designation', valueLocation: 'profile.designation' },
          phone: { label: 'Phone Number', valueLocation: 'profile.phone' },
        };
      } else if (type === 'bookmarkTable') {
        dataOrder = ['index', 'name', 'email', 'phone', 'company', 'designation'];
        dataObj = {
          index: { label: '#', valueLocation: 'index' },
          name: { label: 'Name', valueLocation: 'visitorRef.profile.fullName' },
          email: { label: 'Email', valueLocation: 'visitorRef.email' },
          phone: { label: 'Phone Number', valueLocation: 'visitorRef.profile.phone' },
          company: { label: 'Company', valueLocation: 'visitorRef.profile.company' },
          designation: { label: 'Designation', valueLocation: 'visitorRef.profile.designation' },
        };
      } else if (type === 'addToBagTableDoc') {
        data = data.flatMap((obj) =>
          obj.visitorRef.map((visitorRef) => ({
            documentRef: obj.documentRef,
            ...visitorRef,
          }))
        );
        dataOrder = ['index', 'docName', 'type', 'name', 'email', 'phone', 'company', 'designation'];
        dataObj = {
          index: { label: '#', valueLocation: 'index' },
          docName: { label: 'Document Name', valueLocation: 'documentRef.title' },
          type: { label: 'Document Type', valueLocation: 'documentRef.extension' },
          visitor: { label: 'Visitor', valueLocation: 'visitorRef', format: (data) => JSON.stringify(data) },
          name: { label: 'Name', valueLocation: 'profile.fullName' },
          email: { label: 'Email', valueLocation: 'email' },
          phone: { label: 'Phone Number', valueLocation: 'profile.phone' },
          company: { label: 'Company', valueLocation: 'profile.company' },
          designation: { label: 'Designation', valueLocation: 'profile.designation' },
        };
      }

      await handleDownload({ fileName: `${type}`, dataOrder, dataObj, data });

      return true;
    } catch (err) {
      console.error(err);
      customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  return (
    <>
      <Container maxWidth="xl" className="mt-4 mt-md-0">
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={currentTab}
            onChange={(e, newV) => handleTabChange(e, newV)}
            aria-label="Tabs where selection follows focus"
            sx={{
              '& .MuiTabs-flexContainer': { gap: smUp ? '25px' : '10px', flexWrap: 'wrap' },
              '& .Mui-selected': {
                color: 'white !important',
                backgroundColor: wocOrange,
              },
              '& .MuiTabs-indicator': {
                width: '0 !important',
              },
              '& .MuiTab-root': {
                minHeight: '60px',
              },
            }}
            className="expand_tabs"
          >
            <Tab
              iconPosition={smUp ? 'top' : 'start'}
              icon={<Iconify icon="material-symbols:reviews" width={smUp ? 30 : 20} />}
              label="Reviews"
            />
            <Tab
              iconPosition={smUp ? 'top' : 'start'}
              icon={<Iconify icon="mdi:heart" width={smUp ? 30 : 20} />}
              label="Wishlist"
            />
            <Tab
              iconPosition={smUp ? 'top' : 'start'}
              icon={<Iconify icon="material-symbols:bookmark" width={smUp ? 30 : 20} />}
              label="Bookmark"
            />
            <Tab
              iconPosition={smUp ? 'top' : 'start'}
              icon={<Iconify icon="ion:bag" width={smUp ? 30 : 20} />}
              label="Add To Bag"
            />
          </Tabs>

          {/* Review Page Start */}
          <div
            role="tabpanel"
            hidden={currentTab !== 0}
            id={`simple-tabpanel-${0}`}
            aria-labelledby={`simple-tab-${0}`}
          >
            <Box sx={{ py: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="d-flex">
                    <Typography variant="h6" className=" black-login fs-6 my-3">
                      Review Table
                    </Typography>
                  </div>

                  <Card className="bg-transparent bordre-0 shadow-none row justify-content-between">
                    <Form className="col-md-5 col-12 mb-3 ">
                      <InputGroup>
                        <InputGroup.Text className="bg-white border-0 pe-0 me-0 shadow-none">
                          <Iconify icon="ic:outline-search" />
                        </InputGroup.Text>
                        <Form.Control
                          className="border-0 shadow-none"
                          placeholder="Search By Product Name"
                          aria-label="Search"
                          disabled={isLoading}
                          value={storeData?.reviewTable?.searchV}
                          onChange={filterTable('reviewTable')}
                        />
                      </InputGroup>
                    </Form>
                    <div className="col-md-2 mb-3">
                      <Button
                        variant="contained"
                        disabled={isLoading}
                        onClick={() => callDownload('reviewTable')}
                        className=" w-100"
                      >
                        Download Excel
                      </Button>
                    </div>
                  </Card>
                  <Box>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <Grid item xs={12}>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: smUp ? 650 : 'auto' }} aria-label="simple table">
                            <TableHeadCustom>
                              <TableRow>
                                <TableCell align="left">Index</TableCell>
                                <TableCell align="center">Product Name</TableCell>
                                <TableCell align="right">
                                  <Tooltip title={'Click on fields to copy Email ID'} placement={'bottom-start'}>
                                    <span className="cursor-pointer">
                                      No. Of Review
                                      <Iconify
                                        icon="material-symbols:info-outline-rounded"
                                        className="ms-2"
                                        width={25}
                                      />
                                    </span>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            </TableHeadCustom>
                            <TableBody>
                              {storeData?.reviewTable?.data?.length > 0 &&
                                storeData?.reviewTable?.data?.map((row, index) => (
                                  <TableRow key={row._id} sx={TableRowStyle}>
                                    <TableCell align="left">{index + 1}</TableCell>
                                    <TableCell align="center">
                                      <span
                                        className="cursor-pointer me-2"
                                        onClick={() =>
                                          onClickProduct({
                                            productID: row?.productRef?._id,
                                            showcaseID: row?.companyRef?.defaultShowcaseRef,
                                          })
                                        }
                                      >
                                        <Iconify icon="mdi:link-variant" sx={{ color: '#3f51b5' }} />
                                      </span>
                                      <Tooltip title={row?.productRef?.title} placement={'bottom-start'}>
                                        <span className="cursor-pointer">{row?.productRef?.title}</span>
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell align="right">
                                      <div className="cursor-pointer">
                                        <Tooltip
                                          title={row?.review?.map((i) => i?.visitorRef?.profile?.fullName).join(',')}
                                          placement={'bottom-start'}
                                          onClick={() =>
                                            copyText(
                                              row?.review?.map((i) => i?.visitorRef?.email).join(','),
                                              'Email ID Copied'
                                            )
                                          }
                                        >
                                          <span>{row?.review?.length}</span>
                                        </Tooltip>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              {storeData?.reviewTable?.data?.length === 0 && (
                                <TableRow>
                                  <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                                    No Data Found
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </div>
          {/* Review Page End */}

          {/* Wishlist Page Start */}
          <div
            role="tabpanel"
            hidden={currentTab !== 1}
            id={`simple-tabpanel-${1}`}
            aria-labelledby={`simple-tab-${1}`}
          >
            <Box sx={{ py: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="d-flex">
                    <Typography variant="h6" className=" black-login fs-6 my-3">
                      Wishlisted Product
                    </Typography>
                  </div>

                  <Card className="bg-transparent bordre-0 shadow-none row justify-content-between">
                    <Form className="col-md-5 col-12 mb-3 ">
                      <InputGroup>
                        <InputGroup.Text className="bg-white border-0 pe-0 me-0 shadow-none">
                          <Iconify icon="ic:outline-search" />
                        </InputGroup.Text>
                        <Form.Control
                          className="border-0 shadow-none"
                          placeholder="Search By Product Name"
                          aria-label="Search"
                          disabled={isLoading}
                          value={storeData?.wishlistTable?.searchV}
                          onChange={filterTable('wishlistTable')}
                        />
                      </InputGroup>
                    </Form>
                    <div className="col-md-2 mb-3">
                      <Button
                        variant="contained"
                        disabled={isLoading}
                        onClick={() => callDownload('wishlistTable')}
                        className=" w-100"
                      >
                        Download Excel
                      </Button>
                    </div>
                  </Card>
                  <Box>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <Grid item xs={12}>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: smUp ? 650 : 'auto' }} aria-label="simple table">
                            <TableHeadCustom>
                              <TableRow>
                                <TableCell align="left">Index</TableCell>
                                <TableCell align="center">Product Name</TableCell>
                                <TableCell align="right">
                                  <Tooltip title={'Click on fields to copy Email ID'} placement={'bottom-start'}>
                                    <span className="cursor-pointer">
                                      No. of Users
                                      <Iconify
                                        icon="material-symbols:info-outline-rounded"
                                        className="ms-2"
                                        width={25}
                                      />
                                    </span>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            </TableHeadCustom>
                            <TableBody>
                              {storeData?.wishlistTable?.data?.length > 0 &&
                                storeData?.wishlistTable?.data?.map((row, index) => (
                                  <TableRow key={row._id} sx={TableRowStyle}>
                                    <TableCell align="left">{index + 1}</TableCell>
                                    <TableCell align="center">
                                      <span
                                        className="cursor-pointer me-2"
                                        onClick={() =>
                                          onClickProduct({
                                            productID: row?.productRef?._id,
                                            showcaseID: row?.companyRef?.defaultShowcaseRef,
                                          })
                                        }
                                      >
                                        <Iconify icon="mdi:link-variant" sx={{ color: '#3f51b5' }} />
                                      </span>

                                      <Tooltip title={row?.productRef?.title} placement={'bottom-start'}>
                                        <span className="cursor-pointer">{row?.productRef?.title}</span>
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell align="right">
                                      <div className="cursor-pointer">
                                        <Tooltip
                                          title={row?.visitorRef?.map((i) => i?.profile?.fullName).join(',')}
                                          placement={'bottom-start'}
                                          onClick={() =>
                                            copyText(row?.visitorRef?.map((i) => i?.email).join(','), 'Email ID Copied')
                                          }
                                        >
                                          <span>{row?.visitorRef?.length}</span>
                                        </Tooltip>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              {storeData?.wishlistTable?.data?.length === 0 && (
                                <TableRow>
                                  <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                                    No Data Found
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </div>
          {/* Wishlist Page End */}

          {/* Bookmark Page Start */}
          <div
            role="tabpanel"
            hidden={currentTab !== 2}
            id={`simple-tabpanel-${2}`}
            aria-labelledby={`simple-tab-${2}`}
          >
            <Box sx={{ py: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="d-flex">
                    <Typography variant="h6" className=" black-login fs-6 my-3">
                      People Who Bookmarked Showcase
                    </Typography>
                  </div>

                  <Card className="bg-transparent bordre-0 shadow-none row justify-content-between">
                    <Form className="col-md-5 col-12 mb-3 ">
                      <InputGroup>
                        <InputGroup.Text className="bg-white border-0 pe-0 me-0 shadow-none">
                          <Iconify icon="ic:outline-search" />
                        </InputGroup.Text>
                        <Form.Control
                          className="border-0 shadow-none"
                          placeholder="Search By Name, Email, Phone, Company, Designation"
                          aria-label="Search"
                          disabled={isLoading}
                          value={storeData?.bookmarkTable?.searchV}
                          onChange={filterTable('bookmarkTable')}
                        />
                      </InputGroup>
                    </Form>
                    <div className="col-md-2 mb-3">
                      <Button
                        variant="contained"
                        disabled={isLoading}
                        onClick={() => callDownload('bookmarkTable')}
                        className=" w-100"
                      >
                        Download Excel
                      </Button>
                    </div>
                  </Card>
                  <Box>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <Grid item xs={12}>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: smUp ? 650 : 'auto' }} aria-label="simple table">
                            <TableHeadCustom>
                              <TableRow>
                                <TableCell align="left">Index</TableCell>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="center">Email</TableCell>
                                <TableCell align="center">Phone</TableCell>
                                <TableCell align="center">Company</TableCell>
                                <TableCell align="right">Designation</TableCell>
                              </TableRow>
                            </TableHeadCustom>
                            <TableBody>
                              {storeData?.bookmarkTable?.data?.length > 0 &&
                                storeData?.bookmarkTable?.data?.map((row, index) => (
                                  <TableRow key={row._id} sx={TableRowStyle}>
                                    <TableCell align="left">{index + 1}</TableCell>
                                    <TableCell align="center">
                                      <Tooltip title={row?.visitorRef?.profile?.fullName} placement={'bottom-start'}>
                                        <span className="cursor-pointer">{row?.visitorRef?.profile?.fullName}</span>
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Tooltip title={row?.visitorRef?.email} placement={'bottom-start'}>
                                        <span className="cursor-pointer"> {row?.visitorRef?.email}</span>
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Tooltip title={row?.visitorRef?.profile?.phone} placement={'bottom-start'}>
                                        <span className="cursor-pointer"> {row?.visitorRef?.profile?.phone}</span>
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Tooltip title={row?.visitorRef?.profile?.company} placement={'bottom-start'}>
                                        <span className="cursor-pointer"> {row?.visitorRef?.profile?.company}</span>
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell align="right">
                                      <Tooltip title={row?.visitorRef?.profile?.designation} placement={'bottom-start'}>
                                        <span className="cursor-pointer"> {row?.visitorRef?.profile?.designation}</span>
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              {storeData?.bookmarkTable?.data?.length === 0 && (
                                <TableRow>
                                  <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                                    No Data Found
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </div>
          {/* Bookmark Page End */}

          {/* Add To Bag Page Start */}
          <div
            role="tabpanel"
            hidden={currentTab !== 3}
            id={`simple-tabpanel-${3}`}
            aria-labelledby={`simple-tab-${3}`}
          >
            <Box sx={{ py: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="d-flex">
                    <Typography variant="h6" className=" black-login fs-6 my-3">
                      Resource Added To Bag Table
                    </Typography>
                  </div>

                  <Card className="bg-transparent bordre-0 shadow-none row justify-content-between">
                    <Form className="col-md-5 col-12 mb-3 ">
                      <InputGroup>
                        <InputGroup.Text className="bg-white border-0 pe-0 me-0 shadow-none">
                          <Iconify icon="ic:outline-search" />
                        </InputGroup.Text>
                        <Form.Control
                          className="border-0 shadow-none"
                          placeholder="Search By Name, Type"
                          aria-label="Search"
                          disabled={isLoading}
                          value={storeData?.addToBagTableDoc?.searchV}
                          onChange={filterTable('addToBagTableDoc')}
                        />
                      </InputGroup>
                    </Form>
                    <div className="col-md-2 mb-3">
                      <Button
                        variant="contained"
                        disabled={isLoading}
                        onClick={() => callDownload('addToBagTableDoc')}
                        className=" w-100"
                      >
                        Download Excel
                      </Button>
                    </div>
                  </Card>
                  <Box>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <Grid item xs={12}>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: smUp ? 650 : 'auto' }} aria-label="simple table">
                            <TableHeadCustom>
                              <TableRow>
                                <TableCell align="left">Index</TableCell>
                                <TableCell align="center">Document Name</TableCell>
                                <TableCell align="center">Document Type</TableCell>
                                <TableCell align="right">
                                  <Tooltip title={'Click on fields to copy Email ID'} placement={'bottom-start'}>
                                    <span className="cursor-pointer">
                                      No. Of Users
                                      <Iconify
                                        icon="material-symbols:info-outline-rounded"
                                        className="ms-2"
                                        width={25}
                                      />
                                    </span>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            </TableHeadCustom>
                            <TableBody>
                              {storeData?.addToBagTableDoc?.data?.length > 0 &&
                                storeData?.addToBagTableDoc?.data?.map((row, index) => (
                                  <TableRow key={row._id} sx={TableRowStyle}>
                                    <TableCell align="left">{index + 1}</TableCell>
                                    <TableCell align="center">
                                      <span
                                        className="cursor-pointer me-2"
                                        onClick={() =>
                                          onClickShowcase({
                                            showcaseID: row?.showcaseRef,
                                          })
                                        }
                                      >
                                        <Iconify icon="mdi:link-variant" sx={{ color: '#3f51b5' }} />
                                      </span>
                                      <Tooltip title={row?.documentRef?.title} placement={'bottom-start'}>
                                        <span className="cursor-pointer">{row?.documentRef?.title}</span>
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Tooltip title={row?.documentRef?.extension} placement={'bottom-start'}>
                                        <span className="cursor-pointer"> {row?.documentRef?.extension}</span>
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell align="right">
                                      <div className="cursor-pointer">
                                        <Tooltip
                                          title={row?.visitorRef?.map((i) => i?.profile?.fullName).join(',')}
                                          placement={'bottom-start'}
                                          onClick={() =>
                                            copyText(row?.visitorRef?.map((i) => i?.email).join(','), 'Email ID Copied')
                                          }
                                        >
                                          <span>{row?.visitorRef?.length}</span>
                                        </Tooltip>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              {storeData?.addToBagTableDoc?.data?.length === 0 && (
                                <TableRow>
                                  <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                                    No Data Found
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </div>
          {/* Add To Bag Page End */}
        </Box>
      </Container>
    </>
  );
}

export default UserMarketplace;
