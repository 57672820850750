/* eslint-disable react/jsx-no-bind */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Grid,
    Input,
    InputLabel,
    ListItemText,
    ListSubheader,
    MenuItem,
    Select as MultiSelect,
    TextField,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import Iconify from '../../../components/iconify';
import CustomInputLabel from '../../../components/label/CustomInputLabel';
import ImageUpload from '../../../components/image-upload/ImageUpload';
import CommonModal from '../../../components/modal/CommonModal';
import { S3BucketBaseUrl, ckEditorConfig, confirmGreen, defaultError, productList } from '../../../utils/constants';
import customToast from '../../../utils/customToast';
import { getExtension, openInNewTab, parseVideo, uploadImageToS3 } from '../../../utils/helper';
import apiMiddleWare from '../../../utils/useApiMiddleWare';
import ImageUploadNew from '../../../components/image-upload/imageUploadNew';

const iconFontSize = '1.9rem';

function SingleProductPage({ data: parentData, open, onClose, mode }) {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [formChanged, setFormChanged] = useState(false);
    const [callApi, setCallApi] = useState(false);

    const [dataHere, setDataHere] = useState({
        name: '',
        description: '',
        thumbnail: { value: '', name: '' },
        url: '',
        hashtags: { value: '', split: '' },
        availableCountry: { value: [], list: [] },
        minQuantity: '',
        unit: '',
        pricePerUnit: '',
        currency: {},
        catalogItems: [],
        galleryItems: [],
        productCategory: { value: [], list: Object.keys(productList) },
        subproductCategory: { value: [], list: [] },
        featureDescription: '',
        isGlobal: false,
        page: 1,
        id: '',
    });
    const [addEditData, setAddEditData] = useState({
        mode: 'add',
        subtype: 'gallery',
        data: {
            id: '',
            name: '',
            type: 'image',
            src: '',
            file: {},
            imgName: '',
            videoProvider: '',
        },
        modalOpen: false,
    });
    const [menuOption, setMenuOption] = useState({
        country: false,
        prod: false,
        subProd: false,
    });
    const [currecyList, setCurrecyList] = useState([]);

    useEffect(() => {
        if (open) {
            getSingleProduct();
            if (currecyList?.length === 0) {
                getCurrencyList();
            }
            if (!dataHere?.availableCountry?.list || dataHere?.availableCountry?.list?.length === 0) {
                getCountryList();
            }
        }
    }, [callApi, open]);

    useEffect(() => {
        onBlurHashtag();
    }, [dataHere?.hashtags?.value]);

    async function getSingleProduct() {
        if (!parentData?.id && !dataHere?.id) {
            return;
        }
        try {
            setIsLoading(true);
            const res = await apiMiddleWare({ type: 'get', url: `/company/product/get/${parentData?.id || dataHere?.id}` });
            if (!res?.data?.ok) {
                customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
                return;
            }

            const singleData = res?.data?.packet;

            setDataHere((prev) => ({
                ...prev,
                name: singleData?.title || '',
                description: singleData?.description || '',
                thumbnail: {
                    name: singleData?.thumbnail || '',
                    value: singleData?.thumbnail ? S3BucketBaseUrl + singleData?.thumbnail : '',
                },
                url: singleData?.url || '',
                hashtags: { value: singleData?.hashtags?.join(',') || '', split: singleData?.hashtags || [] },
                availableCountry: { ...prev?.availableCountry, value: singleData?.availableCountry || [] },
                isGlobal: singleData?.isGlobal || false,
                minQuantity: singleData?.minQuantity || '',
                unit: singleData?.unit || '',
                pricePerUnit: singleData?.pricePerUnit || '',
                currency: singleData?.currency || '',
                featureDescription: singleData?.featureDescription || '',
                productCategory: { ...prev?.productCategory, value: singleData?.productCategory || [] },
                subproductCategory: { ...prev?.subproductCategory, value: singleData?.subproductCategory || [] },
                catalogItems: singleData?.productResourceRef?.catalogItems || '',
                galleryItems: singleData?.productResourceRef?.galleryItems || '',
            }));
            setIsLoading(false);
        } catch (err) {
            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
            setIsLoading(false);
        }
    }

    async function getCountryList() {
        try {
            const resData = await apiMiddleWare({ url: '/json/country-list', type: 'get' });
            setDataHere((prev) => ({
                ...prev,
                availableCountry: {
                    ...prev.availableCountry,
                    list: resData.data.packet,
                },
            }));

            return true;
        } catch (err) {
            return false;
        }
    }

    async function getCurrencyList() {
        try {
            const res = await apiMiddleWare({ type: 'get', url: `/json/currency-list` });
            if (!res?.data?.ok) {
                customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
                return false;
            }
            const allData = res?.data?.currencyList;
            setCurrecyList(allData);
            return true;
        } catch (err) {
            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
            setIsLoading(false);
            return false;
        }
    }

    function clearData() {
        setDataHere((prev) => ({
            name: '',
            description: '',
            url: '',
            hashtags: { value: '', split: '' },
            availableCountry: {
                ...prev.availableCountry,
                value: [],
            },
            minQuantity: '',
            unit: '',
            pricePerUnit: '',
            currency: {},
            featureDescription: '',
            catalogItems: [],
            galleryItems: [],
            productCategory: { ...prev.productCategory, value: [] },
            subproductCategory: { ...prev.subproductCategory, value: [] },
            page: 1,
            isGlobal: false,
        }));
        setFormChanged(false);
    }

    function onCloseHere(status = false) {
        clearData();
        onClose(formChanged || status);
    }

    function onBlurHashtag() {
        const text = dataHere?.hashtags?.value;
        if (dataHere?.hashtags?.value !== '' || dataHere?.hashtags?.value !== ',') {
            setDataHere((prev) => ({
                ...prev,
                hashtags: {
                    ...prev.hashtags,
                    split: text
                        .split(',')
                        .map((i) => i?.trim())
                        .filter((i) => i),
                },
            }));
        } else {
            setDataHere((prev) => ({
                ...prev,
                hashtags: {
                    ...prev.hashtags,
                    split: [],
                },
            }));
        }
    }

    function handleOnChange(e) {
        const { name, value } = e.target;
        if (name === 'hashtags') {
            setDataHere((prev) => ({
                ...prev,
                hashtags: {
                    ...prev.hashtags,
                    value,
                },
            }));
        } else if (name === 'isGlobal') {
            setDataHere((prev) => ({
                ...prev,
                isGlobal: !prev.isGlobal,
            }));
        } else {
            setDataHere((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    }

    const selectChange = (selectedOp) => {
        setDataHere((prev) => ({
            ...prev,
            currency: selectedOp,
        }));
    };

    async function validateFormProduct() {
        return new Promise((resolve, reject) => {
            const sendData = {};
            if (dataHere?.name === '') {
                reject(new Error(`Product Name is required`));
                return false;
            }
            sendData.title = dataHere?.name?.trim();

            if (dataHere?.description === '') {
                reject(new Error(`Product Summary is required`));
                return false;
            }
            sendData.description = dataHere?.description?.trim();

            if (!dataHere?.thumbnail?.value?.length) {
                reject(new Error(`Product Image is required`));
                return false;
            }
            // if (dataHere?.url === '') {
            //   reject(new Error(`Product Image is required`));
            //   return false;
            // }
            // sendData.url = dataHere?.url?.trim();

            // if (dataHere?.hashtags?.value === '') {
            //   reject(new Error(`Product Hashtag is required`));
            //   return false;
            // }
            sendData.hashtags = dataHere?.hashtags?.split;

            if (dataHere?.availableCountry?.value?.length === 0 && !dataHere?.isGlobal) {
                reject(new Error(`Select Atleast 1 Available In Country!`));
                return false;
            }
            sendData.availableCountry = dataHere?.availableCountry?.value || [];
            sendData.isGlobal = dataHere?.isGlobal;

            if (dataHere?.productCategory?.value?.length === 0) {
                reject(new Error(`Select Atleast 1 Available In Product Category!`));
                return false;
            }
            sendData.productCategory = dataHere?.productCategory?.value || [];

            if (dataHere?.subproductCategory?.value?.length === 0) {
                reject(new Error(`Select Atleast 1 Available In Subproduct Category!`));
                return false;
            }
            sendData.subproductCategory = dataHere?.subproductCategory?.value || [];

            // if (dataHere?.minQuantity === '') {
            //   reject(new Error(`Minimum Quantity is required`));
            //   return false;
            // }
            sendData.minQuantity = dataHere?.minQuantity;

            // if (dataHere?.unit === '') {
            //   reject(new Error(`Unit is required`));
            //   return false;
            // }
            sendData.unit = dataHere?.unit;

            // if (dataHere?.pricePerUnit === '') {
            //   reject(new Error(`Price Per Unit is required`));
            //   return false;
            // }
            sendData.pricePerUnit = dataHere?.pricePerUnit;

            // if (dataHere?.currency?.value === '') {
            //   reject(new Error(`Currency is required`));
            //   return false;
            // }
            sendData.currency = dataHere?.currency;

            if (dataHere?.featureDescription === '') {
                reject(new Error(`Feature Description is required`));
                return false;
            }
            sendData.featureDescription = dataHere?.featureDescription?.trim();

            resolve(sendData);
            return true;
        });
    }

    const onSubmitPage1 = async () => {
        try {
            setIsLoading(true);
            const toastID = toast.loading('Please wait...');

            const sendData = await validateFormProduct();

            if (dataHere.thumbnail?.value?.indexOf(S3BucketBaseUrl) === -1) {
                const { thumbnail } = await uploadImageToS3({
                    sendData: {
                        thumbnail: dataHere?.thumbnail?.name?.split('/')?.pop() || '',
                        thumbnailType: true,
                    },
                    value: dataHere.thumbnail?.value,
                });
                sendData.thumbnail = thumbnail;
            }

            if (mode === 'add') {
                const res = await apiMiddleWare({
                    type: 'post',
                    url: `/company/product/add`,
                    data: sendData,
                });
                if (!res?.data?.ok) {
                    customToast({
                        updateId: toastID,
                        type: 'update',
                        msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                        updateType: 'error',
                    });
                    setIsLoading(false);
                    return false;
                }
                setFormChanged(true);
                setDataHere((prev) => ({
                    ...prev,
                    id: res?.data?.packet?._id,
                }));

                customToast({ updateId: toastID, type: 'update', msg: 'Product Added Successfully', updateType: 'success' });
            } else if (mode === 'edit') {
                // const toastID = toast.loading('Please wait...');

                const res = await apiMiddleWare({
                    type: 'post',
                    url: `/company/product/edit`,
                    data: { ...sendData, productID: parentData?.id || dataHere?.id },
                });
                if (!res?.data?.ok) {
                    customToast({
                        updateId: toastID,
                        type: 'update',
                        msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                        updateType: 'error',
                    });

                    setIsLoading(false);
                    return false;
                }
                setFormChanged(true);
                customToast({ updateId: toastID, type: 'update', msg: 'Product Updated Successfully', updateType: 'success' });
                onCloseHere(true);
            }
            setIsLoading(false);
            return true;
        } catch (err) {
            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
            setIsLoading(false);
            return false;
        }
    };

    function onOpenAddEditModal(type = 'add', subtype = 'gallery', data = {}) {
        if (type === 'add') {
            setAddEditData((prev) => ({
                ...prev,
                mode: type,
                subtype,
                data: {
                    ...prev.data,
                    type: subtype === 'gallery' ? 'image' : 'pdf',
                    name: '',
                    src: '',
                    file: {},
                },
                modalOpen: true,
            }));
        } else {
            setAddEditData((prev) => ({
                ...prev,
                mode: type,
                subtype,
                data: {
                    id: data._id,
                    name: data?.title,
                    type: data.type === 'image' || data.type === 'video' ? data?.type : data?.extension,
                    src: data.type === 'image' ? S3BucketBaseUrl + data.source : data.type === 'video' ? data.source : '',
                    imgName: data.type !== 'video' ? data.source : '',
                    videoProvider: data.type === 'video' ? data?.extension : '',
                    file: data.type !== 'image' && data.type !== 'video' ? data.source : '',
                },
                modalOpen: true,
            }));
        }
    }

    const deleteSwal = (id, type) => {
        Swal.fire({
            title: 'Are you sure you want to delete resource ?',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            customClass: { container: 'zIndex-10000 ' },
        }).then((result) => {
            if (result.isConfirmed) {
                deleteButton(id, type);
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info');
            }
        });
    };

    const deleteButton = async (idHere, type) => {
        try {
            setIsLoading(true);

            const toastID = toast.loading('Please wait...');

            const sendData = {
                productID: parentData?.id || dataHere?.id,
                resourceID: idHere,
                type,
            };
            const res = await apiMiddleWare({ url: `/company/product/delete/resource`, type: 'post', data: sendData });
            if (res?.data?.ok) {
                customToast({
                    updateId: toastID,
                    type: 'update',
                    msg: 'Product Resource Deleted Successfully',
                    updateType: 'success',
                });
                setCallApi((prev) => !prev);
            } else {
                customToast({
                    updateId: toastID,
                    type: 'update',
                    msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                    updateType: 'error',
                });
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
        }
    };

    function onCloseAddEditModal() {
        clearAddEditData();
    }

    function clearAddEditData() {
        setAddEditData({
            mode: 'add',
            subtype: 'gallery',
            data: {
                id: '',
                name: '',
                type: 'image',
                src: '',
                imgName: '',
                videoProvider: '',
                file: {},
            },
            modalOpen: false,
        });
    }

    function handleChangeAddEdit(e) {
        const { name, value } = e.target;
        if (name === 'src') {
            setAddEditData((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    [name]: value,
                },
            }));
        } else if (name === 'file') {
            const fileHere = e.target.files[0];
            if (fileHere) {
                setAddEditData((prev) => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        file: fileHere,
                    },
                }));
            }
        } else {
            let newSrc = addEditData.data.src;
            if (name === 'type') {
                newSrc = '';
            }
            setAddEditData((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    src: newSrc,
                    [name]: value,
                },
            }));
        }
    }

    const handleMultiSelect = (e) => {
        const { name, value } = e.target;
        console.log("name",name,"value",value)
        const changedValue = typeof value === 'string' ? value.split(',') : value;

        if (name === 'productCategory') {
            const subProIntVal = dataHere?.subproductCategory?.value;
            const newSubProVal = [...subProIntVal];

            const productCatList = dataHere?.productCategory?.list;
            const differenceProdList = productCatList.filter((i) => value?.indexOf(i) === -1);

            subProIntVal.forEach((i) => {
                differenceProdList.forEach((j) => {
                    if (productList?.[j]?.indexOf(i) !== -1) {
                        newSubProVal.splice(newSubProVal?.indexOf(i), 1);
                    }
                });
            });

            setDataHere((prev) => ({
                ...prev,
                subproductCategory: {
                    ...prev?.subproductCategory,
                    value: newSubProVal,
                },
                productCategory: {
                    ...prev?.productCategory,
                    value: changedValue,
                },
            }));
        } else if (name === 'subproductCategory') {
            setDataHere((prev) => ({
                ...prev,
                subproductCategory: {
                    ...prev?.subproductCategory,
                    value: changedValue,
                },
            }));
        } else {
            setDataHere((prev) => ({
                ...prev,
                [name]: {
                    ...prev?.[name],
                    value: changedValue,
                },
            }));
        }
    };

    async function onSubmitAddEditModal() {
        // clearAddEditData()
        try {
            setIsLoading(true);

            await validateFormResource();
            const id = toast.loading('Please wait...');

            const sendData = {
                title: addEditData?.data?.name.trim(),
                // type : ['video', 'image', 'doc']
                type: addEditData?.subtype === 'document' ? 'doc' : addEditData?.data?.type,
                // extension : ['doc', 'pdf', 'xls','xlsx', 'docx', 'jpg', 'jpeg', 'png', 'youtube', 'vimeo' ]
                extension: '',
                source: '',
            };

            if (sendData.type === 'doc') {
                sendData.extension = addEditData?.data?.type;
            }
            if (sendData.type === 'video') {
                sendData.extension = addEditData?.data?.videoProvider;
                sendData.source = addEditData?.data?.src;
            }
            if (sendData.type === 'image') {
                sendData.extension = getExtension(addEditData?.data?.src);
            }

            if (addEditData?.mode === 'add') {
                sendData.productID = parentData?.id || dataHere?.id;

                if (sendData.type === 'image') {
                    const { product } = await uploadImageToS3({
                        sendData: {
                            product: addEditData?.data?.imgName.split('/')?.pop() || '',
                            productType: getExtension(addEditData?.data?.src),
                        },
                        value: addEditData?.data?.src,
                    });
                    sendData.source = product;
                } else if (sendData.type === 'doc') {
                    const formData = new FormData();
                    formData.append('myFile', addEditData?.data?.file, addEditData?.data?.file.name);
                    const { product } = await uploadImageToS3({
                        sendData: {
                            product: addEditData?.data?.imgName?.split('/')?.pop() || '',
                            productType: addEditData?.data?.type,
                        },
                        body: formData,
                        headers: { 'Content-Type': 'multipart/form-data' },
                    });
                    sendData.source = product;
                }

                sendData.productID = parentData?.id || dataHere?.id;

                console.log('sendData', sendData);
                const res = await apiMiddleWare({ type: 'post', url: `/company/product/add/resource`, data: sendData });
                if (!res?.data?.ok) {
                    customToast({
                        updateId: id,
                        type: 'update',
                        msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                        updateType: 'error',
                    });
                    setIsLoading(false);

                    return;
                }

                customToast({
                    updateId: id,
                    type: 'update',
                    msg: 'Product Resource Added Successfully',
                    updateType: 'success',
                });
            } else if (addEditData?.mode === 'edit') {
                if (sendData.type === 'image') {
                    if (addEditData?.data?.src?.indexOf(S3BucketBaseUrl) === -1) {
                        const { product } = await uploadImageToS3({
                            sendData: {
                                product: addEditData?.data?.imgName?.split('/')?.pop() || '',
                                productType: getExtension(addEditData?.data?.src),
                            },
                            value: addEditData?.data?.src,
                        });
                        sendData.source = product;
                    }
                } else if (sendData.type === 'doc') {
                    if (typeof addEditData?.data?.file !== 'string') {
                        const formData = new FormData();
                        formData.append('myFile', addEditData?.data?.file, addEditData?.data?.file.name);
                        const { product } = await uploadImageToS3({
                            sendData: {
                                product: addEditData?.data?.imgName?.split('/')?.pop() || '',
                                productType: addEditData?.data?.type,
                            },
                            body: formData,
                            headers: { 'Content-Type': 'multipart/form-data' },
                        });
                        sendData.source = product;
                    }
                } else if (sendData.type === 'video') {
                    sendData.source = addEditData?.data?.src;
                }
                sendData.productID = parentData?.id || dataHere?.id;
                sendData.resourceID = addEditData?.data?.id;

                const res2 = await apiMiddleWare({ type: 'post', url: `/company/product/edit/resource`, data: sendData });
                if (!res2?.data?.ok) {
                    customToast({
                        updateId: id,
                        type: 'update',
                        msg: res2?.data?.errors[0]?.message || res2?.data?.errors[0]?.msg || defaultError,
                        updateType: 'error',
                    });
                    setIsLoading(false);

                    return;
                }

                customToast({
                    updateId: id,
                    type: 'update',
                    msg: 'Product Resource Updated Successfully',
                    updateType: 'success',
                });
            }

            setIsLoading(false);

            setTimeout(() => {
                setCallApi((prev) => !prev);
            }, [1500]);
            onCloseAddEditModal();
            // return true;
        } catch (err) {
            setIsLoading(false);

            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
            // return false;
        }
    }

    async function validateFormResource() {
        return new Promise((resolve, reject) => {
            if (addEditData?.data?.name === '') {
                reject(new Error(`Name is required`));
                return false;
            }
            if (addEditData?.data?.type === '') {
                reject(new Error(`Type is required`));
                return false;
            }
            if (addEditData?.subtype === 'gallery') {
                if (addEditData?.data?.type === 'image' && addEditData?.data?.src === '') {
                    reject(new Error(`Image is required`));
                    return false;
                }
                if (addEditData?.data?.type === 'video' && addEditData?.data?.src === '') {
                    reject(new Error(`Video Link is required`));
                    return false;
                }
            } else if (addEditData?.subtype === 'document') {
                if (!addEditData?.data?.file === '') {
                    reject(new Error(`File is required`));
                    return false;
                }
            }
            resolve();
            return true;
        });
    }

    function getImageHere(data) {
        if (!!data) {
            setAddEditData((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    src: data,
                },
            }));
        }
    }

    function videoOnBlur() {
        const currentLink = addEditData?.data?.src;
        if (currentLink) {
            const allData = parseVideo(currentLink);
            if (allData?.provider !== 'youtube' && allData?.provider !== 'vimeo') {
                customToast({ type: 'error', msg: `URL is not valid` });
                setAddEditData((prev) => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        src: '',
                    },
                }));
            } else {
                setAddEditData((prev) => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        videoProvider: allData?.provider,
                        src: allData?.rawLink,
                    },
                }));
            }
        }
    }

    function handleThumbnail(data) {
        if (!!data) {
            setDataHere((prev) => ({
                ...prev,
                thumbnail: {
                    ...prev.thumbnail,
                    value: data,
                },
            }));
        }
    }

    function clearThumb() {
        setDataHere((prev) => ({
            ...prev,
            thumbnail: {
                ...prev.thumbnail,
                value: '',
            },
        }));
    }

    async function onNextPage() {
        if (mode === 'add' && (await onSubmitPage1())) {
            setDataHere((prev) => ({
                ...prev,
                page: 2,
            }));
        } else if (mode === 'edit') {
            onSubmitPage1();
        }
    }

    function getThumbnailLink(link) {
        const data = parseVideo(link);
        return data?.thumb;
    }

    const subProductList = Object.keys(productList)?.map((name) => {
        if (dataHere?.productCategory?.value?.indexOf(name) === -1) return null;
        return productList?.[name]?.map((j) => (
            <MenuItem
                key={name + j}
                value={j}
                data-key={name}
                // style={getStyles(name, personName, theme)}
            >
                <Checkbox checked={dataHere?.subproductCategory?.value.indexOf(j) > -1} />
                <ListItemText primary={j} />
            </MenuItem>
        ));
    });

    const handleOpenMenu = (type) => {
        setMenuOption((prev) => ({
            ...prev,
            [type]: true,
        }));
    };

    const handleCloseMenu = (type) => {
        setMenuOption((prev) => ({
            ...prev,
            [type]: false,
        }));
    };

    const CustomMenuClose = useCallback(
        ({ type }) => (
            <ListSubheader className="d-flex justify-content-end" sx={{ position: 'sticky', bottom: 0, width: '100%' }}>
                <Button
                    variant="contained"
                    className="ms-auto"
                    sx={{ marginBottom: '12px', marginTop: '12px' }}
                    type="button"
                    onClick={() => handleCloseMenu(type)}
                >
                    OK
                </Button>
            </ListSubheader>
        ),
        []
    );

    return (
        <>
            <CommonModal
                open={addEditData?.modalOpen}
                onClose={onCloseAddEditModal}
                title={`${addEditData?.mode === 'add' ? 'Add' : 'Edit'} ${
                    addEditData.subtype === 'gallery' ? 'Product Gallery and Video' : 'Product Catalogue'
                }`}
                onSubmit={onSubmitAddEditModal}
                body={
                    <>
                        <div className="p-3">
                            <Grid container spacing={3}>
                                <Grid item xs={12} className="side-by-side">
                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Content Name</InputLabel>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            sx={{ width: '70%' }}
                                            className="fullWidth-responsive"
                                            // fullWidth
                                            name="name"
                                            value={addEditData?.data?.name}
                                            onChange={handleChangeAddEdit}
                                            // inputProps={{ maxLength: 30 }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className="side-by-side">
                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Content Type</InputLabel>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            // fullWidth
                                            sx={{ width: '50%', textAlign: 'left' }}
                                            className="fullWidth-responsive"
                                            name="type"
                                            id="outlined-select-currency"
                                            select
                                            disabled={addEditData?.mode === 'edit'}
                                            // label="Search Time"
                                            value={addEditData?.data?.type}
                                            onChange={handleChangeAddEdit}
                                            // required
                                        >
                                            {addEditData?.subtype === 'gallery'
                                                ? ['image', 'video'].map((i) => (
                                                      <MenuItem value={i} key={i}>
                                                          {i?.charAt(0)?.toUpperCase() + i.substring(1)}
                                                      </MenuItem>
                                                  ))
                                                : ['pdf', 'xls', 'xlsx', 'doc', 'docx'].map((i) => (
                                                      <MenuItem value={i} key={i}>
                                                          {i?.toUpperCase()}
                                                      </MenuItem>
                                                  ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                                {addEditData?.data?.type === 'image' && (
                                    <Grid item xs={12} className="side-by-side">
                                        <Grid item xs={12} md={4}>
                                            <InputLabel>Upload Image</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} md={8} style={{ alignItems: 'center' }}>
                                            {!!addEditData?.data?.src && (
                                                <img
                                                    src={addEditData?.data?.src}
                                                    alt="gallery content"
                                                    height={120}
                                                    style={{ marginRight: '2rem' }}
                                                />
                                            )}
                                            <ImageUpload
                                                title="Upload Image"
                                                previousImgSrc={addEditData?.data?.src || ''}
                                                onClose={(data) => getImageHere(data)}
                                                propSize={2}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                {addEditData?.data?.type === 'video' && (
                                    <Grid item xs={12} className="side-by-side">
                                        <Grid item xs={12} md={4} sx={{ flexDirection: 'column' }}>
                                            <InputLabel>Video Link</InputLabel>
                                            <span className="note-text">Only Youtube/Vimeo Link</span>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <TextField
                                                // sx={{ width: '10%' }}
                                                fullWidth
                                                name="src"
                                                value={addEditData?.data?.src}
                                                onChange={handleChangeAddEdit}
                                                onBlur={videoOnBlur}
                                                // inputProps={{ maxLength: 30 }}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                {addEditData?.data?.type !== 'image' && addEditData?.data?.type !== 'video' && (
                                    <Grid item xs={12} className="side-by-side">
                                        <Grid item xs={12} md={4}>
                                            <InputLabel>Upload File</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            {addEditData?.data?.type === 'pdf' && (
                                                <Input
                                                    type="file"
                                                    name="file"
                                                    onChange={handleChangeAddEdit}
                                                    inputProps={{ accept: 'application/pdf' }}
                                                />
                                            )}
                                            {addEditData?.data?.type === 'xls' && (
                                                <Input
                                                    type="file"
                                                    name="file"
                                                    onChange={handleChangeAddEdit}
                                                    inputProps={{
                                                        accept: 'application/vnd.ms-excel',
                                                    }}
                                                />
                                            )}
                                            {addEditData?.data?.type === 'xlsx' && (
                                                <Input
                                                    type="file"
                                                    name="file"
                                                    onChange={handleChangeAddEdit}
                                                    inputProps={{
                                                        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                                    }}
                                                />
                                            )}

                                            {addEditData?.data?.type === 'doc' && (
                                                <Input
                                                    type="file"
                                                    name="file"
                                                    onChange={handleChangeAddEdit}
                                                    inputProps={{ accept: 'application/msword' }}
                                                />
                                            )}
                                            {addEditData?.data?.type === 'docx' && (
                                                <Input
                                                    type="file"
                                                    name="file"
                                                    onChange={handleChangeAddEdit}
                                                    inputProps={{
                                                        accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                    }}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                            {/* <ImageUpload /> */}
                        </div>
                    </>
                }
            />
            <CommonModal
                customStyle={'medium-modal extra-height'}
                open={open}
                title={mode === 'add' ? 'Add Product' : 'Edit Product'}
                onClose={onCloseHere}
                noTitleLine
                // onSubmit={onSubmitPage1}
                customFooter={
                    <>
                        <Button type="button" variant="outlined" color="error" onClick={onCloseHere} disabled={isLoading}>
                            Cancel
                        </Button>
                        {dataHere?.page === 1 ? (
                            <Button  sx={{ ml: 2,background:confirmGreen,":hover":{background:confirmGreen} }} type="button" variant="contained" color="primary" disabled={isLoading} onClick={onNextPage}>
                                {mode === 'edit' ? 'Submit' : 'Save & Next'}
                            </Button>
                        ) : (
                            dataHere?.page === 2 && (
                                <Button  sx={{ ml: 2,background:confirmGreen,":hover":{background:confirmGreen} }} type="button" variant="contained" color="primary" disabled={isLoading} onClick={onCloseHere}>
                                    Submit
                                </Button>
                            )
                        )}
                    </>
                }
                body={
                    <>
                        <Grid container spacing={3} className="px-2 px-md-3 p-3">
                            {dataHere?.page === 1 ? (
                                <>
                                    <Grid item xs={12} className="side-by-side ">
                                        {/* <Grid item xs={12} md={3}>
                                            <CustomInputLabel label="Product Name" labelProps={{ required: true }} helperText="What you're selling" />
                                        </Grid> */}
                                        <Grid item xs={12} md={9}>
                                            <TextField
                                                className="fullWidth-responsive"
                                                // fullWidth
                                                name="name"
                                                label="Product name*"
                                                placeholder="Enter the product name"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                sx={{
                                                    width: '60%',
                                                    '& .MuiInputLabel-asterisk': {
                                                        display: 'none', // inherit the color from the parent
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: 'black', // Default black outline
                                                        },
                                                    },
                                                }}
                                                value={dataHere?.name}
                                                onChange={handleOnChange}
                                                inputProps={{ maxLength: 100 }}
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className="side-by-side">
                                        {/* <Grid item xs={12} md={3}>
                                            <CustomInputLabel
                                                label="Product Summary"
                                                labelProps={{ required: true }}
                                                helperText="Brief product description"
                                            />
                                        </Grid> */}
                                        <Grid
                                            style={{ border: '1px solid black', padding: '0.5rem', borderRadius: '6px', position: 'relative' }}
                                            item
                                            xs={12}
                                            md={9}
                                        >
                                            <div className="ck-editor-label">Product summary*</div>

                                            <CKEditor
                                                editor={ClassicEditor}
                                                style={{ width: '100%' }}
                                                // TO SEE ALL THE CONFIGS - ClassicEditor.builtinPlugins.map((plugin) => plugin.pluginName)
                                                config={ckEditorConfig}
                                                data={dataHere?.description}
                                                // onReady={(editor) => {
                                                //   console.log('Editor is ready to use!', editor);
                                                // }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    // console.log({ event, editor, data });
                                                    setDataHere((prev) => ({
                                                        ...prev,
                                                        description: data,
                                                    }));
                                                }}
                                                // onBlur={(event, editor) => {
                                                //   console.log('Blur.', editor);
                                                // }}
                                                // onFocus={(event, editor) => {
                                                //   console.log('Focus.', editor);
                                                // }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className="">
                                        {/* <Grid item xs={12} md={3}>
                                            <CustomInputLabel label="Product Image" labelProps={{ required: true }} helperText="Main product image" />
                                        </Grid> */}
                                        <div className="custom-heading">Image*</div>
                                        <Grid item xs={12} md={12} className="side-by-side flex-md-row gap-md-3 align-items-md-start">
                                            <ImageUploadNew
                                                customClass="my-auto mt-2 mt-md-0"
                                                title={'Upload Product Thumbnail'}
                                                // propAspect={1 / 1}
                                                propSize={5}
                                                onClose={handleThumbnail}
                                                previousImgSrc={dataHere?.thumbnail?.value}
                                                imageHeight="13rem"
                                                imageWidth="18rem"
                                            />
                                            <div className=''> 
                                            {!!dataHere?.thumbnail?.value && (
                                                <img
                                                    src={dataHere?.thumbnail?.value}
                                                    alt="Thumbnail"
                                                    height={150}
                                                    // onError={handleImgErrorBanner}
                                                    style={{ marginRight: '2rem' }}
                                                />
                                            )}
                                            {!!dataHere?.thumbnail?.value && (
                                                <Button type="button" variant="contained" color="error" className="ms-0 ms-md-3" onClick={clearThumb}>
                                                    Clear Image
                                                </Button>
                                            )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    {mode === 'edit' && (
                                        <>
                                            <Grid item xs={12}>
                                                <Accordion sx={{ bgcolor: '#F4F6F8', borderRadius: '10px' }}>
                                                    <AccordionSummary expandIcon={<Iconify icon="akar-icons:chevron-down" />}>
                                                        <Typography variant="h4" sx={{ m: 1 }}>
                                                            Product Gallery and Video
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Box>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sx={{ justifyContent: 'end', display: 'flex' }}>
                                                                    <Button
                                                                        type="Button"
                                                                        variant="contained"
                                                                        disabled={isLoading}
                                                                        className="fs-6 "
                                                                        onClick={() => onOpenAddEditModal('add', 'gallery')}
                                                                        sx={{ fontSize: iconFontSize }}
                                                                    >
                                                                        Add Image & Video
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TableContainer component={Paper}>
                                                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell align="left">Index</TableCell>
                                                                                    <TableCell align="center">Preview</TableCell>
                                                                                    <TableCell align="center">Name</TableCell>
                                                                                    <TableCell align="right">Type</TableCell>
                                                                                    <TableCell align="right">Actions</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {dataHere?.galleryItems?.length > 0 &&
                                                                                    dataHere?.galleryItems?.map((row, index) => (
                                                                                        <TableRow
                                                                                            key={row._id}
                                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                        >
                                                                                            <TableCell align="left">{index + 1}</TableCell>
                                                                                            <TableCell align="center">
                                                                                                <div className="table-img">
                                                                                                    {row?.type === 'image' ? (
                                                                                                        <img
                                                                                                            src={`${S3BucketBaseUrl}${row?.source}`}
                                                                                                            alt=""
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <img
                                                                                                            src={getThumbnailLink(row?.source)}
                                                                                                            alt=""
                                                                                                        />
                                                                                                    )}
                                                                                                </div>
                                                                                            </TableCell>
                                                                                            <TableCell align="center">{row.title}</TableCell>
                                                                                            <TableCell align="right">
                                                                                                {row?.type?.charAt(0)?.toUpperCase() +
                                                                                                    row?.type?.substring(1)}
                                                                                            </TableCell>
                                                                                            <TableCell align="right" sx={{ minWidth: '265px' }}>
                                                                                                <Button
                                                                                                    type="Button"
                                                                                                    variant="outlined"
                                                                                                    color="success"
                                                                                                    disabled={isLoading}
                                                                                                    onClick={() =>
                                                                                                        onOpenAddEditModal('edit', 'gallery', row)
                                                                                                    }
                                                                                                    sx={{ fontSize: '1.4rem', mr: 2 }}
                                                                                                >
                                                                                                    <Tooltip title="Edit">
                                                                                                        <Iconify icon="clarity:edit-line" />
                                                                                                    </Tooltip>
                                                                                                </Button>
                                                                                                <Button
                                                                                                    type="Button"
                                                                                                    variant="outlined"
                                                                                                    color="primary"
                                                                                                    disabled={isLoading}
                                                                                                    sx={{ fontSize: '1.4rem', mr: 2 }}
                                                                                                    onClick={openInNewTab(
                                                                                                        row.type === 'image'
                                                                                                            ? `${S3BucketBaseUrl}${row.source}`
                                                                                                            : row.source
                                                                                                    )}
                                                                                                >
                                                                                                    <Tooltip title="View">
                                                                                                        <Iconify icon="clarity:eye-line" />
                                                                                                    </Tooltip>
                                                                                                </Button>
                                                                                                <Button
                                                                                                    type="Button"
                                                                                                    variant="outlined"
                                                                                                    color="error"
                                                                                                    sx={{ fontSize: '1.4rem' }}
                                                                                                    disabled={isLoading}
                                                                                                    onClick={() => deleteSwal(row?._id, row?.type)}
                                                                                                >
                                                                                                    <Tooltip title="Delete">
                                                                                                        <Iconify icon="gg:trash-empty" />
                                                                                                    </Tooltip>
                                                                                                </Button>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    ))}
                                                                                {dataHere?.galleryItems?.length === 0 && (
                                                                                    <TableRow>
                                                                                        <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                                                                                            No Gallery Item Found
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                )}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Accordion sx={{ bgcolor: '#F4F6F8', borderRadius: '10px' }}>
                                                    <AccordionSummary expandIcon={<Iconify icon="akar-icons:chevron-down" />}>
                                                        <Typography variant="h4" sx={{ m: 1 }}>
                                                            Product Catalogue
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Box>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sx={{ justifyContent: 'end', display: 'flex' }}>
                                                                    <Button
                                                                        type="Button"
                                                                        variant="contained"
                                                                        disabled={isLoading}
                                                                        className="fs-6 "
                                                                        onClick={() => onOpenAddEditModal('add', 'document')}
                                                                        sx={{ fontSize: iconFontSize }}
                                                                    >
                                                                        Add Catalouge
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TableContainer component={Paper}>
                                                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell align="left">Index</TableCell>
                                                                                    <TableCell align="center">Name</TableCell>
                                                                                    <TableCell align="right">Type</TableCell>
                                                                                    <TableCell align="right">Actions</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {dataHere?.catalogItems?.length > 0 &&
                                                                                    dataHere?.catalogItems?.map((row, index) => (
                                                                                        <TableRow
                                                                                            key={row._id}
                                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                        >
                                                                                            <TableCell align="left">{index + 1}</TableCell>
                                                                                            <TableCell align="center">{row?.title}</TableCell>
                                                                                            <TableCell align="right">
                                                                                                {row?.extension?.toUpperCase()}
                                                                                            </TableCell>
                                                                                            <TableCell align="right" sx={{ minWidth: '265px' }}>
                                                                                                <Button
                                                                                                    type="Button"
                                                                                                    variant="outlined"
                                                                                                    color="success"
                                                                                                    disabled={isLoading}
                                                                                                    onClick={() =>
                                                                                                        onOpenAddEditModal('edit', 'document', row)
                                                                                                    }
                                                                                                    sx={{ fontSize: '1.4rem', mr: 2 }}
                                                                                                >
                                                                                                    <Tooltip title="Edit">
                                                                                                        <Iconify icon="clarity:edit-line" />
                                                                                                    </Tooltip>
                                                                                                </Button>
                                                                                                <Button
                                                                                                    type="Button"
                                                                                                    variant="outlined"
                                                                                                    color="primary"
                                                                                                    sx={{ fontSize: '1.4rem', mr: 2 }}
                                                                                                    disabled={isLoading}
                                                                                                    onClick={openInNewTab(
                                                                                                        `${S3BucketBaseUrl}${row.source}`
                                                                                                    )}
                                                                                                >
                                                                                                    <Tooltip title="View">
                                                                                                        <Iconify icon="clarity:eye-line" />
                                                                                                    </Tooltip>
                                                                                                </Button>
                                                                                                <Button
                                                                                                    type="Button"
                                                                                                    variant="outlined"
                                                                                                    color="error"
                                                                                                    disabled={isLoading}
                                                                                                    sx={{ fontSize: '1.4rem' }}
                                                                                                    onClick={() => deleteSwal(row?._id, row?.type)}
                                                                                                >
                                                                                                    <Tooltip title="Delete">
                                                                                                        <Iconify icon="gg:trash-empty" />
                                                                                                    </Tooltip>
                                                                                                </Button>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    ))}
                                                                                {dataHere?.catalogItems?.length === 0 && (
                                                                                    <TableRow>
                                                                                        <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                                                                                            No Product Catalogue Item Found
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                )}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        </>
                                    )}

                                    {/* <Grid item xs={12} className="side-by-side ">
                    <Grid item xs={12} md={3}>
                      <InputLabel>Product URL</InputLabel>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <TextField
                        sx={{ width: '70%' }}
                        className="fullWidth-responsive"
                        // fullWidth
                        name="url"
                        value={dataHere?.url}
                        onChange={handleOnChange}
                        // inputProps={{ maxLength: 30 }}
                        required
                      />
                    </Grid>
                  </Grid> */}
                                    {/* <Grid item xs={12} className="side-by-side ">
                    <Grid item xs={12} md={3} sx={{ flexDirection: 'column' }}>
                      <InputLabel>Keywords</InputLabel>
                      <span className="note-text">Include comma(,) between each word</span>
                    </Grid>
                    <Grid item xs={12} md={9} sx={{ flexDirection: 'column' }}>
                      <TextField
                        // sx={{ width: '70%' }}
                        className="fullWidth-responsive"
                        fullWidth
                        name="hashtags"
                        value={dataHere?.hashtags?.value}
                        onChange={handleOnChange}
                        // inputProps={{ maxLength: 30 }}
                        required
                      />
                      {dataHere?.hashtags?.split?.length > 0 && (
                        <div className="d-flex flex-wrap" style={{ marginTop: '10px' }}>
                          {dataHere?.hashtags?.split?.map((i, index) => (
                            <span key={`${i}-${index}`} className="hashtag-box">
                              {i}
                            </span>
                          ))}
                        </div>
                      )}
                    </Grid>
                  </Grid> */}
                                    <Grid item xs={12} className="side-by-side">
                                        <Grid item xs={12} md={3}>
                                            <CustomInputLabel
                                                label="Is Globally Available"
                                                labelProps={{ required: true }}
                                                helperText="Where you sell it"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={9}>
                                            <FormControl>
                                                <Checkbox checked={dataHere?.isGlobal} onChange={handleOnChange} name="isGlobal" />
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    {!dataHere?.isGlobal && (
                                        <Grid style={{ position: 'relative' }} item xs={12} className="side-by-side">
                                            {/* <Grid item xs={12} md={3}>
                                                <CustomInputLabel
                                                    label="Available In Country"
                                                    labelProps={{ required: true }}
                                                    helperText="Where you sell it"
                                                />
                                            </Grid> */}
                                            <div className="ck-editor-label" style={{ top: '0.7rem', left: '1.9rem' }}>
                                                Available in country*
                                            </div>
                                            <Grid item xs={12} md={9}>
                                                {dataHere?.availableCountry?.list?.length > 0 && (
                                                    <FormControl fullWidth>
                                                        {/* <InputLabel id="demo-multiple-checkbox-label">Products Interested*</InputLabel> */}
                                                        <MultiSelect
                                                            name='availableCountry'
                                                            sx={{ width: '60%' }}
                                                            open={menuOption?.country}
                                                            onOpen={() => handleOpenMenu('country')}
                                                            onClose={() => handleCloseMenu('country')}
                                                            labelId="demo-multiple-checkbox-label"
                                                            id="demo-multiple-checkbox"
                                                            // fullWidth
                                                            multiple
                                                            className="text-start position-relative"
                                                            value={dataHere?.availableCountry?.value}
                                                            onChange={handleMultiSelect}
                                                            // input={<OutlinedInput label="Products Interested*" />}
                                                            renderValue={(selected) => selected.join(', ')}
                                                            // MenuProps={MenuProps}
                                                            // required
                                                        >
                                                            {dataHere?.availableCountry?.list?.length &&
                                                                dataHere?.availableCountry?.list?.map((item) => (
                                                                    <MenuItem
                                                                        key={item?.id}
                                                                        value={item?.name}
                                                                        // style={getStyles(name, personName, theme)}
                                                                    >
                                                                        <Checkbox
                                                                            checked={dataHere?.availableCountry?.value.indexOf(item?.name) > -1}
                                                                        />
                                                                        <ListItemText primary={item?.name} />
                                                                    </MenuItem>
                                                                ))}
                                                            <CustomMenuClose type="country" />
                                                        </MultiSelect>
                                                    </FormControl>
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid style={{ position: 'relative' }} item xs={12} className="side-by-side ">
                                        {/* <Grid item xs={12} md={3}>
                                            <CustomInputLabel
                                                label="Product Category"
                                                labelProps={{ required: true }}
                                                helperText="Main product type(e.g., Solar Energy, Bio Energy)"
                                            />
                                        </Grid> */}
                                        <div className="ck-editor-label" style={{ top: '0.7rem', left: '1.9rem' }}>
                                            Product category*
                                        </div>

                                        <Grid item xs={12} md={9}>
                                            <FormControl fullWidth>
                                                <MultiSelect
                                                    sx={{ width: '60%' }}
                                                    open={menuOption?.prod}
                                                    onOpen={() => handleOpenMenu('prod')}
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="demo-multiple-checkbox"
                                                    className="text-start"
                                                    fullWidth
                                                    multiple
                                                    name="productCategory"
                                                    value={dataHere?.productCategory?.value}
                                                    onChange={handleMultiSelect}
                                                    // input={<OutlinedInput label="Products Interested*" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    // MenuProps={MenuProps}
                                                    // required
                                                >
                                                    {dataHere?.productCategory?.list?.map((name) => (
                                                        <MenuItem
                                                            key={name}
                                                            value={name}
                                                            // style={getStyles(name, personName, theme)}
                                                        >
                                                            <Checkbox checked={dataHere?.productCategory?.value.indexOf(name) > -1} />
                                                            <ListItemText primary={name} />
                                                        </MenuItem>
                                                    ))}
                                                    <CustomMenuClose type="prod" />
                                                </MultiSelect>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid style={{ position: 'relative' }} item xs={12} className="side-by-side ">
                                        {/* <Grid item xs={12} md={3}>
                                            <CustomInputLabel
                                                label="Sub-product Category"
                                                labelProps={{ required: true }}
                                                helperText="More specific type(e.g., Solar Pump Manufacturers)"
                                            />
                                        </Grid> */}
                                        <div className="ck-editor-label" style={{ top: '0.7rem', left: '1.9rem' }}>
                                            Sub-product category*
                                        </div>

                                        <Grid item xs={12} md={9}>
                                            <FormControl fullWidth>
                                                <MultiSelect
                                                    sx={{ width: '60%' }}
                                                    open={menuOption?.subProd}
                                                    onOpen={() => handleOpenMenu('subProd')}
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="demo-multiple-checkbox"
                                                    fullWidth
                                                    multiple
                                                    name="subproductCategory"
                                                    className="text-start"
                                                    value={dataHere?.subproductCategory?.value}
                                                    onChange={handleMultiSelect}
                                                    // input={<OutlinedInput label="Products Interested*" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    // MenuProps={MenuProps}
                                                    // required
                                                >
                                                    {subProductList}
                                                    <CustomMenuClose type="subProd" />
                                                </MultiSelect>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} className="side-by-side ">
                                        {/* <Grid item xs={12} md={3}>
                                            <CustomInputLabel label="Minimum Quantity" helperText="Lowest order amount" />
                                        </Grid> */}
                                        <Grid item xs={12} md={9}>
                                            <TextField
                                                className="fullWidth-responsive"
                                                label="Minimum quantity*"
                                                placeholder="Enter the minimum quantity"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                sx={{
                                                    width: '60%',
                                                    '& .MuiInputLabel-asterisk': {
                                                        display: 'none', // inherit the color from the parent
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: 'black', // Default black outline
                                                        },
                                                    },
                                                }}
                                                // fullWidth
                                                name="minQuantity"
                                                type={'number'}
                                                value={dataHere?.minQuantity}
                                                onChange={handleOnChange}
                                                inputProps={{ min: 0 }}
                                                // required
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} className="side-by-side ">
                                        {/* <Grid item xs={12} md={3}>
                                            <CustomInputLabel label="Unit" helperText="How you sell it(e.g., Pcs, Kg)" />
                                        </Grid> */}
                                        <Grid item xs={12} md={9}>
                                            <TextField
                                                // fullWidth
                                                className="fullWidth-responsive"
                                                name="unit"
                                                id="outlined-select-currency"
                                                label="Unit*"
                                                placeholder="How you sell it(e.g., Pcs, Kg)"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                sx={{
                                                    textAlign: 'left',
                                                    width: '60%',
                                                    '& .MuiInputLabel-asterisk': {
                                                        display: 'none', // inherit the color from the parent
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: 'black', // Default black outline
                                                        },
                                                    },
                                                }}
                                                select
                                                value={dataHere?.unit}
                                                onChange={handleOnChange}
                                                // required
                                            >
                                                <MenuItem value={''}>-- How you sell it(e.g., Pcs, Kg) --</MenuItem>
                                                {unitOption.map((i) => (
                                                    <MenuItem value={i} key={i}>
                                                        {i?.toUpperCase()}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>

                                    <Grid style={{ position: 'relative' }} item xs={12} className="side-by-side ">
                                        {/* <Grid item xs={12} md={3}>
                                            <CustomInputLabel label="Currency" helperText="What currency you use" />
                                        </Grid> */}
                                        <div className="ck-editor-label" style={{ top: '0.7rem', left: '1.9rem', zIndex: 16 }}>
                                            Currency*
                                        </div>

                                        <Grid style={{ zIndex: 15 }} item xs={12} md={9}>
                                            {currecyList?.length > 0 && (
                                                <Select
                                                    className="custom-select"
                                                    isSearchable
                                                    styles={{
                                                        //  ':active ': {
                                                        //    ...styles[':active'],
                                                        //    color:'black',
                                                        //  },
                                                        control: (baseStyle, state) => ({
                                                            ...baseStyle,
                                                            padding: '4px 3px',
                                                            width: '120%',
                                                        }),
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#f6f7f8', // hover
                                                            // primary: '#eef3fb', // selected
                                                            // primary25: 'red', // hover
                                                            // primary: 'white',
                                                            // neutral5: 'black',
                                                        },
                                                    })}
                                                    options={currecyList}
                                                    defaultValue={dataHere?.currency}
                                                    value={dataHere?.currency}
                                                    onChange={selectChange}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} className="side-by-side ">
                                        {/* <Grid item xs={12} md={3}>
                                            <CustomInputLabel label="Price Per Unit" helperText="Cost of each unit" />
                                        </Grid> */}
                                        <Grid item xs={12} md={9}>
                                            <TextField
                                                label="Price per unit*"
                                                placeholder="Cost of each unit"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                sx={{
                                                    width: '60%',
                                                    '& .MuiInputLabel-asterisk': {
                                                        display: 'none', // inherit the color from the parent
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: 'black', // Default black outline
                                                        },
                                                    },
                                                }}
                                                className="fullWidth-responsive"
                                                // fullWidth
                                                name="pricePerUnit"
                                                type={'number'}
                                                value={dataHere?.pricePerUnit}
                                                onChange={handleOnChange}
                                                inputProps={{ min: 0 }}
                                                // required
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} className="">
                                        {/* <Grid item xs={12} md={3}>
                                            <CustomInputLabel
                                                label="Feature Description"
                                                labelProps={{ required: true }}
                                                helperText="Highlight key features"
                                            />
                                        </Grid> */}

                                        <Grid
                                            style={{ border: '1px solid black', padding: '0.5rem', borderRadius: '6px', position: 'relative' }}
                                            item
                                            xs={12}
                                            md={9}
                                        >
                                            <div className="ck-editor-label">Feature description*</div>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                style={{ width: '100%' }}
                                                // TO SEE ALL THE CONFIGS - ClassicEditor.builtinPlugins.map((plugin) => plugin.pluginName)
                                                config={ckEditorConfig}
                                                data={dataHere?.featureDescription}
                                                // onReady={(editor) => {
                                                //   console.log('Editor is ready to use!', editor);
                                                // }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    // console.log({ event, editor, data });
                                                    setDataHere((prev) => ({
                                                        ...prev,
                                                        featureDescription: data,
                                                    }));
                                                }}
                                                // onBlur={(event, editor) => {
                                                //   console.log('Blur.', editor);
                                                // }}
                                                // onFocus={(event, editor) => {
                                                //   console.log('Focus.', editor);
                                                // }}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                dataHere?.page === 2 &&
                                mode === 'add' && (
                                    <>
                                        <Grid item xs={12}>
                                            <Accordion sx={{ bgcolor: '#F4F6F8', borderRadius: '10px' }}>
                                                <AccordionSummary expandIcon={<Iconify icon="akar-icons:chevron-down" />}>
                                                    <Typography variant="h4" sx={{ m: 1 }}>
                                                        Product Gallery and Video
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Box>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} sx={{ justifyContent: 'end', display: 'flex' }}>
                                                                <Button
                                                                    type="Button"
                                                                    variant="contained"
                                                                    disabled={isLoading}
                                                                    className="fs-6 "
                                                                    onClick={() => onOpenAddEditModal('add', 'gallery')}
                                                                    sx={{ fontSize: iconFontSize }}
                                                                >
                                                                    Add Image & Video
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TableContainer component={Paper}>
                                                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell align="left">Index</TableCell>
                                                                                <TableCell align="center">Name</TableCell>
                                                                                <TableCell align="center">Preview</TableCell>
                                                                                <TableCell align="right">Type</TableCell>
                                                                                <TableCell align="right">Actions</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {dataHere?.galleryItems?.length > 0 &&
                                                                                dataHere?.galleryItems?.map((row, index) => (
                                                                                    <TableRow
                                                                                        key={row._id}
                                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                    >
                                                                                        <TableCell align="left">{index + 1}</TableCell>
                                                                                        <TableCell align="center">
                                                                                            <div className="table-img">
                                                                                                {row?.type === 'image' ? (
                                                                                                    <img
                                                                                                        src={`${S3BucketBaseUrl}${row?.source}`}
                                                                                                        alt=""
                                                                                                    />
                                                                                                ) : (
                                                                                                    <img src={getThumbnailLink(row?.source)} alt="" />
                                                                                                )}
                                                                                            </div>
                                                                                        </TableCell>
                                                                                        <TableCell align="center">{row?.title}</TableCell>
                                                                                        <TableCell align="right">
                                                                                            {row?.type?.charAt(0)?.toUpperCase() +
                                                                                                row?.type?.substring(1)}
                                                                                        </TableCell>
                                                                                        <TableCell align="right" sx={{ minWidth: '265px' }}>
                                                                                            <Button
                                                                                                type="Button"
                                                                                                variant="outlined"
                                                                                                color="success"
                                                                                                disabled={isLoading}
                                                                                                onClick={() =>
                                                                                                    onOpenAddEditModal('edit', 'gallery', row)
                                                                                                }
                                                                                                sx={{ fontSize: '1.4rem', mr: 2 }}
                                                                                            >
                                                                                                <Tooltip title="Edit">
                                                                                                    <Iconify icon="clarity:edit-line" />
                                                                                                </Tooltip>
                                                                                            </Button>
                                                                                            <Button
                                                                                                type="Button"
                                                                                                variant="outlined"
                                                                                                color="primary"
                                                                                                sx={{ fontSize: '1.4rem', mr: 2 }}
                                                                                                disabled={isLoading}
                                                                                                onClick={() =>
                                                                                                    openInNewTab(
                                                                                                        row.type === 'image'
                                                                                                            ? `${S3BucketBaseUrl}${row.source}`
                                                                                                            : row.source
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <Tooltip title="View">
                                                                                                    <Iconify icon="clarity:eye-line" />
                                                                                                </Tooltip>
                                                                                            </Button>
                                                                                            <Button
                                                                                                type="Button"
                                                                                                variant="outlined"
                                                                                                color="error"
                                                                                                disabled={isLoading}
                                                                                                sx={{ fontSize: '1.4rem' }}
                                                                                                onClick={() => deleteSwal(row?._id, row?.type)}
                                                                                            >
                                                                                                <Tooltip title="Delete">
                                                                                                    <Iconify icon="gg:trash-empty" />
                                                                                                </Tooltip>
                                                                                            </Button>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Accordion sx={{ bgcolor: '#F4F6F8', borderRadius: '10px' }}>
                                                <AccordionSummary expandIcon={<Iconify icon="akar-icons:chevron-down" />}>
                                                    <Typography variant="h4" sx={{ m: 1 }}>
                                                        Product Catalogue
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Box>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} sx={{ justifyContent: 'end', display: 'flex' }}>
                                                                <Button
                                                                    type="Button"
                                                                    variant="contained"
                                                                    disabled={isLoading}
                                                                    className="fs-6 "
                                                                    onClick={() => onOpenAddEditModal('add', 'document')}
                                                                    sx={{ fontSize: iconFontSize }}
                                                                >
                                                                    Add Catalouge
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TableContainer component={Paper}>
                                                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell align="left">Index</TableCell>
                                                                                <TableCell align="center">Name</TableCell>
                                                                                <TableCell align="right">Type</TableCell>
                                                                                <TableCell align="right">Actions</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {dataHere?.catalogItems?.length > 0 &&
                                                                                dataHere?.catalogItems?.map((row, index) => (
                                                                                    <TableRow
                                                                                        key={row._id}
                                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                    >
                                                                                        <TableCell align="left">{index + 1}</TableCell>
                                                                                        <TableCell align="center">{row?.title}</TableCell>
                                                                                        <TableCell align="right">
                                                                                            {row?.extension?.toUpperCase()}
                                                                                        </TableCell>
                                                                                        <TableCell align="right" sx={{ minWidth: '265px' }}>
                                                                                            <Button
                                                                                                type="Button"
                                                                                                variant="outlined"
                                                                                                color="success"
                                                                                                disabled={isLoading}
                                                                                                onClick={() =>
                                                                                                    onOpenAddEditModal('edit', 'document', row)
                                                                                                }
                                                                                                sx={{ fontSize: '1.4rem', mr: 2 }}
                                                                                            >
                                                                                                <Tooltip title="Edit">
                                                                                                    <Iconify icon="clarity:edit-line" />
                                                                                                </Tooltip>
                                                                                            </Button>
                                                                                            <Button
                                                                                                type="Button"
                                                                                                variant="outlined"
                                                                                                color="primary"
                                                                                                disabled={isLoading}
                                                                                                sx={{ fontSize: '1.4rem', mr: 2 }}
                                                                                                onClick={() =>
                                                                                                    openInNewTab(
                                                                                                        row.type === 'image'
                                                                                                            ? `${S3BucketBaseUrl}${row.source}`
                                                                                                            : row.source
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <Tooltip title="View">
                                                                                                    <Iconify icon="clarity:eye-line" />
                                                                                                </Tooltip>
                                                                                            </Button>
                                                                                            <Button
                                                                                                type="Button"
                                                                                                variant="outlined"
                                                                                                color="error"
                                                                                                disabled={isLoading}
                                                                                                sx={{ fontSize: '1.4rem' }}
                                                                                                onClick={() => deleteSwal(row?._id, row?.type)}
                                                                                            >
                                                                                                <Tooltip title="Delete">
                                                                                                    <Iconify icon="gg:trash-empty" />
                                                                                                </Tooltip>
                                                                                            </Button>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    </>
                                )
                            )}
                        </Grid>
                    </>
                }
            />
        </>
    );
}

export default SingleProductPage;

const unitOption = [
    'Acre/Acres',
    'Ampere/Amperes',
    'Bag/Bags',
    'Barrel/Barrels',
    'Blade/Blades',
    'Box/Boxes',
    'Bushel/Bushels',
    'Carat/Carats',
    'Carton/Cartons',
    'Case/Cases',
    'Centimeter/Centimeters',
    'Chain/Chains',
    'Combo/Combos',
    'Container',
    'Cubic Centimeter/Cubic Centimeters',
    'Cubic Foot/Cubic Feet',
    'Cubic Inch/Cubic Inches',
    'Cubic Meter/Cubic Meters',
    'Cubic Yard/Cubic Yards',
    'Degrees Celsius',
    'Degrees Fahrenheit',
    'Dozen/Dozens',
    'Dram/Drams',
    'Fluid Ounce/Fluid Ounces',
    'Foot/Feet',
    'Furlong/Furlongs',
    'Gallon/Gallons',
    'Gill/Gills',
    'Grain/Grains',
    'Gram/Grams',
    'Gross Forty-Foot Container',
    'Hectare/Hectares',
    'Hertz',
    'Inch/Inches',
    'Kiloampere/Kiloamperes',
    'Kilogram/Kilograms',
    'Kilohertz',
    'Kilometer/Kilometers',
    'Kiloohm/Kiloohms',
    'Kilovolt/Kilovolts',
    'Kilowatt/Kilowatts',
    'Liter/Liters',
    'Long Ton/Long',
    'Megahertz',
    'Metric Ton/Metric Tons',
    'Mile/Miles',
    'Milliampere/Milliamperes',
    'Milligram/Milligrams',
    'Millihertz',
    'Milliliter/Milliliters',
    'Millimeter/Millimeters',
    'Milliohm/Milliohms',
    'Millivolt/Millivolts',
    'Milliwatt/Milliwatts',
    'Nautical Mile/Nautical Miles',
    'Ohm/Ohms',
    'Ounce/Ounces',
    'Pack/Packs',
    'Pair/Pairs',
    'Pallet/Pallets',
    'Parcel/Parcels',
    'Perch/Perches',
    'Piece/Pieces',
    'Pint/Pints',
    'Plant/Plants',
    'Pole/Poles',
    'Pound/Pounds',
    'Quart/Quarts',
    'Quarter/Quarters',
    'Rod/Rods',
    'Roll/Rolls',
    'Set/Sets',
    'Sheet/Sheets',
    'Short Ton/Short Tons',
    'Square Centimeter/Square Centimeters',
    'Square Foot/Square Feet',
    'Square Inch/Square Inches',
    'Square Meter/Square Meters',
    'Square Mile/Square Miles',
    'Square Yard/Square Yards',
    'Stone/Stones',
    'Strand/Strands',
    'Ton/Tons',
    'Tonne/Tonnes',
    'Tons Meter/Meters',
    'Tray/Trays',
    'Unit/Units',
    'Volt/Volts',
    'Watt/Watts',
    'Yard/Yards',
];
