/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
import { Box, Button, Container, Grid, MenuItem, Popover, TextField, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import CommonAddButton from '../../../components/add-button/CommonAddButton';
import { Progresscontext } from '../../../components/circular-progress/ProgressContext';
import Iconify from '../../../components/iconify';
import Loader from '../../../components/loader';
import CommonModal from '../../../components/modal/CommonModal';
import WarningModal from '../../../components/modal/WarningModal';
import useResponsive from '../../../hooks/useResponsive';
import {
    BUYER_BASE_MARKETPLACE,
    confirmGreen,
    defaultError,
    defaultProductImg,
    S3BucketBaseUrl,
    SHOWCASE_HEADER_NOTE,
    userRouteName,
} from '../../../utils/constants';
import customToast from '../../../utils/customToast';
import { copyText, openInNewTab, parseVideo } from '../../../utils/helper';
import apiMiddleWare from '../../../utils/useApiMiddleWare';
import SingleProductPage from './SingleProductPage';

function createData(id, name, productUrl, hashtags, minimumQ, unit, pricePerUnit, gallery, document) {
    return { id, name, productUrl, hashtags, minimumQ, unit, pricePerUnit, gallery, document };
}

const rows = [
    createData(1111, 'Name 1', 'youtube.com', ['hashtag1', 'hashtag2'], 3, 'kg', 300, ['gallery1', 'gallery2'], ['document 1', 'document 2']),
    createData(
        2222,
        'Name 2',
        'google.com',
        ['hashtag1', 'hashtag2', 'hashtag3', 'hashtag4', 'hashtag5', 'hashtag6'],
        10,
        'pc',
        500,
        ['gallery1', 'gallery2'],
        ['document 1', 'document 2']
    ),
    createData(3333, 'Name 3', 'samaaro.com', ['hashtag1', 'hashtag2'], 15, 'ltr', 2000, ['gallery1', 'gallery2'], ['document 1', 'document 2']),
    createData(444, 'Name 4', 'product.com', ['hashtag1', 'hashtag2'], 1, 'kg', 5000, ['gallery1', 'gallery2'], ['document 1', 'document 2']),
];
const iconFontSize = '1.9rem';

function UserProduct() {
    const navigate = useNavigate();

    const { setCallGuideStatus } = useContext(Progresscontext);

    const mdUp = useResponsive('up', 'md');
    const smUp = useResponsive('up', 'sm');
    const isLg = useResponsive('up', 'lg');

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [callApi, setCallApi] = useState(false);
    const [allData, setAllData] = useState([]);
    const [defaultShowcaseData, setDefaultShowcaseData] = useState({ showcaseRef: '', identifier: '' });
    const [singleData, setSingleData] = useState({
        open: false,
        data: {
            id: '',
        },
        mode: 'add',
    });
    const [cacheData, setCacheData] = useState({});
    const [expandedProduct, setExpandedProduct] = useState(false);
    const [shareModalData, setShareModalData] = useState({
        open: false,
        data: {
            identifier: '',
            title: '',
            url: '',
        },
    });

    // * waring modal state
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [missingFields, setMissingFields] = useState([]);

    // * warning modal functions
    // * close handler
    const handleClose = () => {
        setShowWarningModal(false);
    };
    const handleProceed = () => {
        navigate(`/${userRouteName}/showcase/contact`);
    };

    useEffect(() => {
        getInitialData();
    }, [callApi]);

    async function getInitialData() {
        try {
            setIsLoading(true);
            const res = await apiMiddleWare({ type: 'get', url: `/company/products` });
            if (!res?.data?.ok) {
                customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
                setIsLoading(false);
                return false;
            }
            setCacheData({});
            const { packet, showcaseRef, identifier } = res?.data;
            setAllData(packet);
            setDefaultShowcaseData({ showcaseRef, identifier });
            setIsLoading(false);
            return true;
        } catch (err) {
            customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
            setIsLoading(false);
            return false;
        }
    }

    async function getSingleData(givenID) {
        try {
            if (givenID in cacheData) {
                return cacheData[givenID];
            }
            setIsLoading(true);
            const res = await apiMiddleWare({ type: 'get', url: `/company/product/get/${givenID}` });
            if (!res?.data?.ok) {
                customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
                setIsLoading(false);
                return false;
            }

            const singleData = res?.data?.packet;
            setCacheData((prev) => ({
                ...prev,
                [givenID]: singleData,
            }));
            setIsLoading(false);
            return singleData;
        } catch (err) {
            customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
            setIsLoading(false);
            return false;
        }
    }

    function onOpenAddEditModal(type = 'add', data = {}) {
        handleCloseMenu();
        setSingleData((prev) => ({
            ...prev,
            open: true,
            data: {
                id: data?.id,
            },
            mode: type,
        }));
    }

    function closeModal(status = 'false') {
        setSingleData((prev) => ({
            ...prev,
            open: false,
            mode: 'add',
        }));
        if (status === true) {
            setCallGuideStatus((prev) => !prev);
            setExpandedProduct(false);
            setCallApi((prev) => !prev);
        }
    }

    const deleteSwalProduct = (id) => {
        handleCloseMenu();
        Swal.fire({
            title: 'Are you sure you want to delete product?',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            customClass: { container: 'zIndex-10000 ' },
        }).then((result) => {
            if (result.isConfirmed) {
                deleteButtonProduct(id);
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info');
            }
        });
    };

    const deleteButtonProduct = async (idHere) => {
        try {
            const toastID = toast.loading('Please wait...');

            const res = await apiMiddleWare({ url: `/company/product/delete/${idHere}`, type: 'delete' });
            if (!res?.data?.ok) {
                customToast({
                    updateId: toastID,
                    type: 'update',
                    msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                    updateType: 'error',
                });
                return;
            }
            customToast({ updateId: toastID, type: 'update', msg: 'Product Deleted Successfully', updateType: 'success' });
            setCallApi((prev) => !prev);
            setCallGuideStatus((prev) => !prev);
        } catch (err) {
            customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
        }
    };

    const handleChangeAccordion = (id) => async (e, newExpanded) => {
        setExpandedProduct(newExpanded ? id : false);
        if (newExpanded) {
            await getSingleData(id);
        }
    };

    function getThumbnailLink(link) {
        const data = parseVideo(link);
        return data?.thumb;
    }

    const submitAndNext = (type) => () => {
        const missingFields = [];
        if (allData?.length === 0) missingFields.push('Products');
        if(missingFields.length > 0) {
            setMissingFields(missingFields);
            setShowWarningModal(true);
        } else if (type === 'next') {
            navigate(`/${userRouteName}/showcase/contact`);
        } else if (type === 'share') {
            navigate(`/${userRouteName}/showcase/share`);
        }
    };

    const handleOpenMenu = (e) => {
        setOpen(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const openShareModal = (data) => {
        const { productRef, title } = data;
        const { showcaseRef, identifier } = defaultShowcaseData;
        const url = `${BUYER_BASE_MARKETPLACE}/${showcaseRef}/${productRef}`;
        setShareModalData((prev) => ({
            ...prev,
            open: true,
            data: {
                identifier,
                title,
                url,
            },
        }));
    };

    const closeShareModal = () => {
        setShareModalData((prev) => ({
            ...prev,
            open: false,
        }));
        resetShareModal();
    };

    const resetShareModal = () => {
        setShareModalData((prev) => ({
            ...prev,
            data: {
                identifier: '',
                title: '',
                url: '',
            },
        }));
    };

    return (
        <>
            {/* warning modal */}
            <WarningModal
                sectionName="Product Portfolio"
                missingFields={missingFields}
                open={showWarningModal}
                handleClose={handleClose}
                handleProceed={handleProceed}
            />

            {shareModalData?.open && (
                <CommonModal
                    open={shareModalData?.open}
                    onClose={closeShareModal}
                    customBodyStyle={' extra-height '}
                    title={`Share - ${shareModalData?.data?.title}`}
                    customFooter={<></>}
                    noFooterLine
                    body={
                        <div className="d-flex flex-column">
                            <div className="px-2 px-md-3 my-3 mx-auto d-flex  flex-wrap">
                                <div className=" mx-auto ">
                                    <TextField
                                        className=""
                                        sx={{
                                            width: 'auto',
                                            minWidth: !mdUp ? '100%' : '600px',
                                            maxWidth: !mdUp ? '550px' : 'none',
                                        }}
                                        value={shareModalData?.data?.url}
                                        disabled
                                    />
                                </div>
                                <div className="mx-auto mt-2 mt-lg-0">
                                    <Tooltip title={'Open Link'}>
                                        <Button
                                            variant="contained"
                                            className="display-5 mx-2 h-100"
                                            onClick={openInNewTab(shareModalData?.data?.url)}
                                        >
                                            <Iconify icon="maki:arrow" />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title={'Copy Link'}>
                                        <Button
                                            variant="contained"
                                            className="display-5 mx-2 h-100"
                                            onClick={() => copyText(shareModalData?.data?.url, 'Link Copied')}
                                        >
                                            <Iconify icon="material-symbols:content-copy-outline-rounded" />
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="mx-auto d-flex gap-2 display-6 flex-wrap d-flex justify-content-center mt-4 mt-md-3">
                                <Tooltip title={'Share in Twitter'}>
                                    <div
                                        className="circle-icon"
                                        onClick={openInNewTab(`https://twitter.com/intent/tweet?url=${shareModalData?.data?.url}`)}
                                    >
                                        <Iconify icon="ri:twitter-fill" width="10" />
                                    </div>
                                </Tooltip>

                                <Tooltip title={'Share in Linkedin'}>
                                    <div
                                        className="circle-icon"
                                        onClick={openInNewTab(`https://www.linkedin.com/sharing/share-offsite/?url=${shareModalData?.data?.url}`)}
                                    >
                                        <Iconify icon="uil:linkedin" width="10" />
                                    </div>
                                </Tooltip>

                                <Tooltip title={'Share in Facebook'}>
                                    <div
                                        className="circle-icon"
                                        onClick={openInNewTab(`https://www.facebook.com/sharer.php?u=${shareModalData?.data?.url}`)}
                                    >
                                        <Iconify icon="ic:baseline-facebook" width="10" />
                                    </div>
                                </Tooltip>

                                <Tooltip title={'Share in Whatsapp'}>
                                    <div
                                        className="circle-icon"
                                        onClick={openInNewTab(`https://web.whatsapp.com/send?text=${shareModalData?.data?.url}`)}
                                    >
                                        <Iconify icon="ic:baseline-whatsapp" width="10" />
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    }
                />
            )}
            <SingleProductPage open={singleData?.open} onClose={closeModal} mode={singleData?.mode} data={singleData?.data} />

            <Container maxWidth="xl" sx={{ px: smUp ? 4 : 2, pb: 2 }} className="h-100 w-100">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            {/* <Typography variant="h3" sx={{ mb: 5 }}>
                                Showcase Products
                            </Typography> */}
                            {/* <div>
                                <Button
                                type="Button"
                                variant="contained"
                                onClick={() => onOpenAddEditModal('add')}
                                sx={{ fontSize: iconFontSize }}
                                >
                                <Iconify icon="ant-design:plus-outlined" width="10" />
                                </Button>
                            </div> */}
                        </Box>
                        <Box className="mt-3">
                            <Typography variant="h6" className="mb-3">
                                Note: {SHOWCASE_HEADER_NOTE.product}
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <div className="row">
                                        {allData?.length > 0 ? (
                                            <>
                                                <div className="col-6 col-md-4 col-lg-3 mb-4 showcase_max_ht">
                                                    <CommonAddButton text="Add Product" onClickParent={() => onOpenAddEditModal('add')} />
                                                </div>
                                                {allData?.map((i, index) => (
                                                    <div className="col-6 col-md-4 col-lg-3 mb-4 d-flex flex-column" key={index}>
                                                        <div className=" showcase_max_ht position-relative">
                                                            <div className="d-flex p-0 flex-column justify-content-center align-items-center  border-custom bg-white custom-shadow box_click">
                                                                <div className="display-3 position-relative w-100 h-100 justify-content-center align-items-center d-flex position-relative">
                                                                    {/* <Iconify icon="material-symbols:hide-image-outline-rounded" width="10" /> */}

                                                                    {i?.thumbnail ? (
                                                                        <img
                                                                            src={`${S3BucketBaseUrl}${i?.thumbnail}`}
                                                                            alt=""
                                                                            className="aspect-ratio-1 background-white"
                                                                        />
                                                                    ) : (
                                                                        <img src={`${S3BucketBaseUrl}${defaultProductImg}`} alt="" />
                                                                    )}
                                                                    <div className="hidden_box ">
                                                                        <div className="d-flex justify-content-center align-items-center h-100 px-3">
                                                                            <div className="row">
                                                                                <div className="col-6  d-flex justify-content-center text-center mb-2 px-2 ">
                                                                                    <Button
                                                                                        type="Button"
                                                                                        variant="outlined"
                                                                                        color="success"
                                                                                        className="bg-white px-1 px-lg-2"
                                                                                        // disabled={isLoading}
                                                                                        onClick={() => onOpenAddEditModal('edit', { id: i?._id })}
                                                                                        sx={{ width: '100%', minWidth: 'auto' }}
                                                                                    >
                                                                                        <Tooltip title="Edit" className="text-center w-100">
                                                                                            <Iconify icon="clarity:edit-line" />
                                                                                        </Tooltip>
                                                                                    </Button>
                                                                                </div>

                                                                                <div className="col-6  d-flex justify-content-center text-center mb-2 px-2 ">
                                                                                    <Button
                                                                                        type="Button"
                                                                                        className="bg-white px-1 px-lg-2"
                                                                                        variant="outlined"
                                                                                        color="error"
                                                                                        // disabled={isLoading}
                                                                                        onClick={() => deleteSwalProduct(i?._id)}
                                                                                        sx={{ width: '100%', minWidth: 'auto' }}
                                                                                    >
                                                                                        <Tooltip title="Delete" className="text-center w-100">
                                                                                            <Iconify icon="gg:trash-empty" />
                                                                                        </Tooltip>
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="title__box p-2 bg-light font-body-custom cursor-pointer "
                                                                    style={{ height: '40px' }}
                                                                >
                                                                    <span className={'w-100'}>
                                                                        <Tooltip
                                                                            title={i?.title}
                                                                            placement={'bottom-start'}
                                                                            className=""
                                                                            // onClick={() => i?.designation && copyText(i?.designation, 'Designation Copied')}
                                                                        >
                                                                            <div className="ellipse-text">{i?.title}</div>
                                                                        </Tooltip>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {defaultShowcaseData?.showcaseRef && (
                                                            <div>
                                                                <Button
                                                                    variant="contained"
                                                                    type="button"
                                                                    className="mt-2 w-100"
                                                                    onClick={() => openShareModal({ productRef: i?._id, title: i?.title })}
                                                                >
                                                                    Share
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                    // <div key={index} className="col-6 col-sm-4 col-md-3 mb-3 product_max_ht">
                                                    //   <div
                                                    //     className="d-flex p-0 flex-column justify-content-center align-items-center  border-custom bg-white "
                                                    //     // onClick={() => openProduct(i?._id)}
                                                    //   >
                                                    //     <div className="display-3 position-relative w-100 h-100 justify-content-center align-items-center d-flex">
                                                    //       {/* <Iconify icon="material-symbols:hide-image-outline-rounded" width="10" /> */}

                                                    //       {i?.thumbnail ? (
                                                    //         <img src={`${S3BucketBaseUrl}${i?.thumbnail}`} alt="" className='aspect-ratio-1 background-white'/>
                                                    //       ) : (
                                                    //         <img src={`${S3BucketBaseUrl}${defaultProductImg}`} alt="" className='aspect-ratio-1 background-white'/>
                                                    //       )}
                                                    //     </div>
                                                    //     <div
                                                    //       className="title__box p-2 bg-light font-body-custom cursor-pointer "
                                                    //       style={{ height: '40px' }}
                                                    //     >
                                                    //       <span className={smUp ? 'w-150px' : 'w-80px'}>
                                                    //         <Tooltip
                                                    //           title={i?.title}
                                                    //           placement={'bottom-start'}
                                                    //           className=""
                                                    //           // onClick={() => i?.designation && copyText(i?.designation, 'Designation Copied')}
                                                    //         >
                                                    //           <div className="ellipse-text">{i?.title}</div>
                                                    //         </Tooltip>
                                                    //       </span>
                                                    //     </div>
                                                    //   </div>
                                                    // </div>
                                                ))}
                                            </>
                                        ) : (
                                            <div className="col-12 col-md-4 col-lg-3 mb-4 showcase_max_ht">
                                                <CommonAddButton text="Add Product" onClickParent={() => onOpenAddEditModal('add')} />
                                            </div>
                                        )}
                                    </div>
                                </Grid>
                                {!isLoading && (
                                    <Box
                                        sx={{
                                            position: 'fixed',
                                            bottom: 0,
                                            right: 0,
                                            left: isLg ? 250 : 0,
                                            bgcolor: 'white',
                                            p: 1,
                                            boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
                                            gap: 2,
                                        }}
                                    >
                                        <div className='d-flex w-100 justify-content-end'>
                                        <Button type="button" sx={{background:confirmGreen,":hover":{background:confirmGreen},boxShadow:"none"}} onClick={submitAndNext('next')} variant="contained">
                                            Save & Next
                                        </Button>
                                        </div>
                                        {/* <div className="">
                                        <Button type="button" onClick={submitAndNext('share')} variant="contained">
                                            Save & Share
                                        </Button>
                                        </div> */}
                                    </Box>
                                )}
                            </Grid>
                        </Box>
                    </>
                )}
            </Container>
            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem sx={{ color: 'success.main' }} onClick={() => onOpenAddEditModal('edit', { id: open?.dataset?.id })}>
                    <Iconify icon="clarity:edit-line" sx={{ mr: 2 }} />
                    Edit
                </MenuItem>

                <MenuItem
                    sx={{ color: 'primary.main' }}
                    onClick={() => {
                        handleCloseMenu();
                        openInNewTab(open?.dataset?.url)();
                    }}
                >
                    <Iconify icon="akar-icons:link-chain" sx={{ mr: 2 }} />
                    Open URL
                </MenuItem>

                <MenuItem sx={{ color: 'error.main' }} onClick={() => deleteSwalProduct(open?.dataset?.id)}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover>
        </>
    );
}

export default UserProduct;
