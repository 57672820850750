import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { Carousel } from 'react-bootstrap';

// @mui
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Container, Typography, Grid } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
import { userRouteName, visitorRouteName } from '../../utils/constants';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  // [theme.breakpoints.up('md')]: {
  //   display: 'flex',
  // },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // height: '100%',
  // borderRadius: '40px',
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  maxWidth: 480,
  display: 'flex',
  overflow: 'hidden',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundImage: `url('/favicon/samaaroBG.png')`,
  backgroundPosition: 'left',
  backgroundRepeat: 'no-repeat',
  boxShadow: theme.customShadows.card,
  backgroundColor: '#151521',
  backgroundSize: '65vh',
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(7, 0),
}));

// ----------------------------------------------------------------------

LandingPage.propTypes = {
  type: PropTypes.string,
};

function LandingPage({ type }) {
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');
  const mdDown = useResponsive('down', 'md');
  const typeName = String(type)[0].toUpperCase() + String(type).substring(1).toLowerCase();
  // const location = useLocation();

  return (
    <div
      className="main-landing-page"
      style={{
        backgroundColor: 'white',
        height: '100vh',
        maxHeight: '100vh',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Helmet>
        <title>Informa Marketplace</title>
      </Helmet>

      <div className="d-flex justify-content-center justify-content-md-start p-3 ">
        <span style={{ width: '200px', height: '40px',objectFit:'contain',position:'relative' }} className=''>
        <img
          src={'/assets/icons/navbar/woc_nav_logo.png'}
          alt="Samaaro Logo"
          style={{ width: '100%', height: '200%',objectFit:'contain',position:"absolute",top:'-1.1rem',left:'0' }}
        />
        </span>
        {mdUp && (
          <span className="green-color mx-3 fs-4 fw-bold w-100 d-flex justify-content-center">
            Become the Seller of Informa Markets Concrete industry Marketplace
          </span>
        )}
      </div>

      <div
        className="flex-grow-1 d-flex justify-content-center align-items-center h-100 overflow-hidden"
        style={{ backgroundColor: '#f3f3f3' }}
      >
        {/* <img
          style={{ width: '100vw', height: '100vh', objectFit: 'cover', position: 'absolute', zIndex: '-1' }}
          src="/assets/backgroundImg/login-register/blurry-gradient-haikei.svg"
          alt="background SVG"
        /> */}

        <div className="reg-card-landing">
          {/* <Box> */}
          <Grid container className="h-100 w-100 flex-grow-1 card overflow-hidden box-shadow-1">
            {mdUp && (
              <Grid item md={4} className=" flex-grow-1 ">
                <img
                  className="d-block w-100 fit-cover h-100"
                  src={
                    window.location.pathname.includes('/login')
                      ? '/assets/backgroundImg/login-register/login-img.png'
                      : '/assets/backgroundImg/login-register/reg-img.png'
                  }
                  alt="Login-BG"
                />
              </Grid>
            )}
            <Grid item md={8} style={{ minWidth: smUp ? '350px' : '320px' }} className="mx-2 h-100 flex-grow-1">
              {/* <div
                // className="grid-landing"
              > */}
              <Container
                maxWidth={window.location.pathname.includes('login') ? 'sm' : ''}
                className={`py-2 py-md-3 px-0 px-md-4 h-100 d-flex justify-content-center align-items-center flex-column ${
                  window.location.pathname.includes('login') ? ' ' : smUp ? 'w-100' : ''
                }`}
              >
                {/* <div className="fw-normal fs-4 lh-1">
                   {window.location.pathname.includes('/login') ? 'Sign In' : 'Register'} 
                </div> */}
                <div className="black-login fs-3 fw-bold mb-2 w-100 text-start ">Seller Dashboard</div>
                <div className={`text-start w-100 ${window.location.pathname.includes('login') ? '' : 'flex-grow-1 '}`}>
                  <Outlet />
                </div>
              </Container>
              {/* </div> */}
            </Grid>
          </Grid>
          {/* </Box> */}
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
