/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import secureLocalStorage from 'react-secure-storage';
import Tooltip from '@mui/material/Tooltip';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic/build/ckeditor';
import Checkbox from '@mui/material/Checkbox';
import { toast } from 'react-toastify';

import Iconify from '../../../../components/iconify';
import {
  defaultError,
  BASE_URL,
  userRouteName,
  ckEditorConfig,
  S3BucketBaseUrl,
  defaultProductImg,
  defaultLogoImg,
  tableBgColor,
  wocOrange,
} from '../../../../utils/constants';
import apiMiddleWare from '../../../../utils/useApiMiddleWare';
import customToast from '../../../../utils/customToast';
import { copyText, getBlob, getExtension, openInNewTab, parseVideo } from '../../../../utils/helper';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import CommonModal from '../../../../components/modal/CommonModal';
import ImageUpload from '../../../../components/image-upload/ImageUpload';
import useResponsive from '../../../../hooks/useResponsive';

const iconFontSize = '1.9rem';
const commonBoxStyles = {
  bgcolor: '#fff',
  borderColor: 'transparent',
  p: 2,
  // border: 1,
  width: '100%',
  minHeight: '5rem',
};
const samaaroBgBlue = '#ffffff';

function AddEditUserTemplate({ open, onClose, templateList, mode, editData }) {
  const smUp = useResponsive('up', 'sm');

  const stepList = {
    showcaseType: {
      title: 'Showcase Type',
      // getApi: '/showcase/list-all'
    },
    profile: {
      title: 'Profile',
      // getApi: '/company/profile',
    },
    resource: {
      title: 'Resource',
      getApi: '/company/resources',
    },
    product: {
      title: 'Product',
      getApi: '/company/products',
    },
    contact: {
      title: 'Contact',
      getApi: '/company/contact',
    },
    rep: {
      title: 'Representative',
      getApi: '/company/reps',
    },
    social: {
      title: 'Social',
      // getApi: '/company/social',
    },
  };
  const stepsOrder = ['showcaseType', 'profile', 'resource', 'product', 'contact', 'social'];

  const [currentStep, setCurrentStep] = useState(mode === 'add' ? stepsOrder[0] : stepsOrder[1]);
  const timeout = useRef();

  const [callProductApi, setCallProductApi] = useState(false);
  const [callResourceApi, setCallResourceApi] = useState(false);
  const [callRepApi, setCallRepApi] = useState(false);
  const [apiCalledOnce, setApiCalledOnce] = useState(false);

  const [newType, setNewType] = useState('new'); // ['new','clone']
  const [newCreated, setNewCreated] = useState(false);

  const [formData, setFormData] = useState({
    showcaseID: editData?._id || '',
    profile: {
      identifier: { value: editData?.identifier || '', error: false },
      title: editData?.name || '',
      profileImg: { s3name: editData?.logo || '', img: editData?.logo ? S3BucketBaseUrl + editData?.logo : '' },
      banner: {
        type: editData?.banner?.type || 'image',
        s3Name: editData?.banner?.source || '',
        img: editData?.banner?.source
          ? editData?.banner?.type === 'image'
            ? S3BucketBaseUrl + editData?.banner?.source
            : editData?.banner?.source
          : '',
        extension: editData?.banner?.extension || '',
      },
      keywords: { value: editData?.hashtags?.join(',') || '', list: editData?.hashtags || [] },
      description: editData?.description || '',
    },
    resource: {
      gallery: [],
      document: [],
      gallerySelected: editData?.galleryList?.map((i) => i?.galleryID) || [],
      documentSelected: editData?.docList?.map((i) => i?.docID) || [],
    },
    product: {
      list: [],
      listSelected: editData?.productList?.map((i) => i?.productID) || [],
      removedProducts: [],
      newAddProducts: [],
      prevProducts: editData?.productList?.map((i) => i?.productID) || [],
    },
    contact: {
      calendlyLink: {
        value: editData?.calendlyLink || '',
        list: [],
      },
      rep: [],
      repSelected: editData?.repList?.map((i) => i?.repID) || [],
    },
    social: {
      website: editData?.website || '',
      email: editData?.email || '',
      phone: editData?.phone || '',
      address: editData?.address || '',
      socialMedia: {
        facebook: editData?.socialMedia?.facebook || '',
        instagram: editData?.socialMedia?.instagram || '',
        linkedin: editData?.socialMedia?.linkedin || '',
        twitter: editData?.socialMedia?.twitter || '',
        pinterest: editData?.socialMedia?.pinterest || '',
        youtube: editData?.socialMedia?.youtube || '',
      },
    },
  });

  const [addResourceModal, setAddResourceModal] = useState({
    mode: 'add',
    subtype: 'gallery',
    modalOpen: false,
    data: {
      id: '',
      name: '',
      type: 'image', // ['image','video']
      src: '',
      file: {},
      imgName: '',
      videoProvider: '',
    },
  });
  const [addRepModal, setAddRepModal] = useState({
    mode: 'add',
    modalOpen: false,
    data: {
      repID: '',
      name: '',
      designation: '',
      email: '',
      phone: '',
    },
  });

  useEffect(() => {
    if (currentStep === 'showcaseType') {
      getShowcaseList();
    } else if (currentStep === 'profile') {
      getProfileData();
    } else if (currentStep === 'resource') {
      getResourceData();
    } else if (currentStep === 'product') {
      getProductData();
    } else if (currentStep === 'contact') {
      getContactData();
    } else if (currentStep === 'social') {
      getSocialList();
    }
  }, [currentStep, callProductApi, callResourceApi, callRepApi]);

  // INITIAL API CALL START
  const commonInitialData = async (curStep) => {
    const url = stepList[curStep]?.getApi || '';
    if (!url) {
      return false;
    }
    try {
      // setIsLoading(true);
      const res = await apiMiddleWare({ type: 'get', url });
      if (!res?.data?.ok) {
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        return false;
      }
      // setIsLoading(false);
      return res?.data;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      // setIsLoading(false);
      return false;
    }
  };

  const getShowcaseList = async () => {
    await commonInitialData('showcaseType');
  };

  const getProfileData = async () => {
    await commonInitialData('profile');
    // setFormData((prev) => ({
    //   ...prev,
    //   profile: {
    //     ...prev.profile,
    //     profileImg:data?.packet?.documentItems,
    //     banner : {
    //     ...prev.profile.banner,
    //     extension :

    //     }
    //   },
    // }));
  };
  const getResourceData = async () => {
    const data = await commonInitialData('resource');
    setFormData((prev) => ({
      ...prev,
      resource: {
        ...prev.resource,
        gallery: data?.packet?.galleryItems,
        document: data?.packet?.documentItems,
      },
    }));
  };
  const getProductData = async () => {
    const data = await commonInitialData('product');
    setFormData((prev) => ({
      ...prev,
      product: {
        ...prev.product,
        list: data?.packet,
      },
    }));
  };
  const getContactData = async () => {
    const [contactData, repData] = await Promise.all([commonInitialData('contact'), commonInitialData('rep')]);

    setFormData((prev) => ({
      ...prev,
      contact: {
        ...prev.contact,
        rep: repData?.packet,
      },
    }));
  };

  const getSocialList = async () => {
    await commonInitialData('social');
  };

  // HELPER
  const backButton = () => {
    const curIndex = stepsOrder.indexOf(currentStep);
    setCurrentStep(stepsOrder[(curIndex - 1) % stepsOrder.length]);
  };
  const nextStep = () => {
    const curIndex = stepsOrder.indexOf(currentStep);
    const goToTab = (curIndex + 1) % stepsOrder.length;
    setCurrentStep(stepsOrder[goToTab]);
    if (goToTab === 0) {
      // onClose(apiCalledOnce);
      addEditClose();
    }
  };

  const addEditClose = async () => {
    if (!!newCreated) {
      await deleteNewShowcase({ id: formData?.showcaseID });
    }
    onClose(apiCalledOnce);
  };
  const deleteNewShowcase = async ({ id }) => {
    try {
      // const toastID = toast.loading('Please wait...');

      const res = await apiMiddleWare({ url: `/showcase/template/delete/${id}`, type: 'delete' });
      if (res?.data?.ok) {
        // customToast({
        //   updateId: toastID,
        //   type: 'update',
        //   msg: '',
        //   updateType: 'success',
        // });
        return true;
      } else {
        // customToast({
        //   updateId: toastID,
        //   type: 'update',
        //   msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
        //   updateType: 'error',
        // });
        return false;
      }
    } catch (err) {
      customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
      console.error('callDeleteShowcase error', err);
      return false;
    }
  };

  const resetForm = (params) => {
    const { type } = params;
    if (type === 'profile') {
      console.log('resetForm = ', type);
    } else if (type === 'resource') {
      console.log('resetForm = ', type);
    } else if (type === 'product') {
      console.log('resetForm = ', type);
    } else if (type === 'contact') {
      console.log('resetForm = ', type);
    }
  };
  const saveStep = (params) => async () => {
    const { data } = params || { data: {} };
    const type = currentStep;
    try {
      let res;
      if (type === 'showcaseType') {
        if (data?.id) {
          // clone from other template
          res = await getTemplateIDSave({ id: data?.id });
        } else {
          // make new template
          res = await getTemplateIDSave();
        }
        setNewCreated(true);
      } else if (type === 'profile') {
        res = await saveProfile();
      } else if (type === 'resource') {
        res = await saveResource();
      } else if (type === 'product') {
        res = await saveProduct();
      } else if (type === 'contact') {
        res = await saveContact();
      } else if (type === 'social') {
        res = await saveSocial();
      }

      if (type === stepsOrder[1]) {
        setNewCreated(false);
      }

      if (res) {
        setApiCalledOnce(true);
        nextStep();
      }
    } catch (err) {
      customToast({
        msg: err,
        type: 'error',
      });
      console.error('saveStep ', err);
    }
  };

  // SAVE API FUNCTION CALL
  const getTemplateIDSave = async (params) => {
    const id = params?.id;
    const mode = id ? 'clone' : 'new';
    setNewType(mode);
    try {
      const toastID = toast.loading('Please wait...');

      const sendData = { mode, showcaseRef: id || '' };
      const url = '/showcase/template/create';
      const res = await apiMiddleWare({ type: 'post', url, data: sendData });

      if (!res?.data?.ok) {
        customToast({
          updateId: toastID,
          type: 'update',
          msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
          updateType: 'error',
        });
        return false;
      }
      const data = res?.data?.packet;
      setFormData((prev) => ({
        ...prev,
        showcaseID: data?._id,
        profile: {
          ...prev.profile,
          identifier: { ...prev.profile.identifier, value: data?.identifier },
          title: data?.name,
          profileImg: {
            ...prev.profile.profileImg,
            s3name: data?.logo || '',
            img: data?.logo ? S3BucketBaseUrl + data?.logo : '',
          },
          banner: {
            ...prev.profile.banner,
            type: data?.banner?.type || 'image',
            s3Name: data?.banner?.source,
            img: data?.banner?.type === 'image' ? S3BucketBaseUrl + data?.banner?.source : data?.banner?.source,
            extension: data?.banner?.extension,
          },
          keywords: {
            ...prev.profile.keywords,
            value: data?.hashtags?.join(','),
            list: data?.hashtags || [],
          },
          description: data?.description,
        },
        resource: {
          ...prev.resource,
          gallerySelected: data?.galleryList?.map((i) => i?.galleryID) || [],
          documentSelected: data?.docList?.map((i) => i?.docID) || [],
        },
        product: {
          ...prev.product,
          listSelected: data?.productList?.map((i) => i?.productID),
        },
        contact: {
          ...prev.contact,
          calendlyLink: {
            ...prev.contact.calendlyLink,
            value: data?.calendlyLink,
          },
          repSelected: data?.repList?.map((i) => i?.repID) || [],
        },
        social: {
          ...prev.social,
          address: data?.address,
          email: data?.email,
          phone: data?.phone,
          website: data?.website,
          socialMedia: {
            ...prev.social.socialMedia,
            facebook: data?.socialMedia?.facebook,
            instagram: data?.socialMedia?.instagram,
            linkedin: data?.socialMedia?.linkedin,
            pinterest: data?.socialMedia?.pinterest,
            twitter: data?.socialMedia?.twitter,
            youtube: data?.socialMedia?.youtube,
          },
        },
      }));
      customToast({ updateId: toastID, type: 'update', msg: 'Data Saved Successfully', updateType: 'success' });

      return true;
    } catch (err) {
      console.error('getTemplateIDSave ++', err);
      customToast({
        msg: err?.message || err?.msg || defaultError,
        type: 'error',
      });
      return false;
    }
  };
  const saveProfile = async () => {
    try {
      const toastID = toast.loading('Please wait...');
      const sendData = await validateProfileForm();

      if (newType === 'new') {
        const { logo, banner } = await uploadImageToS3Profile();
        // if (formData?.profile?.profileImg?.img.indexOf(S3BucketBaseUrl) === -1) {
        sendData.logo = logo;
        // }

        if (formData?.profile?.banner?.type === 'image') {
          // if (formData?.profile?.banner?.img.indexOf(S3BucketBaseUrl) === -1) {
          sendData.banner.source = banner;
          // }
        } else {
          sendData.banner.source = formData?.profile?.banner?.img;
        }
      }

      const url = '/showcase/template/profile';
      const res = await apiMiddleWare({ type: 'patch', url, data: sendData });

      if (!res?.data?.ok) {
        customToast({
          updateId: toastID,
          type: 'update',
          msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
          updateType: 'error',
        });

        return false;
      }
      const data = res?.data?.packet;
      customToast({ updateId: toastID, type: 'update', msg: 'Data Saved Successfully', updateType: 'success' });

      return true;
    } catch (err) {
      console.error('saveProfile ++', err);
      customToast({
        msg: err?.message || err?.msg || defaultError,
        type: 'error',
      });
      return false;
    }
  };
  const saveResource = async () => {
    try {
      const toastID = toast.loading('Please wait...');

      const sendData = {
        showcaseRef: formData?.showcaseID,
        galleryList: formData?.resource?.gallerySelected?.map((i, index) => ({ priority: index + 1, galleryID: i })),
        docList: formData?.resource?.documentSelected?.map((i, index) => ({ priority: index + 1, docID: i })),
      };

      const url = '/showcase/template/resource';
      const res = await apiMiddleWare({ type: 'patch', url, data: sendData });

      if (!res?.data?.ok) {
        customToast({
          updateId: toastID,
          type: 'update',
          msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
          updateType: 'error',
        });
        return false;
      }
      const data = res?.data?.packet;
      customToast({ updateId: toastID, type: 'update', msg: 'Data Saved Successfully', updateType: 'success' });

      return true;
    } catch (err) {
      console.error('saveResource ++', err);
      customToast({
        msg: err?.message || err?.msg || defaultError,
        type: 'error',
      });
      return false;
    }
  };
  const saveProduct = async () => {
    try {
      const toastID = toast.loading('Please wait...');
      const sendData = {
        showcaseRef: formData?.showcaseID,
        productList: formData?.product?.listSelected?.map((i, index) => ({ priority: index + 1, productID: i })),
        removedProducts: formData?.product?.removedProducts || [],
        newAddProducts: formData?.product?.newAddProducts || [],
      };

      const url = '/showcase/template/products';
      const res = await apiMiddleWare({ type: 'patch', url, data: sendData });

      if (!res?.data?.ok) {
        customToast({
          updateId: toastID,
          type: 'update',
          msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
          updateType: 'error',
        });

        return false;
      }
      // const data = res?.data?.packet;
      customToast({ updateId: toastID, type: 'update', msg: 'Data Saved Successfully', updateType: 'success' });

      setFormData((prev) => ({
        ...prev,
        product: {
          ...prev.product,
          prevProducts: sendData?.productList,
          newAddProducts: [],
          removedProducts: [],
        },
      }));

      return true;
    } catch (err) {
      console.error('saveProduct ++', err);
      customToast({
        msg: err?.message || err?.msg || defaultError,
        type: 'error',
      });
      return false;
    }
  };
  const saveContact = async () => {
    try {
      const toastID = toast.loading('Please wait...');

      const sendData = {
        showcaseRef: formData?.showcaseID,
        repList: formData?.contact?.repSelected?.map((i, index) => ({ priority: index + 1, repID: i })),
        calendlyLink: formData?.contact?.calendlyLink?.value,
      };

      const url = '/showcase/template/contact';
      const res = await apiMiddleWare({ type: 'patch', url, data: sendData });

      if (!res?.data?.ok) {
        customToast({
          updateId: toastID,
          type: 'update',
          msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
          updateType: 'error',
        });

        return false;
      }
      const data = res?.data?.packet;
      customToast({ updateId: toastID, type: 'update', msg: 'Data Saved Successfully', updateType: 'success' });

      return true;
    } catch (err) {
      console.error('saveContact ++', err);
      customToast({
        msg: err?.message || err?.msg || defaultError,
        type: 'error',
      });
      return false;
    }
  };

  const saveSocial = async () => {
    try {
      const toastID = toast.loading('Please wait...');

      const sendData = {
        showcaseRef: formData?.showcaseID,
        website: formData?.social?.website,
        email: formData?.social?.email,
        phone: formData?.social?.phone,
        address: formData?.social?.address,
        socialMedia: {
          facebook: formData?.social?.socialMedia?.facebook,
          instagram: formData?.social?.socialMedia?.instagram,
          linkedin: formData?.social?.socialMedia?.linkedin,
          pinterest: formData?.social?.socialMedia?.pinterest,
          twitter: formData?.social?.socialMedia?.twitter,
          youtube: formData?.social?.socialMedia?.youtube,
        },
      };

      const url = '/showcase/template/social';
      const res = await apiMiddleWare({ type: 'patch', url, data: sendData });

      if (!res?.data?.ok) {
        customToast({
          updateId: toastID,
          type: 'update',
          msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
          updateType: 'error',
        });

        return false;
      }
      const data = res?.data?.packet;
      customToast({ updateId: toastID, type: 'update', msg: 'Data Saved Successfully', updateType: 'success' });

      return true;
    } catch (err) {
      console.error('saveContact ++', err);
      customToast({
        msg: err?.message || err?.msg || defaultError,
        type: 'error',
      });
      return false;
    }
  };

  // PROFILE TAB FUNCTIONS
  const handleIdentifierChange = (e) => {
    let { value } = e.target;
    value = value.replace(/[^a-z0-9-]/g, '').replace(/(\..*?)\..*/g, '$1');
    setFormData((prev) => ({
      ...prev,
      profile: {
        ...prev.profile,
        identifier: {
          ...prev.profile.identifier,
          value,
        },
      },
    }));

    const sendData = {
      showcaseID: formData?.showcaseID || '',
      identifier: value,
    };

    clearTimeout(timeout.current);
    timeout.current = setTimeout(async () => {
      try {
        const res = await apiMiddleWare({ url: `/showcase/check-identifier`, type: 'post', data: sendData });
        if (!res?.data?.ok) {
          setFormData((prev) => ({
            ...prev,
            profile: {
              ...prev.profile,
              identifier: {
                ...prev.profile.identifier,
                error: true,
              },
            },
          }));
          customToast({
            msg: res?.response?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || 'Username Not Valid',
            type: 'error',
          });
          return false;
        }

        setFormData((prev) => ({
          ...prev,
          profile: {
            ...prev.profile,
            identifier: {
              ...prev.profile.identifier,
              error: false,
            },
          },
        }));
        return true;
      } catch (err) {
        customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
        return false;
      }
    }, 1200);
  };
  const getLogoHere = (data) => {
    if (!!data) {
      setFormData((prev) => ({
        ...prev,
        profile: {
          ...prev.profile,
          profileImg: {
            ...prev.profile?.profileImg,
            img: data,
          },
        },
      }));
    }
  };
  const clearImg = (type) => {
    if (type === 'logo') {
      setFormData((prev) => ({
        ...prev,
        profile: {
          ...prev.profile,
          profileImg: {
            ...prev.profile?.profileImg,
            img: '',
          },
        },
      }));
    } else if (type === 'banner') {
      setFormData((prev) => ({
        ...prev,
        profile: {
          ...prev.profile,
          banner: {
            ...prev.profile?.banner,
            img: '',
          },
        },
      }));
    }
  };
  const getBannerHere = (data) => {
    if (!!data) {
      setFormData((prev) => ({
        ...prev,
        profile: {
          ...prev.profile,
          banner: {
            ...prev.profile?.banner,
            img: data,
          },
        },
      }));
    }
  };
  const videoOnBlur = () => {
    const currentLink = formData?.profile?.banner?.img;
    if (currentLink) {
      const allData = parseVideo(currentLink);
      if (allData?.provider !== 'youtube' && allData?.provider !== 'vimeo') {
        customToast({ type: 'error', msg: `URL is not valid` });

        setFormData((prev) => ({
          ...prev,
          profile: {
            ...prev?.profile,
            banner: { ...prev.profile?.banner, img: '' },
          },
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          profile: {
            ...prev?.profile,
            banner: { ...prev.profile.banner, img: allData?.rawLink, extension: allData?.provider },
          },
        }));
      }
    }
  };
  const handleChangeProfile = (e) => {
    const { name, value } = e.target;
    if (name === 'keywords') {
      setFormData((prev) => ({
        ...prev,
        profile: {
          ...prev.profile,
          keywords: {
            list: value ? value.split(',') : [''],
            value,
          },
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        profile: {
          ...prev.profile,
          [name]: value,
        },
      }));
    }
  };
  const validateProfileForm = async () =>
    new Promise((resolve, reject) => {
      const sendData = {
        showcaseRef: '',
        identifier: '',
        name: '',
        description: '',
        logo: '',
        banner: {
          source: '',
          type: '',
          extension: '',
        },
        hashtags: [],
      };
      if (formData?.showcaseID === '') {
        reject(new Error(`Showcase ID is required`));
        return false;
      }
      sendData.showcaseRef = formData?.showcaseID?.trim();

      if (formData?.profile?.identifier?.value?.trim() === '') {
        reject(new Error(`Username is required`));
        return false;
      }
      sendData.identifier = formData?.profile?.identifier?.value?.trim();

      if (formData?.profile?.title?.trim() === '') {
        reject(new Error(`Company Name is required`));
        return false;
      }
      sendData.name = formData?.profile?.title?.trim();

      sendData.description = formData?.profile?.description?.trim();

      if (formData?.profile?.profileImg?.img === '') {
        reject(new Error(`Company Logo is required`));
        return false;
      }
      sendData.logo = formData?.profile?.profileImg?.s3name?.trim();

      if (formData?.profile?.banner?.type === 'image' && formData?.profile?.banner?.img === '') {
        reject(new Error(`Company Banner Image is required`));
        return false;
      }
      if (formData?.profile?.banner?.type === 'video' && formData?.profile?.banner?.img === '') {
        reject(new Error(`Company Banner Link is required`));
        return false;
      }
      sendData.banner.source = formData?.profile?.banner?.s3Name?.trim();
      sendData.banner.type = formData?.profile?.banner?.type?.trim();
      sendData.banner.extension = formData?.profile?.banner?.extension?.trim();

      sendData.hashtags = formData?.profile?.keywords?.value?.split(',');

      resolve(sendData);
      return true;
    });
  async function uploadImageToS3Profile() {
    try {
      const sendData = {
        showcaseID: formData?.showcaseID,
        banner: formData?.profile?.banner?.s3Name?.split('/').pop() || '',
        bannerType: getExtension(formData?.profile?.banner?.img),
        logo: formData?.profile?.profileImg?.s3name?.split('/').pop() || '',
        logoType: getExtension(formData?.profile?.profileImg?.img),
      };
      const res = await apiMiddleWare({ url: '/showcase/get-s3-url', type: 'post', data: sendData });
      if (res?.data?.ok) {
        const dataHere = res?.data?.packet;

        sendData.logo = dataHere?.logo?.source;
        sendData.banner = dataHere?.banner?.source;

        // if (formData?.profile?.profileImg?.img.indexOf(S3BucketBaseUrl) === -1) {
        const logoBody = await getBlob(formData?.profile?.profileImg?.img);
        await fetch(dataHere?.logo?.uploadURL, {
          method: 'PUT',
          body: logoBody,
        });
        // }

        // if (
        //   formData?.profile?.banner?.img.indexOf(S3BucketBaseUrl) === -1 &&
        //   formData?.profile?.banner?.type === 'image'
        // ) {
        const bannerBody = await getBlob(formData?.profile?.banner?.img);
        await fetch(dataHere?.banner?.uploadURL, {
          method: 'PUT',
          body: bannerBody,
        });
        // }

        return { logo: sendData.logo, banner: sendData.banner };
      }
      return new Error(res?.data?.errors[0]?.message);
    } catch (err) {
      return false;
    }
  }

  // Resource Tab Function
  const addEditSelectResource = (e) => {
    const { name, value, checked } = e.target;
    let selectedList = [];

    if (name === 'gallery') {
      selectedList = formData?.resource?.gallerySelected || [];
      if (checked === true) {
        selectedList.push(value);
      } else {
        selectedList.splice(selectedList.indexOf(value), 1);
      }
      setFormData((prev) => ({
        ...prev,
        resource: {
          ...prev.resource,
          gallerySelected: selectedList,
        },
      }));
    } else if (name === 'doc') {
      selectedList = formData?.resource?.documentSelected || [];
      if (checked === true) {
        selectedList.push(value);
      } else {
        selectedList.splice(selectedList.indexOf(value), 1);
      }
      setFormData((prev) => ({
        ...prev,
        resource: {
          ...prev.resource,
          documentSelected: selectedList,
        },
      }));
    }
  };
  async function validateFormResource() {
    return new Promise((resolve, reject) => {
      if (addResourceModal?.data?.name === '') {
        reject(new Error(`Name is required`));
        return false;
      }
      if (addResourceModal?.data?.type === '') {
        reject(new Error(`Type is required`));
        return false;
      }
      if (addResourceModal?.subtype === 'gallery') {
        if (addResourceModal?.data?.type === 'image' && addResourceModal?.data?.src === '') {
          reject(new Error(`Image is required`));
          return false;
        }
        if (addResourceModal?.data?.type === 'video' && addResourceModal?.data?.src === '') {
          reject(new Error(`Video Link is required`));
          return false;
        }
      } else if (addResourceModal?.subtype === 'document') {
        if (!addResourceModal?.data?.file === '') {
          reject(new Error(`File is required`));
          return false;
        }
      }
      resolve();
      return true;
    });
  }
  const uploadImageResourceToS3 = async (sendType) => {
    try {
      const sendData = {
        resource: addResourceModal?.data?.imgName.split('/').pop() || '',
        resourceType: sendType === 'image' ? getExtension(addResourceModal?.data?.src) : addResourceModal?.data?.type,
      };
      const res = await apiMiddleWare({ url: '/company/get-s3-url', type: 'post', data: sendData });

      if (!res || !res?.data?.ok) {
        return new Error(res?.data?.errors[0]?.message || defaultError);
      }

      const dataHere = res?.data?.packet;

      sendData.resource = dataHere?.resource?.source;

      if (sendType === 'doc') {
        if (typeof addResourceModal?.data?.file !== 'string') {
          const formData = new FormData();
          formData.append('myFile', addResourceModal?.data?.file, addResourceModal?.data?.file.name);
          await fetch(dataHere?.resource?.uploadURL, {
            method: 'PUT',
            body: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
          });
        }
      } else if (sendType === 'image') {
        // if (addResourceModal?.data?.src?.indexOf(S3BucketBaseUrl) === -1) {
        const resourceImg = await getBlob(addResourceModal?.data?.src);
        await fetch(dataHere?.resource?.uploadURL, {
          method: 'PUT',
          body: resourceImg,
        });
        // }
      }

      return { resource: sendData.resource };
    } catch (err) {
      return false;
    }
  };

  // Product Tab Function
  const addEditSelectProduct = (e) => {
    const { name, value, checked } = e.target;
    const newAddProducts = formData?.product?.newAddProducts || [];
    const removedProducts = formData?.product?.removedProducts || [];
    const prevProducts = formData?.product?.prevProducts || [];

    let selectedList = [];
    selectedList = formData?.product?.listSelected || [];
    if (checked === true) {
      selectedList.push(value);
      if (prevProducts.indexOf(value) === -1) {
        newAddProducts.push(value);
      }
      const index = removedProducts.indexOf(value);
      if (index !== -1) {
        removedProducts.splice(removedProducts.indexOf(value), 1);
      }
    } else {
      selectedList.splice(selectedList.indexOf(value), 1);
      const index = newAddProducts.indexOf(value);
      if (index !== -1) {
        newAddProducts.splice(index, 1);
      }
      removedProducts.push(value);
    }
    setFormData((prev) => ({
      ...prev,
      product: {
        ...prev.product,
        listSelected: selectedList,
        newAddProducts,
        removedProducts,
      },
    }));
  };

  // CONTACT TAB FUNCTIONS
  const handleChangeContact = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      contact: {
        ...prev.contact,
        [name]: {
          value,
        },
      },
    }));
  };
  const addEditSelectContact = (e) => {
    const { name, value, checked } = e.target;
    let selectedList = [];

    selectedList = formData?.contact?.repSelected || [];
    if (checked === true) {
      selectedList.push(value);
    } else {
      selectedList.splice(selectedList.indexOf(value), 1);
    }
    setFormData((prev) => ({
      ...prev,
      contact: {
        ...prev.contact,
        repSelected: selectedList,
      },
    }));
  };

  // Social tab Function
  const handleChangeSocial = (e) => {
    const { name, value } = e.target;
    const { type } = e.target.dataset;
    if (type === 'socialMedia') {
      setFormData((prev) => ({
        ...prev,
        social: {
          ...prev.social,
          socialMedia: {
            ...prev.social.socialMedia,
            [name]: value,
          },
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        social: {
          ...prev.social,
          [name]: value,
        },
      }));
    }
  };

  // Representative ADD EDIT Modal Start
  const handleRepChange = (e) => {
    const { name, value } = e.target;
    let valueChange = value;
    if (name === 'phone') {
      valueChange = valueChange.replace(/[^0-9-]/g, '').replace(/(\..*?)\..*/g, '$1');
      if (valueChange.length >= 14) valueChange = valueChange.substring(0, 14);
    }
    setAddRepModal((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [name]: valueChange,
      },
    }));
  };
  const openRepModal = (type = 'add') => {
    setAddRepModal((prev) => ({
      ...prev,
      mode: type,
      modalOpen: true,
      data: {
        repID: '',
        name: '',
        designation: '',
        email: '',
        phone: '',
      },
    }));
  };
  const closeRepModal = (status = false) => {
    clearAddRepData();
    if (status === true) {
      setCallRepApi((prev) => !prev);
    }
  };
  const clearAddRepData = () => {
    setAddRepModal({
      mode: 'add',
      modalOpen: false,
      data: {
        repID: '',
        name: '',
        designation: '',
        email: '',
        phone: '',
      },
    });
  };
  const submitRepModal = async (e) => {
    e.preventDefault();

    try {
      await validateRepForm();
      const toastID = toast.loading('Please wait...');
      const sendData = {
        mode: addRepModal?.mode,
        repID: addRepModal?.data?.repID || '',
        name: addRepModal?.data?.name,
        email: addRepModal?.data?.email,
        phone: addRepModal?.data?.phone,
        designation: addRepModal?.data?.designation,
      };
      const res = await apiMiddleWare({ type: 'post', url: `/company/rep/add`, data: sendData });

      if (!res?.data?.ok) {
        customToast({
          updateId: toastID,
          type: 'update',
          msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
          updateType: 'error',
        });
        // setIsLoading(false);
        return;
      }
      const selectedList = [...formData?.contact?.repSelected];
      selectedList.push(res?.data?.packet?._id);
      setFormData((prev) => ({
        ...prev,
        contact: {
          ...prev.contact,
          repSelected: selectedList,
        },
      }));

      closeRepModal(true);
      customToast({
        updateId: toastID,
        type: 'update',
        msg: 'Representative Added Successfully',
        updateType: 'success',
      });
      // setIsLoading(false);
      return;
    } catch (err) {
      customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
    }
  };
  const validateRepForm = async () =>
    new Promise((resolve, reject) => {
      if (addRepModal?.data?.name === '') {
        reject(new Error(`Name is required`));
        return false;
      }
      if (addRepModal?.data?.designation === '') {
        reject(new Error(`Designation is required`));
        return false;
      }
      if (addRepModal?.data?.email === '') {
        reject(new Error(`Email is required`));
        return false;
      }
      if (addRepModal?.data?.phone === '') {
        reject(new Error(`Phone is required`));
        return false;
      }
      resolve();
      return true;
    });

  // Resource ADD EDIT Modal
  const handleResourceChange = (e) => {
    const { name, value } = e.target;
    if (name === 'src') {
      setAddResourceModal((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          [name]: value,
        },
      }));
    } else if (name === 'file') {
      const fileHere = e.target.files[0];
      if (fileHere) {
        setAddResourceModal((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            file: fileHere,
          },
        }));
      }
    } else {
      let newSrc = addResourceModal.data.src;
      if (name === 'type') {
        newSrc = '';
      }
      setAddResourceModal((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          src: newSrc,
          [name]: value,
        },
      }));
    }
  };
  function openResourceModal(type = 'add', subtype = 'gallery') {
    setAddResourceModal((prev) => ({
      ...prev,
      mode: type,
      subtype,
      data: {
        ...prev.data,
        type: subtype === 'gallery' ? 'image' : 'pdf',
        name: '',
        src: '',
        file: {},
      },
      modalOpen: true,
    }));
  }
  const closeResourceModal = (status) => {
    clearAddResourceData();
    if (status === true) setCallResourceApi((prev) => !prev);
  };
  function clearAddResourceData() {
    setAddResourceModal({
      mode: 'add',
      subtype: 'gallery',
      modalOpen: false,
      data: {
        id: '',
        name: '',
        type: 'image',
        src: '',
        imgName: '',
        videoProvider: '',
        file: {},
      },
    });
  }
  const submitResoruceModal = async () => {
    try {
      await validateFormResource();
      const id = toast.loading('Please wait...');

      let sendData = {
        title: addResourceModal?.data?.name.trim(),
        // type : ['video', 'image', 'doc']
        type: addResourceModal?.subtype === 'document' ? 'doc' : addResourceModal?.data?.type,
        // extension : ['doc', 'pdf', 'xls','xlsx', 'docx', 'jpg', 'jpeg', 'png', 'youtube', 'vimeo' ]
        extension: '',
        source: '',
      };

      if (sendData.type === 'doc') {
        sendData.extension = addResourceModal?.data?.type;
      }
      if (sendData.type === 'video') {
        sendData.extension = addResourceModal?.data?.videoProvider;
        sendData.source = addResourceModal?.data?.src;
      }
      if (sendData.type === 'image') {
        sendData.extension = getExtension(addResourceModal?.data?.src);
      }

      if (addResourceModal?.mode === 'add') {
        if (sendData.type === 'image' || sendData.type === 'doc') {
          //
          const data = await uploadImageResourceToS3(sendData.type);
          sendData = {
            ...sendData,
            source: data?.resource,
            // resourceID: res?.data?.packet?._id,
          };
        }
        const res = await apiMiddleWare({ type: 'post', url: `/company/resource/add`, data: sendData });
        if (!res?.data?.ok) {
          customToast({
            updateId: id,
            type: 'update',
            msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
            updateType: 'error',
          });
          return;
        }
        if (sendData.type === 'doc') {
          const selectedList = [...formData.resource?.documentSelected];
          selectedList.push(res?.data?.packet?._id);
          setFormData((prev) => ({
            ...prev,
            resource: {
              ...prev.resource,
              documentSelected: selectedList,
            },
          }));
        } else {
          const selectedList = [...formData.resource?.gallerySelected];
          selectedList.push(res?.data?.packet?._id);
          setFormData((prev) => ({
            ...prev,
            resource: {
              ...prev.resource,
              gallerySelected: selectedList,
            },
          }));
        }
        customToast({ updateId: id, type: 'update', msg: 'Resource Added Successfully', updateType: 'success' });
      }

      closeResourceModal(true);

      // return true;
    } catch (err) {
      customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
      // return false;
    }
  };
  const getResourceImageHere = (data) => {
    if (!!data) {
      setAddResourceModal((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          src: data,
        },
      }));
    }
  };
  const videoOnBlurResource = () => {
    const currentLink = addResourceModal?.data?.src;
    if (currentLink) {
      const allData = parseVideo(currentLink);
      if (allData?.provider !== 'youtube' && allData?.provider !== 'vimeo') {
        customToast({ type: 'error', msg: `URL is not valid` });
        setAddResourceModal((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            src: '',
          },
        }));
      } else {
        setAddResourceModal((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            videoProvider: allData?.provider,
            src: allData?.rawLink,
          },
        }));
      }
    }
  };

  // HELPER FUNCTION
  function getThumbnailLink(link) {
    const data = parseVideo(link);
    return data?.thumb;
  }

  return (
    <CommonModal
      customStyle={'medium-modal modal-header-blue extra-height '}
      open={open}
      title={`${mode === 'add' ? stepsOrder.indexOf(currentStep) + 1 : stepsOrder.indexOf(currentStep)}/${
        mode === 'add' ? stepsOrder.length : stepsOrder.length - 1
      } : ${stepList[currentStep]?.title}`}
      onClose={addEditClose}
      customBodyStyle={{ height: '69vh', backgroundColor: samaaroBgBlue }}
      body={
        <div className="p-3">
          {currentStep === 'showcaseType' && (
            <div className="d-flex justify-content-center align-items-center flex-column">
              <InputLabel className="mb-2">Start new template for your next event</InputLabel>
              <Button type="button" variant="contained" className="mb-3 px-4" onClick={saveStep()}>
                Create Template
              </Button>
              {templateList?.length > 0 && (
                <>
                  <span className="mb-3">OR</span>
                  <div className="w-100 bg-white p-3">
                    <InputLabel className="">Choose from existing and modify</InputLabel>
                    <div className="row  overflow-auto">
                      {templateList?.map((i, index) => (
                        <div
                          key={i?._id}
                          className="col-3 mt-3 showcase_max_ht cursor-pointer"
                          onClick={saveStep({ data: { id: i?._id } })}
                        >
                          <div
                            className="d-flex p-0 flex-column justify-content-center align-items-center border-custom bg-white "
                            // onClick={() => openProduct(i?._id)}
                          >
                            <div className="display-3 position-relative w-100 h-100 justify-content-center align-items-center d-flex">
                              {/* <Iconify icon="material-symbols:hide-image-outline-rounded" width="10" /> */}

                              {i?.logo ? (
                                <img src={`${S3BucketBaseUrl}${i?.logo}`} alt="" />
                              ) : (
                                <img src={`${S3BucketBaseUrl}${defaultLogoImg}`} alt="" />
                              )}
                            </div>
                            <div
                              className="title__box p-2 bg-light font-body-custom cursor-pointer "
                              style={{ height: '40px' }}
                            >
                              <span className={smUp ? 'w-100' : 'w-150px'}>
                                <Tooltip
                                  title={i?.name}
                                  placement={'bottom-start'}
                                  className=""
                                  // onClick={() => i?.designation && copyText(i?.designation, 'Designation Copied')}
                                >
                                  <div className="ellipse-text">{i?.name}</div>
                                </Tooltip>
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {currentStep === 'profile' && (
            <Grid container spacing={3}>
              <Grid item xs={12} className="side-by-side">
                <Grid item xs={12} md={5} sx={{ flexDirection: 'column' }}>
                  <InputLabel>Username</InputLabel>
                  <span className="note-text">Only Lowercase Letter, Numbers, Hyphen(-) allowed</span>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    // sx={{ width: '50%' }}
                    className="fullWidth-responsive"
                    fullWidth
                    name="identifier"
                    value={formData?.profile?.identifier?.value}
                    // error={identifier?.error === true}
                    onChange={handleIdentifierChange}
                    // onBlur={handleIdentifierBlur}
                    inputProps={{ maxLength: 50 }}
                    required
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} className="side-by-side">
                <Grid item xs={12} md={5}>
                  <InputLabel>Company Name</InputLabel>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    // sx={{ width: '50%' }}
                    className="fullWidth-responsive"
                    fullWidth
                    name="title"
                    value={formData?.profile?.title}
                    onChange={handleChangeProfile}
                    inputProps={{ maxLength: 50 }}
                    required
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} className="side-by-side">
                <Grid item xs={12} md={5}>
                  <InputLabel>Company Logo</InputLabel>
                </Grid>
                <Grid item xs={12} md={7} style={{ alignItems: 'center', flexWrap: 'wrap' }}>
                  {!!formData?.profile?.profileImg?.img && (
                    <img
                      src={formData?.profile?.profileImg?.img}
                      alt="Logo"
                      height={150}
                      // onError={handleImgErrorLogo}
                      style={{ marginRight: '2rem' }}
                    />
                  )}
                  <div className="mt-3 mt-md-0">
                    <ImageUpload
                      title="Upload Logo"
                      onClose={(data) => getLogoHere(data)}
                      previousImgSrc={formData?.profile?.profileImg?.img}
                      propAspect={1}
                      propHeight={400}
                      propWidth={400}
                      propSize={2}
                    />
                    {!!formData?.profile?.profileImg?.img && (
                      <Button
                        type="button"
                        variant="contained"
                        color="error"
                        sx={{ ml: 2 }}
                        onClick={() => clearImg('logo')}
                      >
                        Clear Image
                      </Button>
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid item xs={12} className="side-by-side">
                <Grid item xs={12} md={5}>
                  <InputLabel>Company Banner Type</InputLabel>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    // fullWidth
                    sx={{ width: '50%', textAlign: 'left', backgroundColor: 'white' }}
                    className="fullWidth-responsive"
                    name="type"
                    id="outlined-select-currency"
                    select
                    // label="Search Time"
                    value={formData?.profile?.banner?.type}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        profile: {
                          ...prev?.profile,
                          banner: {
                            ...prev?.profile.banner,
                            value: '',
                            type: e.target.value,
                            extension: e.target.value === 'image' ? 'png' : '',
                            name: e.target.value === 'image' ? prev?.showcaseBanner?.name : '',
                          },
                        },
                      }))
                    }
                    // required
                  >
                    {['image', 'video'].map((i) => (
                      <MenuItem value={i} key={i}>
                        {i.charAt(0).toUpperCase() + i.substring(1)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid item xs={12} className="side-by-side">
                <Grid item xs={12} md={5}>
                  <InputLabel>Company Banner</InputLabel>
                </Grid>
                <Grid item xs={12} md={7} style={{ alignItems: 'center', flexGrid: 'flex', flexWrap: 'wrap' }}>
                  {formData?.profile?.banner?.type === 'image' ? (
                    <>
                      {!!formData?.profile?.banner?.img && (
                        <img
                          src={formData?.profile?.banner?.img}
                          alt="Banner"
                          height={150}
                          // onError={handleImgErrorBanner}
                          style={{ marginRight: '2rem' }}
                        />
                      )}
                      <div className="mt-3 mt-md-0">
                        <ImageUpload
                          title="Upload Banner"
                          onClose={(data) => getBannerHere(data)}
                          previousImgSrc={formData?.profile?.banner?.img}
                          propAspect={16 / 9}
                          propHeight={1080}
                          propWidth={1920}
                          propSize={5}
                        />
                        {!!formData?.profile?.banner?.img && (
                          <Button
                            type="button"
                            variant="contained"
                            color="error"
                            sx={{ ml: 2 }}
                            onClick={() => clearImg('banner')}
                          >
                            Clear Image
                          </Button>
                        )}
                      </div>
                    </>
                  ) : (
                    formData?.profile?.banner?.type === 'video' && (
                      <TextField
                        // sx={{ width: '70%' }}
                        fullWidth
                        className="fullWidth-responsive"
                        name="banner"
                        value={formData?.profile?.banner?.img}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            profile: {
                              ...prev?.profile,
                              banner: {
                                ...prev.profile.banner,
                                img: e.target.value,
                              },
                            },
                          }))
                        }
                        onBlur={videoOnBlur}
                        // required
                      />
                    )
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12} className="side-by-side">
                <Grid item xs={12} md={5} sx={{ flexDirection: 'column' }}>
                  <InputLabel>Keywords</InputLabel>
                  <span className="note-text">Include comma(,) between each word</span>
                </Grid>
                <Grid item xs={12} md={7} sx={{ flexDirection: 'column' }}>
                  <TextField
                    // sx={{ width: '70%' }}
                    fullWidth
                    className="fullWidth-responsive"
                    name="keywords"
                    value={formData?.profile?.keywords?.value}
                    onChange={handleChangeProfile}
                    // onBlur={onBlurHashtag}
                    // required
                  />
                  {formData?.profile?.keywords?.list?.filter((i) => i)?.length >= 1 && (
                    <div className="d-flex flex-wrap" style={{ marginTop: '10px' }}>
                      {formData?.profile?.keywords?.list?.map((i, index) => (
                        <span key={`${i}-${index}`} className="hashtag-box">
                          {i}
                        </span>
                      ))}
                    </div>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12} className="side-by-side ">
                <Grid item xs={12} md={5}>
                  <InputLabel>Company Overview</InputLabel>
                </Grid>
                <Grid item xs={12} md={7}>
                  <CKEditor
                    editor={ClassicEditor}
                    style={{ width: '100%' }}
                    config={ckEditorConfig}
                    data={formData?.profile?.description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setFormData((prev) => ({
                        ...prev,
                        profile: {
                          ...prev.profile,
                          description: data,
                        },
                      }));
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          {currentStep === 'resource' && (
            <>
              <CommonModal
                title={`${addResourceModal?.mode === 'add' ? 'Add' : 'Edit'} ${
                  addResourceModal.subtype === 'gallery' ? 'Media Content' : 'Document'
                }`}
                open={addResourceModal?.modalOpen}
                onClose={closeResourceModal}
                onSubmit={submitResoruceModal}
                body={
                  <>
                    <div className="p-3">
                      <Grid container spacing={3}>
                        <Grid item xs={12} className="side-by-side">
                          <Grid item xs={12} md={4}>
                            <InputLabel>Content Name</InputLabel>
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <TextField
                              sx={{ width: '70%' }}
                              className="fullWidth-responsive"
                              // fullWidth
                              name="name"
                              value={addResourceModal?.data?.name}
                              onChange={handleResourceChange}
                              // inputProps={{ maxLength: 30 }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} className="side-by-side">
                          <Grid item xs={12} md={4}>
                            <InputLabel>Content Type</InputLabel>
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <TextField
                              // fullWidth
                              sx={{ width: '50%', textAlign: 'left' }}
                              className="fullWidth-responsive"
                              name="type"
                              id="outlined-select-currency"
                              select
                              disabled={addResourceModal?.mode === 'edit'}
                              // label="Search Time"
                              value={addResourceModal?.data?.type}
                              onChange={handleResourceChange}
                              // required
                            >
                              {addResourceModal?.subtype === 'gallery'
                                ? ['image', 'video'].map((i) => (
                                    <MenuItem value={i} key={i}>
                                      {i.charAt(0).toUpperCase() + i.substring(1)}
                                    </MenuItem>
                                  ))
                                : ['pdf', 'xls', 'xlsx', 'doc', 'docx'].map((i) => (
                                    <MenuItem value={i} key={i}>
                                      {i.toUpperCase()}
                                    </MenuItem>
                                  ))}
                            </TextField>
                          </Grid>
                        </Grid>
                        {addResourceModal?.data?.type === 'image' && (
                          <Grid item xs={12} className="side-by-side">
                            <Grid item xs={12} md={4}>
                              <InputLabel>Upload Image</InputLabel>
                            </Grid>
                            <Grid item xs={12} md={8} style={{ alignItems: 'center' }}>
                              {!!addResourceModal?.data?.src && (
                                <img
                                  src={addResourceModal?.data?.src}
                                  alt="gallery content"
                                  height={120}
                                  style={{ marginRight: '2rem' }}
                                />
                              )}
                              <ImageUpload
                                title="Upload Image"
                                previousImgSrc={addResourceModal?.data?.src || ''}
                                onClose={(data) => getResourceImageHere(data)}
                                propSize={2}
                              />
                            </Grid>
                          </Grid>
                        )}
                        {addResourceModal?.data?.type === 'video' && (
                          <Grid item xs={12} className="side-by-side">
                            <Grid item xs={12} md={4} sx={{ flexDirection: 'column' }}>
                              <InputLabel>Video Link</InputLabel>
                              <span className="note-text">Only Youtube/Vimeo Link</span>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <TextField
                                // sx={{ width: '10%' }}
                                fullWidth
                                name="src"
                                value={addResourceModal?.data?.src}
                                onChange={handleResourceChange}
                                onBlur={videoOnBlurResource}
                                // inputProps={{ maxLength: 30 }}
                              />
                            </Grid>
                          </Grid>
                        )}
                        {addResourceModal?.data?.type !== 'image' && addResourceModal?.data?.type !== 'video' && (
                          <Grid item xs={12} className="side-by-side">
                            <Grid item xs={12} md={4}>
                              <InputLabel>Upload File</InputLabel>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              {addResourceModal?.data?.type === 'pdf' && (
                                <Input
                                  type="file"
                                  name="file"
                                  onChange={handleResourceChange}
                                  inputProps={{ accept: 'application/pdf' }}
                                />
                              )}
                              {addResourceModal?.data?.type === 'xls' && (
                                <Input
                                  type="file"
                                  name="file"
                                  onChange={handleResourceChange}
                                  inputProps={{
                                    accept: 'application/vnd.ms-excel',
                                  }}
                                />
                              )}
                              {addResourceModal?.data?.type === 'xlsx' && (
                                <Input
                                  type="file"
                                  name="file"
                                  onChange={handleResourceChange}
                                  inputProps={{
                                    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                  }}
                                />
                              )}

                              {addResourceModal?.data?.type === 'doc' && (
                                <Input
                                  type="file"
                                  name="file"
                                  onChange={handleResourceChange}
                                  inputProps={{ accept: 'application/msword' }}
                                />
                              )}
                              {addResourceModal?.data?.type === 'docx' && (
                                <Input
                                  type="file"
                                  name="file"
                                  onChange={handleResourceChange}
                                  inputProps={{
                                    accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                  }}
                                />
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  </>
                }
              />
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<Iconify icon="akar-icons:chevron-down" />}>
                  <Typography variant="h4" sx={{ m: 1 }}>
                    Media
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sx={{ justifyContent: 'end', display: 'flex' }}>
                        <Button
                          type="Button"
                          variant="contained"
                          onClick={() => openResourceModal('add', 'gallery')}
                          sx={{}}
                        >
                          Add Image/Video
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: smUp ? 650 : 'auto' }} aria-label="simple table">
                            <TableHead
                              sx={{
                                '& .MuiTableCell-head': {
                                  color: 'white',
                                  backgroundColor: wocOrange,
                                  // textAlign: 'center',
                                },
                              }}
                            >
                              <TableRow>
                                <TableCell align="left">Index</TableCell>
                                <TableCell align="center">Preview</TableCell>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="right">Type</TableCell>
                                <TableCell align="right">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {formData?.resource?.gallery?.length > 0 &&
                                formData?.resource?.gallery?.map((row, index) => (
                                  <TableRow
                                    key={row._id}
                                    sx={{
                                      '&:last-child td, &:last-child th': { border: 0 },
                                      '&:nth-child(odd)': {
                                        backgroundColor: tableBgColor,
                                      },
                                      '&:nth-child(even)': {
                                        backgroundColor: 'white',
                                      },
                                    }}
                                  >
                                    <TableCell align="left">{index + 1}</TableCell>
                                    <TableCell align="center">
                                      <div className="table-img">
                                        {row.type === 'image' ? (
                                          <img src={`${S3BucketBaseUrl}${row.source}`} alt="" />
                                        ) : (
                                          <img src={getThumbnailLink(row.source)} alt="" />
                                        )}
                                      </div>
                                    </TableCell>
                                    <TableCell align="center">{row.title}</TableCell>
                                    <TableCell align="right">
                                      {row.type.charAt(0).toUpperCase() + row.type.substring(1)}
                                    </TableCell>
                                    <TableCell align="right">
                                      <Checkbox
                                        onClick={addEditSelectResource}
                                        name={'gallery'}
                                        value={row?._id}
                                        checked={formData?.resource?.gallerySelected?.includes(row?._id)}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              {formData?.resource?.gallery?.length === 0 && (
                                <TableRow>
                                  <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                                    No Media Item Found
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<Iconify icon="akar-icons:chevron-down" />}>
                  <Typography variant="h4" sx={{ m: 1 }}>
                    Document
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <input type="file" name="upload" accept="application/pdf , application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" /> */}
                  {/*  will support .pdf, .xls, .doc, .docx */}

                  <Box>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sx={{ justifyContent: 'end', display: 'flex' }}>
                        <Button type="Button" variant="contained" onClick={() => openResourceModal('add', 'document')}>
                          Add Document
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: smUp ? 650 : 'auto' }} aria-label="simple table">
                            <TableHead
                              sx={{
                                '& .MuiTableCell-head': {
                                  color: 'white',
                                  backgroundColor: wocOrange,
                                  // textAlign: 'center',
                                },
                              }}
                            >
                              <TableRow>
                                <TableCell align="left">Index</TableCell>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="right">Type</TableCell>
                                <TableCell align="right">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {formData?.resource?.document?.length > 0 &&
                                formData?.resource?.document?.map((row, index) => (
                                  <TableRow
                                    key={row?._id}
                                    sx={{
                                      '&:last-child td, &:last-child th': { border: 0 },
                                      '&:nth-child(odd)': {
                                        backgroundColor: tableBgColor,
                                      },
                                      '&:nth-child(even)': {
                                        backgroundColor: 'white',
                                      },
                                    }}
                                  >
                                    <TableCell align="left">{index + 1}</TableCell>
                                    <TableCell align="center">{row?.title}</TableCell>
                                    <TableCell align="right">{row?.extension.toUpperCase()}</TableCell>
                                    <TableCell align="right">
                                      <Checkbox
                                        onClick={addEditSelectResource}
                                        name={'doc'}
                                        value={row?._id}
                                        checked={formData?.resource?.documentSelected?.includes(row?._id)}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              {formData?.resource?.document?.length === 0 && (
                                <TableRow>
                                  <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                                    No Document Found
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </>
          )}

          {currentStep === 'product' && (
            <>
              <div className="row">
                <div className="col-12 d-flex justify-content-end mb-3">
                  <Button
                    type="Button"
                    variant="contained"
                    color="success"
                    className="text-white me-3"
                    onClick={() => setCallProductApi((prev) => !prev)}
                    sx={{}}
                  >
                    <Iconify icon="tabler:reload" />
                  </Button>
                  <Button type="Button" variant="contained" onClick={openInNewTab('/user/showcase/product')} sx={{}}>
                    Add Product
                  </Button>
                </div>
                {formData?.product?.list?.length > 0 &&
                  formData?.product?.list?.map((i, index) => (
                    <div key={index} className="col-6 col-sm-4 col-md-3 mb-3 product_max_ht">
                      <div
                        className="d-flex p-0 flex-column justify-content-center align-items-center  border-custom bg-white "
                        // onClick={() => openProduct(i?._id)}
                      >
                        <div className="display-3 position-relative w-100 h-100 justify-content-center align-items-center d-flex">
                          {/* <Iconify icon="material-symbols:hide-image-outline-rounded" width="10" /> */}

                          {i?.thumbnail ? (
                            <img src={`${S3BucketBaseUrl}${i?.thumbnail}`} alt="" className='aspect-ratio-1 background-white'/>
                          ) : (
                            <img src={`${S3BucketBaseUrl}${defaultProductImg}`} alt="" className='aspect-ratio-1 background-white'/>
                          )}
                          <span
                            className=" position-absolute d-flex scale-like"
                            style={{ right: '5px', top: '5px' }}
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          >
                            <Checkbox
                              onClick={addEditSelectProduct}
                              name={'product'}
                              value={i?._id}
                              checked={formData?.product?.listSelected?.includes(i?._id)}
                            />
                          </span>
                        </div>
                        <div
                          className="title__box p-2 bg-light font-body-custom cursor-pointer "
                          style={{ height: '40px' }}
                        >
                          <span className={smUp ? 'w-150px' : 'w-80px'}>
                            <Tooltip
                              title={i?.title}
                              placement={'bottom-start'}
                              className=""
                              // onClick={() => i?.designation && copyText(i?.designation, 'Designation Copied')}
                            >
                              <div className="ellipse-text">{i?.title}</div>
                            </Tooltip>
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}

          {currentStep === 'contact' && (
            <>
              <CommonModal
                title={`${addRepModal?.mode === 'add' ? 'Add' : 'Edit'} Representative`}
                open={addRepModal?.modalOpen}
                onClose={closeRepModal}
                onSubmit={submitRepModal}
                body={
                  <>
                    <div className="p-3">
                      <Grid container spacing={3}>
                        <Grid item xs={12} className="side-by-side">
                          <Grid item xs={12} md={4}>
                            <InputLabel>Name</InputLabel>
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <TextField
                              // sx={{ width: '80%' }}
                              className="fullWidth-responsive"
                              fullWidth
                              name="name"
                              value={addRepModal?.data?.name}
                              onChange={handleRepChange}
                              inputProps={{ maxLength: 100 }}
                              required
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} className="side-by-side">
                          <Grid item xs={12} md={4}>
                            <InputLabel>Designation</InputLabel>
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <TextField
                              // sx={{ width: '80%' }}
                              className="fullWidth-responsive"
                              fullWidth
                              name="designation"
                              value={addRepModal?.data?.designation}
                              onChange={handleRepChange}
                              inputProps={{ maxLength: 100 }}
                              // required
                            />
                          </Grid>
                        </Grid>

                        <Grid item xs={12} className="side-by-side">
                          <Grid item xs={12} md={4}>
                            <InputLabel>Email</InputLabel>
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <TextField
                              // sx={{ width: '80%' }}
                              className="fullWidth-responsive"
                              fullWidth
                              name="email"
                              value={addRepModal?.data?.email}
                              onChange={handleRepChange}
                              inputProps={{ maxLength: 100 }}
                              // required
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} className="side-by-side">
                          <Grid item xs={12} md={4}>
                            <InputLabel>Phone</InputLabel>
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <TextField
                              // sx={{ width: '80%' }}
                              className="fullWidth-responsive"
                              fullWidth
                              name="phone"
                              value={addRepModal?.data?.phone}
                              onChange={handleRepChange}
                              inputProps={{ maxLength: 10 }}
                              // required
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </>
                }
              />
              <Grid conatainer className="contact-page px-0">
                <Grid item xs={12} className="mb-3">
                  <Typography variant="h6">Meeting Scheduler</Typography>
                  <Box sx={{ ...commonBoxStyles }}>
                    <Grid container>
                      <Grid item xs={12} className="side-by-side">
                        <Grid item xs={12} md={4} lg={5}>
                          <InputLabel>Calendly Link</InputLabel>
                        </Grid>
                        <Grid item xs={12} md={8} lg={7}>
                          <TextField
                            // sx={{ width: '80%' }}
                            className="fullWidth-responsive"
                            fullWidth
                            name="calendlyLink"
                            value={formData?.contact?.calendlyLink?.value}
                            onChange={handleChangeContact}
                            required
                          />
                          {/* <TextField
                            // fullWidth
                            sx={{ width: '100%', textAlign: 'left', backgroundColor: 'white' }}
                            className="fullWidth-responsive"
                            name="calendlyLink"
                            id="outlined-select-currency"
                            select
                            // label="Search Time"
                            value={formData?.contact?.calendlyLink?.value}
                            onChange={handleChangeContact}
                            // required
                          >
                            <MenuItem value={''}>--Select--</MenuItem>
                            {formData?.contact?.calendlyLink?.list.length > 0 &&
                              formData?.contact?.calendlyLink?.list?.map((i) => (
                                <MenuItem value={i} key={i}>
                                  {i.charAt(0).toUpperCase() + i.substring(1)}
                                </MenuItem>
                              ))}
                          </TextField> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} className="mb-3">
                  <div className="d-flex">
                    <Typography variant="h6">Representative</Typography>
                    <div className="ms-auto">
                      <Tooltip
                        title={'Click on the fields to copy them'}
                        placement="left-start"
                        className="cursor-pointer my-auto fs-2"
                      >
                        <Iconify icon="material-symbols:info-outline-rounded" width="10" />
                      </Tooltip>
                    </div>
                  </div>
                  <Box sx={{ ...commonBoxStyles }}>
                    <Grid item xs={12} sx={{ justifyContent: 'end', display: 'flex' }} className="mb-3">
                      <Button type="Button" variant="contained" onClick={() => openRepModal('add')} sx={{}}>
                        Add Representative
                      </Button>
                    </Grid>
                    <div className="row">
                      {formData?.contact?.rep?.map((i) => (
                        <div className="col-6 col-lg-3  mb-3" key={i?._id}>
                          <div className=" border border_radius_8px p-3 position-relative">
                            <span
                              className=" position-absolute d-flex scale-like"
                              style={{ right: '5px', top: '5px' }}
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                            >
                              <Checkbox
                                onClick={addEditSelectContact}
                                name={'contact'}
                                value={i?._id}
                                checked={formData?.contact?.repSelected?.includes(i?._id) || false}
                              />
                            </span>
                            <div className="d-flex justify-content-center mb-3">
                              <img
                                src={'/assets/icons/avatar/character-icon.png'}
                                alt=""
                                className="border_radius_100"
                              />
                            </div>
                            <div>
                              <Tooltip title={i?.name} placement="bottom">
                                <div className="ellipse-text cursor-pointer text-center fw-bold fs-6">
                                  {i?.name || ''}
                                </div>
                              </Tooltip>
                            </div>
                            <div>
                              <Tooltip title={i?.designation} placement="bottom">
                                <div className="ellipse-text cursor-pointer text-center fw-light font-body-14">
                                  {i?.designation || ''}
                                </div>
                              </Tooltip>
                            </div>
                            <div>
                              <Tooltip title={i?.email} placement="bottom">
                                <div className="ellipse-text cursor-pointer text-center fw-light font-body-14">
                                  {i?.email || ''}
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}

          {currentStep === 'social' && (
            <>
              <Grid container className="contact-page px-0">
                <Grid item xs={12} className="mb-3">
                  <Typography variant="h6">Contact Info</Typography>

                  <Box sx={{ ...commonBoxStyles }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} className="side-by-side">
                        <Grid item xs={12} md={5} sx={{ flexDirection: 'column' }}>
                          <InputLabel>Website</InputLabel>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <TextField
                            // sx={{ width: '50%' }}
                            className="fullWidth-responsive"
                            fullWidth
                            name="website"
                            value={formData?.social?.website}
                            // error={identifier?.error === true}
                            onChange={handleChangeSocial}
                            // onBlur={handleIdentifierBlur}
                            required
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} className="side-by-side">
                        <Grid item xs={12} md={5} sx={{ flexDirection: 'column' }}>
                          <InputLabel>Email</InputLabel>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <TextField
                            // sx={{ width: '50%' }}
                            className="fullWidth-responsive"
                            fullWidth
                            name="email"
                            type="email"
                            value={formData?.social?.email}
                            // error={identifier?.error === true}
                            onChange={handleChangeSocial}
                            // onBlur={handleIdentifierBlur}
                            required
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} className="side-by-side">
                        <Grid item xs={12} md={5} sx={{ flexDirection: 'column' }}>
                          <InputLabel>Phone</InputLabel>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <TextField
                            // sx={{ width: '50%' }}
                            className="fullWidth-responsive"
                            fullWidth
                            name="phone"
                            value={formData?.social?.phone}
                            // error={identifier?.error === true}
                            onChange={handleChangeSocial}
                            // onBlur={handleIdentifierBlur}
                            required
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} className="side-by-side">
                        <Grid item xs={12} md={5} sx={{ flexDirection: 'column' }}>
                          <InputLabel>Address</InputLabel>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <TextField
                            // sx={{ width: '50%' }}
                            className="fullWidth-responsive"
                            fullWidth
                            name="address"
                            value={formData?.social?.address}
                            // error={identifier?.error === true}
                            onChange={handleChangeSocial}
                            // onBlur={handleIdentifierBlur}
                            inputProps={{ maxLength: 50 }}
                            required
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>

              <Grid container className="contact-page px-0">
                <Grid item xs={12} className="mb-3">
                  <Typography variant="h6">Social Media</Typography>

                  <Box sx={{ ...commonBoxStyles }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} className="side-by-side">
                        <Grid item xs={12} md={5} sx={{ flexDirection: 'column' }}>
                          <InputLabel>Facebook</InputLabel>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <TextField
                            // sx={{ width: '50%' }}
                            className="fullWidth-responsive"
                            fullWidth
                            name="facebook"
                            value={formData?.social?.socialMedia?.facebook}
                            // error={identifier?.error === true}
                            onChange={handleChangeSocial}
                            // onBlur={handleIdentifierBlur}
                            inputProps={{ maxLength: 50, 'data-type': 'socialMedia' }}
                            required
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} className="side-by-side">
                        <Grid item xs={12} md={5} sx={{ flexDirection: 'column' }}>
                          <InputLabel>Instagram</InputLabel>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <TextField
                            // sx={{ width: '50%' }}
                            className="fullWidth-responsive"
                            fullWidth
                            name="instagram"
                            value={formData?.social?.socialMedia?.instagram}
                            // error={identifier?.error === true}
                            onChange={handleChangeSocial}
                            // onBlur={handleIdentifierBlur}
                            inputProps={{ maxLength: 50, 'data-type': 'socialMedia' }}
                            required
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} className="side-by-side">
                        <Grid item xs={12} md={5} sx={{ flexDirection: 'column' }}>
                          <InputLabel>LinkedIn</InputLabel>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <TextField
                            // sx={{ width: '50%' }}
                            className="fullWidth-responsive"
                            fullWidth
                            name="linkedin"
                            value={formData?.social?.socialMedia?.linkedin}
                            // error={identifier?.error === true}
                            onChange={handleChangeSocial}
                            // onBlur={handleIdentifierBlur}
                            inputProps={{ maxLength: 50, 'data-type': 'socialMedia' }}
                            required
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} className="side-by-side">
                        <Grid item xs={12} md={5} sx={{ flexDirection: 'column' }}>
                          <InputLabel>Twitter</InputLabel>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <TextField
                            // sx={{ width: '50%' }}
                            className="fullWidth-responsive"
                            fullWidth
                            name="twitter"
                            value={formData?.social?.socialMedia?.twitter}
                            // error={identifier?.error === true}
                            onChange={handleChangeSocial}
                            // onBlur={handleIdentifierBlur}
                            inputProps={{ maxLength: 50, 'data-type': 'socialMedia' }}
                            required
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} className="side-by-side">
                        <Grid item xs={12} md={5} sx={{ flexDirection: 'column' }}>
                          <InputLabel>Pinterest</InputLabel>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <TextField
                            // sx={{ width: '50%' }}
                            className="fullWidth-responsive"
                            fullWidth
                            name="pinterest"
                            value={formData?.social?.socialMedia?.pinterest}
                            // error={identifier?.error === true}
                            onChange={handleChangeSocial}
                            // onBlur={handleIdentifierBlur}
                            inputProps={{ maxLength: 50, 'data-type': 'socialMedia' }}
                            required
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} className="side-by-side">
                        <Grid item xs={12} md={5} sx={{ flexDirection: 'column' }}>
                          <InputLabel>Youtube</InputLabel>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <TextField
                            // sx={{ width: '50%' }}
                            className="fullWidth-responsive"
                            fullWidth
                            name="youtube"
                            value={formData?.social?.socialMedia?.youtube}
                            // error={identifier?.error === true}
                            onChange={handleChangeSocial}
                            // onBlur={handleIdentifierBlur}
                            inputProps={{ maxLength: 50, 'data-type': 'socialMedia' }}
                            required
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      }
      customFooter={
        <div className="d-flex justify-content-between align-items-center w-100">
          <div>
            {/* {currentStep !== 'showcaseType' && (
              <Button type="button" onClick={resetForm} variant="text" className="">
                Reset
              </Button>
            )} */}
          </div>
          <div>
            {currentStep === 'showcaseType' ? (
              <div>
                <Button type="button" onClick={addEditClose} variant="outlined" className="">
                  Close
                </Button>
              </div>
            ) : (
              <>
                {currentStep !== stepsOrder[0] && currentStep !== stepsOrder[1] && (
                  <Button type="button" onClick={backButton} variant="outlined" className=" me-3 ">
                    Back
                  </Button>
                )}
                <Button type="button" onClick={saveStep()} variant="contained" className="">
                  {stepsOrder[stepsOrder.length - 1] === currentStep ? 'Save' : 'Save & Next'}
                </Button>
              </>
            )}
          </div>
        </div>
      }
    />
  );
}

export default AddEditUserTemplate;
