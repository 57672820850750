import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { Form, InputGroup } from 'react-bootstrap';

import Loader from '../../../components/loader';
import Iconify from '../../../components/iconify/Iconify';
import customToast from '../../../utils/customToast';
import { defaultError, wocOrange } from '../../../utils/constants';
import { handleDownload } from '../../../utils/helper';
import apiMiddleWare from '../../../utils/useApiMiddleWare';
import useLocalStorage from '../../../hooks/useLocalStorage';


function UserMeetings() {
  const [isLoading, setIsLoading] = useState(false);
  const [storeData, setStoreData] = useState({ data: [], copy: [], searchV: '', called: false, loading: false });
  const filterDataLocal = useLocalStorage('filterData');
  const [filterData, setFilterData] = useState({
    showcaseID: filterDataLocal?.showcaseID || '',
    searchTime: filterDataLocal?.searchTime || 'last7',
    startDate: filterDataLocal?.startDate || '',
    endDate: filterDataLocal?.endDate || '',
  });

  useEffect( () => { 
    getListing()
  }, [ ])

  const getListing = async() => { 
    try{ 
      const sendData = {
        showcaseRef:filterData?.showcaseID,
        page: 1,
        limit: 20,
        search: storeData?.searchV,
      };

      const res = await apiMiddleWare({ type: 'post', url: '/showcase/meeting-list', data: sendData });
      if (!res) {
        return false;
      }
      if (!res?.data?.ok) {
        customToast({
          msg: res?.data?.errors?.[0]?.message || res?.data?.errors?.[0]?.msg || defaultError,
          type: 'error',
        });
        return false;
      }

      // customToast({ msg: 'Data Updated Successfully', type: 'success' });
      // setCallApi((prev) => !prev);
      return true;
    } catch (err) {
      console.log(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }

  }

  const filterTable = (e) => {
    const searchValue = e.target.value?.toLowerCase();
    const originalData = storeData?.copy;
    let filteredData = [];

    filteredData = originalData.filter(
      (i) =>
        i?.visitorRef?.email?.toLowerCase()?.indexOf(searchValue) !== -1 ||
        i?.visitorRef?.profile?.fullName?.toLowerCase()?.indexOf(searchValue) !== -1 ||
        i?.visitorRef?.profile?.phone?.toString()?.indexOf(searchValue) !== -1 ||
        i?.visitorRef?.profile?.designation?.toLowerCase()?.indexOf(searchValue) !== -1 ||
        i?.visitorRef?.profile?.company?.toLowerCase()?.indexOf(searchValue) !== -1
    );

    setStoreData((prev) => ({
      ...prev,
      searchV: searchValue,
      data: filteredData,
    }));
  };

  const callDownload = async () => {
    try {
      let data = storeData?.copy;

      if (!data || data.length === 0) {
        customToast({ msg: 'No Data Found', type: 'error' });
        return false;
      }
      customToast({ type: 'success', msg: 'Download will start in few seconds' });

      data = data.flatMap((obj) =>
        obj.review.map((review) => ({
          showcaseRef: obj.showcaseRef,
          productRef: obj.productRef,
          companyRef: obj.companyRef,
          ...review,
        }))
      );

      const dataOrder = ['index', 'productName', 'name', 'email', 'subject', 'description', 'rating'];
      const dataObj = {
        index: { label: '#', valueLocation: 'index' },
        productName: { label: 'Product Name', valueLocation: 'productRef.title' },
        visitor: { label: 'Visitor', valueLocation: 'review', format: (data) => JSON.stringify(data) },
        name: { label: 'Name', valueLocation: 'visitorRef.profile.fullName' },
        email: { label: 'Email', valueLocation: 'visitorRef.email' },
        subject: { label: 'Subject', valueLocation: 'subject' },
        phone: { label: 'Phone Number', valueLocation: 'visitorRef.profile.phone' },
        company: { label: 'Company', valueLocation: 'visitorRef.profile.company' },
        designation: { label: 'Designation', valueLocation: 'visitorRef.profile.designation' },
        description: { label: 'Description', valueLocation: 'description' },
        rating: { label: 'Rating', valueLocation: 'rating' },
      };

      await handleDownload({ fileName: `meeting-data`, dataOrder, dataObj, data });

      return true;
    } catch (err) {
      console.error(err);
      customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const TableHeadCustom = styled(TableHead)({
    '& .MuiTableCell-head': {
      color: 'white',
      backgroundColor: wocOrange,
      // textAlign: 'center',
    },
  });

  return (
    <>
      <Container maxWidth="xl" className="mt-4 mt-md-0">
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Meetings
          </Typography>
        </Stack> */}
        <Box sx={{}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card className="bg-transparent bordre-0 shadow-none row justify-content-between">
                <Form className="col-md-5 col-12 mb-3 ">
                  <InputGroup>
                    <InputGroup.Text className="bg-white border-0 pe-0 me-0 shadow-none">
                      <Iconify icon="ic:outline-search" />
                    </InputGroup.Text>
                    <Form.Control
                      className="border-0 shadow-none"
                      placeholder="Search By Buyer Name, Seller Name"
                      aria-label="Search"
                      disabled={isLoading}
                      value={storeData?.searchV}
                      onChange={filterTable}
                    />
                  </InputGroup>
                </Form>
                <div className="col-md-2 mb-3">
                  {/* <Button variant="contained" disabled={isLoading} onClick={callDownload} className=" w-100">
                    Download Excel
                  </Button> */}
                </div>
              </Card>

              <Box>
                {isLoading ? (
                  <Loader />
                ) : (
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: { sm: 650, xs: 'auto' } }} aria-label="simple table">
                        <TableHeadCustom>
                          <TableRow>
                            <TableCell align="left">Index</TableCell>
                            <TableCell align="center">Buyer Name</TableCell>
                            <TableCell align="center">Buyer Email</TableCell>
                            <TableCell align="center">Seller Rep. Name</TableCell>
                            <TableCell align="center">Seller Rep. Email</TableCell>
                            <TableCell align="center">Scheduled Time</TableCell>
                            <TableCell align="center">Meeting Duration</TableCell>
                            <TableCell align="right">Meeting Status</TableCell>
                          </TableRow>
                        </TableHeadCustom>
                        <TableBody>
                          {storeData?.reviewTable?.data?.length === 0 && (
                            <TableRow>
                              <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                                No Data Found
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default UserMeetings;
