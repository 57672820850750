/* eslint-disable no-unused-expressions */
/* eslint-disable one-var */
import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Tab,
  Tabs,
  Typography,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import {} from 'react-bootstrap';

import { styled } from '@mui/system';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import apiMiddleWare from '../../../utils/useApiMiddleWare';
import useLocalStorage from '../../../hooks/useLocalStorage';
import customToast from '../../../utils/customToast';
import { defaultError, wocOrange, tableBgColor } from '../../../utils/constants';
import Iconify from '../../../components/iconify/Iconify';
import { customRenderValue, useQuery } from '../../../utils/helper';
import useResponsive from '../../../hooks/useResponsive';

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const smUp = useResponsive('up', 'sm');

  const showcaseRef = useLocalStorage('showcaseID');
  const filterDataLocal = useLocalStorage('filterData');
  const sID = location?.state?._id || '';

  const [isLoading, setIsLoading] = useState(false);
  const [apiCall, setApiCall] = useState(false);

  const [open, setOpen] = useState(null);
  const query = useQuery();
  const tabParam = query.get('tab');
  const [currentTab, setCurrentTab] = useState(
    // tabParam === 'representative' ? 1 :
    0
  );
  const [dataList, setDataList] = useState({
    category: {},
    product: [],
    search: [],
  });

  const [addProduct, setAddProduct] = useState({
    open: false,
    data: {
      products: { list: [], value: '' },
      productKeyword: { list: [], value: [] },
      searchKeyword: { list: [], value: [] },
      category: { list: {}, value: {} },
    },
  });

  const [addProduct2, setAddProduct2] = useState({
    open: false,
    data: {
      contactList: { list: [], value: [] },
    },
    called: false,
  });

  useEffect(() => {
    if (!location?.state?.metaData?.eventURL || !location?.state?.metaData?.eventID || !location?.state?._id) {
      navigate('/user/showcase/template/');
      return;
    }
    getInititalData();
    getProductList();
  }, [apiCall]);

  useEffect(() => {
    if (currentTab === 1 && !addProduct2?.called) {
      getContactList();
    }
  }, [currentTab]);

  useEffect(() => {
    // console.log('location?.state?.productList + ', location?.state?.productList);
  }, [location?.state?.productList]);

  const getInititalData = async () => {
    try {
      setIsLoading(true);
      const sendData = {
        eventURL: location?.state?.metaData?.eventURL,
        eventID: location?.state?.metaData?.eventID,
      };
      const res = await apiMiddleWare({
        type: 'post',
        url: '/showcase/event-request-discover',
        data: sendData,
      });
      if (!res?.data?.ok) {
        customToast({
          msg: res?.data?.msg || res?.data?.errors?.[0]?.message || res?.data?.errors?.[0]?.msg || defaultError,
          type: 'error',
        });
        setIsLoading(false);
        return false;
      }
      const allData = res?.data?.packet;
      setDataList((prev) => ({
        ...prev,
        category: allData?.categories || {},
        product: allData?.discoveProducts || [],
        search: allData?.searchKeyword || [],
      }));
      const newObj = {};
      allData?.categories &&
        Object.keys(allData?.categories).length > 0 &&
        Object.keys(allData?.categories)?.forEach((i) => {
          newObj[i] = [];
        });
      setAddProduct((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          productKeyword: {
            ...prev.data.productKeyword,
            list: allData?.discoveProducts || [],
          },
          searchKeyword: {
            ...prev.data.searchKeyword,
            list: allData?.searchKeyword || [],
          },
          category: {
            ...prev.data.searchKeyword,
            list: allData?.categories || {},
            value: newObj,
          },
        },
      }));
      setIsLoading(false);
      return true;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);
      return false;
    }
  };

  const getProductList = async () => {
    try {
      setIsLoading(true);

      const res = await apiMiddleWare({
        type: 'get',
        url: `/showcase/product/list-all/${sID}`,
      });
      if (!res?.data?.ok) {
        customToast({
          msg: res?.data?.msg || res?.data?.errors?.[0]?.message || res?.data?.errors?.[0]?.msg || defaultError,
          type: 'error',
        });
        setIsLoading(false);
        return false;
      }

      const allData = res?.data?.packet;

      setAddProduct((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          products: {
            ...prev.data.products,
            list: allData,
          },
        },
      }));

      setIsLoading(false);
      return true;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);
      return false;
    }
  };

  // console.log('products?.list + ', addProduct?.data?.products?.list);
  // console.log('addProduct?.data + ', addProduct?.data);

  const getContactList = async () => {
    try {
      setIsLoading(true);
      const res = await apiMiddleWare({
        type: 'get',
        url: `/showcase/contact/get/${sID}`,
      });
      if (!res?.data?.ok) {
        customToast({
          msg: res?.data?.msg || res?.data?.errors?.[0]?.message || res?.data?.errors?.[0]?.msg || defaultError,
          type: 'error',
        });
        setIsLoading(false);
        return false;
      }
      const allData = res?.data?.packet;
      setAddProduct2((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          contactList: { ...prev.data.contactList, list: allData?.contactList },
        },
        called: true,
      }));
      setIsLoading(false);
      return true;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);
      return false;
    }
  };

  const onChangeAddProduct = (e) => {
    const { name, value } = e.target;
    resetAddProductForm();
    setAddProduct((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [name]: {
          ...prev?.data?.[name],
          value,
        },
      },
    }));
    if (name === 'products') {
      let index = false;
      addProduct?.data?.products?.list?.forEach((i, index1) => {
        if (i?.productID?._id === value) index = index1;
      });

      if (index !== -1 && index !== false) handleEditProduct(index);
    }
  };

  const selectOption = (type, value, subType) => () => {
    if (type !== 'category') {
      const selectedList = addProduct?.data?.[type]?.value;
      let allValue, indexVal, lastIndex;

      if (selectedList?.includes(value)) {
        // remove
        allValue = addProduct?.data?.[type]?.value;
        indexVal = allValue?.indexOf(value);
        allValue?.splice(indexVal, 1);
      } else {
        // add
        allValue = addProduct?.data?.[type]?.value;
        lastIndex = allValue?.length;
        allValue?.splice(lastIndex, 0, value);
      }
      setAddProduct((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          [type]: {
            ...prev?.data?.[type],
            value: allValue,
          },
        },
      }));
    } else {
      const selectedList = addProduct?.data?.[type]?.value?.[subType];
      let allValue, indexVal, lastIndex;
      if (selectedList?.includes(value)) {
        // remove
        allValue = addProduct?.data?.[type]?.value?.[subType];
        indexVal = allValue?.indexOf(value);
        allValue?.splice(indexVal, 1);
      } else {
        // add
        allValue = addProduct?.data?.[type]?.value?.[subType] || [];

        lastIndex = allValue?.length;
        allValue?.splice(lastIndex, 0, value);
      }
      setAddProduct((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          [type]: {
            ...prev?.data?.[type],
            value: {
              ...prev?.data?.[type]?.value,
              [subType]: allValue,
            },
          },
        },
      }));
    }
  };

  const submitForm = async (e) => {
    try {
      e.preventDefault();
      const formData = await validateForm();

      const sendData = {
        ...formData,
        eventID: location?.state?.metaData?.eventID, // this is not currently found
        showcaseRef: sID,
      };
      setIsLoading(true);
      const res = await apiMiddleWare({
        type: 'post',
        url: `/showcase/event-showcase-product-update`,
        data: sendData,
      });
      if (!res?.data?.ok) {
        customToast({
          msg: res?.data?.msg || res?.data?.errors?.[0]?.message || res?.data?.errors?.[0]?.msg || defaultError,
          type: 'error',
        });
        setIsLoading(false);
        return false;
      }
      // const allData = res?.data?.packet;
      customToast({
        msg: res?.data?.msg || 'Product Updated Successfully',
        type: 'success',
      });
      setIsLoading(false);

      setApiCall((prev) => !prev);
      // getListingProduct();
      resetAddProductForm();
      return true;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);
      return false;
    }
  };

  const validateForm = async () =>
    new Promise((resolve, reject) => {
      const sendData = {};
      sendData.productRef = addProduct?.data?.products?.value;
      sendData.productFilter =
        (addProduct?.data?.productKeyword?.value?.length > 0 && addProduct?.data?.productKeyword?.value) || [];
      sendData.searchFilter =
        (addProduct?.data?.searchKeyword?.value?.length > 0 && addProduct?.data?.searchKeyword?.value) || [];
      sendData.category =
        Object.entries(addProduct?.data?.category?.value).length > 0
          ? Object.entries(addProduct?.data?.category?.value)
              ?.map((i) => {
                if (i?.[1]?.length > 0)
                  return {
                    categoryType: i?.[0],
                    categoryOption: i?.[1],
                  };
                return null;
              })
              ?.filter((i) => i)
          : [];
      resolve(sendData);
    });

  const resetAddProductForm = () => {
    const newObj = {};
    Object.keys(dataList?.category)?.forEach((i) => {
      newObj[i] = [];
    });
    setAddProduct((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        products: {
          ...prev.data.products,
          value: '',
        },
        category: {
          ...prev.data.category,
          value: newObj,
        },
        searchKeyword: {
          ...prev.data.searchKeyword,
          value: [],
        },
        productKeyword: {
          ...prev.data.productKeyword,
          value: [],
        },
      },
    }));
  };

  const getListingProduct = async () => {
    try {
      const sendData = {
        eventID: location?.state?.metaData?.eventID,
        showcaseRef: sID,
      };
      const res = await apiMiddleWare({
        type: 'post',
        url: `/showcase/get-one-event-showcase`,
        data: sendData,
      });
      if (!res?.data?.ok) {
        customToast({
          msg: res?.data?.msg || res?.data?.errors?.[0]?.message || res?.data?.errors?.[0]?.msg || defaultError,
          type: 'error',
        });
        return false;
      }
      navigate('/user/dashboard/connect-event/product', { state: res?.data?.packet, replace: true });
      return true;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const handleOpenMenu = (e) => {
    setOpen(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleEditProduct = (index) => {
    const newData =
      index > -1 && index !== false
        ? addProduct?.data?.products?.list?.[index]
        : addProduct?.data?.products?.list?.[open?.dataset?.index];
    const newCategory = {};
    if (newData?.category && newData?.category?.length > 0)
      newData?.category?.forEach((i) => {
        newCategory[i.categoryType] = i.categoryOption;
      });
    setAddProduct((prev) => ({
      ...prev,
      open: true,
      data: {
        ...prev.data,
        products: {
          ...prev.data.products,
          value: newData?.productID?._id,
        },
        category: {
          ...prev.data.category,
          value: newCategory,
        },
        productKeyword: {
          ...prev.data.productKeyword,
          value: newData?.productFilter,
        },
        searchKeyword: {
          ...prev.data.searchKeyword,
          value: newData?.searchFilter,
        },
      },
    }));
    handleCloseMenu();
  };

  const handleDeleteProduct = async () => {
    try {
      const productListID = location?.state?.productList?.[open?.dataset?.index]?._id;
      const sendData = {
        eventID: location?.state?.metaData?.eventID,
        showcaseRef: sID,
        productListID,
      };

      setIsLoading(true);
      const res = await apiMiddleWare({
        type: 'post',
        url: `/showcase/event-showcase-product-delete`,
        data: sendData,
      });
      if (!res?.data?.ok) {
        customToast({
          msg: res?.data?.msg || res?.data?.errors?.[0]?.message || res?.data?.errors?.[0]?.msg || defaultError,
          type: 'error',
        });
        setIsLoading(false);
        return false;
      }
      setIsLoading(false);
      customToast({
        msg: res?.data?.msg,
        type: 'success',
      });
      handleCloseMenu();
      // getListingProduct();
      resetAddProductForm();
      return true;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);
      return false;
    }
  };

  const submitForm2 = (e) => {
    e.preventDefault();
  };

  const TableHeadCustom = styled(TableHead)({
    '& .MuiTableCell-head': {
      color: 'white',
      backgroundColor: wocOrange,
    },
  });

  const tableCellStyle = {
    padding: '6px 14px',
    '& .MuiOutlinedInput-input': {
      backgroundColor: 'white',
      borderRadius: '7px',
    },
  };

  return (
    <>
      <Container maxWidth="xl" className="">
        <Card sx={{ p: 2, mb: 2 }}>
        <Typography variant="h5" sx={{ mb: 3 }}>
          Configure{location?.state?.name ? ` - ${location?.state?.name}` :''}
        </Typography>
          <Box>
            <form className="mb-4" onSubmit={submitForm}>
              <div className="d-flex justify-content-between mb-3">
                <div className="row w-100 flex-grow-1">
                  {addProduct?.open && (
                    <div className="col-md-3">
                      <TextField
                        fullWidth
                        name="products"
                        id="outlined-select-currency"
                        select
                        label="Select Product"
                        value={addProduct?.data?.products?.value}
                        onChange={onChangeAddProduct}
                        required
                      >
                        <MenuItem value={''}>--Select--</MenuItem>
                        {addProduct?.data?.products?.list?.length > 0 &&
                          addProduct?.data?.products?.list?.map((i) => (
                            <MenuItem key={i?.productID?._id} value={i?.productID?._id}>
                              {i?.productID?.title}
                            </MenuItem>
                          ))}
                      </TextField>
                    </div>
                  )}
                  <div className="ms-auto d-flex col-md-3 my-2">
                    <Button
                      type="button"
                      variant="contained"
                      sx={{ backgroundColor: wocOrange, boxShadow: '0' }}
                      onClick={() => setAddProduct((prev) => ({ ...prev, open: !prev?.open }))}
                      className=" w-100"
                    >
                      <Iconify icon="material-symbols:download-rounded" className="me-2" width={24} />
                      Configure Keywords
                    </Button>
                  </div>
                </div>
              </div>
              {addProduct?.open && (
                <>
                  {addProduct?.data?.productKeyword?.list?.length > 0 && (
                    <div className="mb-3">
                      <Typography variant="h5" component="h6" className="mb-2">
                        Product Filter
                      </Typography>
                      <div className="select_box">
                        {addProduct?.data?.productKeyword?.list?.map((i) => (
                          <button
                            key={i}
                            type="button"
                            onClick={selectOption('productKeyword', i)}
                            className={`me-3 ${addProduct?.data?.productKeyword?.value?.includes(i) ? 'active' : ' '}`}
                          >
                            {i}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                  {addProduct?.data?.searchKeyword?.list?.length > 0 && (
                    <div className="mb-3">
                      <Typography variant="h5" component="h6" className="mb-2">
                        Search Filter
                      </Typography>
                      <div className="select_box">
                        {addProduct?.data?.searchKeyword?.list?.map((i) => (
                          <button
                            key={i}
                            type="button"
                            onClick={selectOption('searchKeyword', i)}
                            className={`me-3 ${addProduct?.data?.searchKeyword?.value?.includes(i) ? 'active' : ' '}`}
                          >
                            {i}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                  {Object.keys(addProduct?.data?.category?.list).length > 0 &&
                    Object.entries(addProduct?.data?.category?.list)?.map((item) => (
                      <div className="mb-3" key={item[0]}>
                        <Typography variant="h5" component="h6" className="mb-2">
                          {item[0]}
                        </Typography>
                        <div className="select_box">
                          {item[1]?.map((i) => (
                            <button
                              key={i}
                              type="button"
                              onClick={selectOption('category', i, item[0])}
                              className={`me-3 ${
                                addProduct?.data?.category?.value?.[item[0]]?.includes(i) ? 'active' : ' '
                              }`}
                            >
                              {i}
                            </button>
                          ))}
                        </div>
                      </div>
                    ))}
                  <div className="d-flex justify-content-end">
                    <Button type="submit" variant="contained" className="ms-2">
                      Save
                    </Button>
                    <Button
                      type="button"
                      variant="outlined"
                      color="error"
                      onClick={resetAddProductForm}
                      className="ms-2"
                    >
                      Reset
                    </Button>
                  </div>
                </>
              )}
            </form>
            <Card>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: smUp ? 650 : 'auto' }} aria-label="simple table">
                  <TableHeadCustom>
                    <TableRow>
                      <TableCell align="left">Index</TableCell>
                      <TableCell align="left">Product Name</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHeadCustom>
                  <TableBody>
                    {addProduct?.data?.products?.list?.length > 0 &&
                      addProduct?.data?.products?.list?.map((row, index) => (
                        <TableRow
                          key={row?.productID?._id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-child(odd)': {
                              backgroundColor: tableBgColor,
                            },
                            '&:nth-child(even)': {
                              backgroundColor: 'white',
                            },
                          }}
                        >
                          <TableCell align="left">{index + 1}</TableCell>
                          <TableCell sx={tableCellStyle} align="left" className="cursor-pointer w-200px">
                            <Tooltip title={row?.productID?.title} placement="bottom-start">
                              <div className="ellipse-text ">{row?.productID?.title}</div>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              size="medium"
                              color="inherit"
                              onClick={handleOpenMenu}
                              data-name="product"
                              data-index={index}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    {addProduct?.data?.products?.list?.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                          No Data Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Box>
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleEditProduct} sx={{ color: 'green' }}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        {/* <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteProduct}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem> */}
      </Popover>
    </>
  );
};
export default App;
